import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl, { FormControlTypeMap } from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Badge, FormHelperText, Stack , SxProps } from '@mui/material';
import { FieldInputProps, FieldMetaProps } from 'formik';
import { CaretDown } from 'icons/icon-components';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

export interface MultiSelectDropdownProps {
  id?: string;
  label: string;
  sx?: SxProps;
  selectSx?: SxProps;
  formControlProps?: DefaultComponentProps<FormControlTypeMap<{}, 'div'>>;
  fieldInputProps?: Omit<FieldInputProps<string[]>, 'value'>;
  fieldMetaProps?: FieldMetaProps<string[]>;
  items: any[];
  value: string[];
  onChange: (items) => void;
  onOpen?: (event: React.SyntheticEvent<Element, Event>) => void;
  onClose?: (event: React.SyntheticEvent<Element, Event>) => void;
}

export default function MultiSelectDropdown (props: MultiSelectDropdownProps) {
  const { id, label, sx, selectSx = { minWidth: '300px', maxWidth: '300px', width: '100%' }, formControlProps, fieldInputProps, fieldMetaProps, items, onChange, onOpen, onClose } = props;

  return (
    <Stack sx={{ ...sx, maxWidth: '50%' }} direction='row'>
      <FormControl {...formControlProps} fullWidth>
        <InputLabel id="basicMultiSelect">{label ?? 'Multi Select Dropdown'}</InputLabel>
        <Badge
          badgeContent={props.value.length}
          color="secondary"
          sx={{ mb: 1 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} >
          <Select
            labelId={`${id}-label`}
            id={id ?? 'multi-checkbox'}
            sx={selectSx}
            label={label}
            IconComponent={CaretDown}
            multiple
            value={props.value}
            {...fieldInputProps}
            input={<OutlinedInput label={label ?? 'Multi Select Dropdown'} />}
            renderValue={(selected) => selected?.join(', ')}
            onChange={(evt) => onChange(evt?.target?.value)}
            onOpen={onOpen}
            onClose={onClose}
          >
            {items.map((name) => (
              <MenuItem key={name} value={name} >
                <Checkbox checked={props.value.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Badge>
      </FormControl>
      {(Boolean(fieldMetaProps?.error) && Boolean(fieldMetaProps!.touched)) ?
        <FormHelperText sx={{ mt: 0, mb: 1 }} error>{fieldMetaProps!.error}</FormHelperText> :
        null}
    </Stack>
  );
}