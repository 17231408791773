import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import authService from 'services/authorization/AuthorizeService';
import queryString from 'query-string';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  paramsSerializer: (params: Record<string, unknown>) =>
    queryString.stringify(params, { arrayFormat: 'none' }),
  prepareHeaders: async (headers) => {
    const token = await authService.getAccessToken();
    if (token) headers.set('Authorization', `Bearer ${token}`);

    return headers;
  }
});

export const baseApi = createApi({
  tagTypes: [
    'IndirectEnergy',
    'Energy',
    'NetZeroApplication',
    'NetZeroProject',
    'NetZeroProjectIndicator',
    'NetZeroProjectType',
    'Locations',
    'SignOff',
    'Unit',
    'DirectEnergy',
    'Scope3Energy',
    'Disclosures',
    'Emission',
    'EmissionSource',
    'EnergyAgreement',
    'EnergyMetric',
    'Gases',
    'FundingApplication',
    'DisclosureAnswer',
    'SafetyIncident',
    'HealthAndSafetyIncident',
    'personnelIncident',
    'BatchTemplates',
    'ReportingPeriod',
    'WaterMeter',
    'WaterSource',
    'WaterMeterCalibration',
    'WaterMeterReading',
    'WaterSourceType',
    'WaterQuality',
    'StakeholderFeedback',
    'FeedbackCategory',
    'StakeholderFeedbackSolicitation',
    'Consultation',
    'Notification',
    'Project',
    'ProjectPayment',
    'Dashboard',
    'RequiredDocuments',
    'SocialIncident',
    'WasteDisposal',
    'IntensityMetric',
    'Company',
    'Target',
    'ProductionFactor',
    'Roles',
    'SupplierReview',
    'CareerDevelopment',
    'CarbonTax',
    'ConfiguredEmissionSource',
    'Biodiversity',
    'BiodiversityOperation',
    'BiodiversityImpact',
    'EmployeeAudit',
    'ImpactAssessment',
  ],
  baseQuery: baseQuery,
  endpoints: () => ({})
});
