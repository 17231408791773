import React from 'react';

const PageLoader = React.memo(() => (
  <div style={styles.container}>
    <img src={require('components/Loading.gif')} alt="loading..." width={80} height={80} style={{ borderRadius: 16, overflow: 'none' }} />
  </div>
));

const styles: { [name: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    minHeight: 512,
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default PageLoader;