import React, { Suspense } from 'react';
import useIsAuthenticated from 'helpers/use-is-authenticated';
import ModalLoader from 'components/shared/modal-loader';
import PageLoader from 'components/shared/page-loader';
import { Navigate, Outlet } from 'react-router';
import RedirectToLogin from 'components/api-authorization/redirect-to-login';
import useGetPermissions from 'helpers/use-permissions';
import { ModuleEnum } from 'services/api/main';

interface LoaderSuspenseProps {
  children: React.ReactNode;
  outlet?: boolean;
  modal?: boolean;
  allowAnonymous?: boolean;
  module?: ModuleEnum;
  role?: string;
}

const LoaderSuspense = ({ children, outlet, modal, allowAnonymous = false, module, role }: LoaderSuspenseProps) => {
  const { permissions, roles } = useGetPermissions(); // A bit finicky - need to call this first so user is populated otherwise redirect to forbidden every time
  const [isAuthenticated] = useIsAuthenticated(!allowAnonymous);
  const checkInvalidModules = React.useCallback(() => (Boolean(module) && isAuthenticated && !permissions.any(p => p.module === module)), [module, isAuthenticated, permissions]);
  const checkInvalidRoles = React.useCallback(() => (Boolean(role) && isAuthenticated && !roles?.any(r => r === role)), [role, roles, isAuthenticated]);
  
  return (
    <>
      <Suspense fallback= { modal ? <ModalLoader /> : <PageLoader />}>
        { children }
      </Suspense>
      { outlet ? <Outlet /> : null}
      { isAuthenticated === false ? <RedirectToLogin /> : null}
      {checkInvalidModules() || checkInvalidRoles() ? <Navigate to='/forbidden' /> : null }
    </>
  );
};

export default LoaderSuspense;