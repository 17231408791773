import { ModuleEnum } from 'services/api/main';
import useGetPermissions, { Permission } from './use-permissions';

const getPermissionsPerModule = (permissions: Permission[], module) => {
  return permissions.filter(p => p.module === module);
};

const useModulePermissions = (module: ModuleEnum): {readOnly: boolean} => {
  const { permissions } = useGetPermissions(); 

  if(!permissions) return { readOnly: true };

  return { readOnly: getPermissionsPerModule(permissions, module).any( p => p.access === 'Read') } ;
};

export default useModulePermissions;