import React from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import { Dictionary } from 'lodash';
import { DataLoad } from 'services/api/common-types';
import {
  UnitResponse,
  useGetApiCommonUnitsByUnitTypeQuery,
  IQuantity
} from 'services/api/main';
import { ExtractUnit } from 'common';
import { formatDisplay, DisplayAs } from '../formatters/data-grid-formatter';

interface QuantityUnitDetailProps extends StackProps {
  quantity: IQuantity;
  showUnitDetail?: boolean;
  rounding?: number;
  displayAs?: DisplayAs;
}

export default function QuantityUnitDetail (props: QuantityUnitDetailProps) {
  const unitsDictionary: DataLoad<Dictionary<UnitResponse>> = useGetApiCommonUnitsByUnitTypeQuery({ unitType: 'Unknown' });
  const { quantity, showUnitDetail, rounding, displayAs = 'both', ...stackProps } = props;
  const unit = unitsDictionary.data?.[ExtractUnit(quantity.unit!)];
  const value = Boolean(rounding) ? quantity.value?.roundTo(rounding!).addSeparator(' ') : quantity.value?.addSeparator(' ');
  const formattedQuantity = formatDisplay(value, unit?.abbreviation, displayAs);

  return (
    <>
      <Stack direction='row' spacing={1} {...stackProps}>
        <Typography textAlign='left'>
          {formattedQuantity}
        </Typography>
      </Stack>
      {showUnitDetail && (
        <Typography textAlign='left'>{`${unit?.name} (${unit?.unitType})`}</Typography>
      )}
    </>
  );
}
