/* eslint-disable max-lines */
import { createTheme } from '@mui/material/styles';

const AppTheme = createTheme({

  palette: {
    primary: {
      main: '#00055c',
      light: '#070e8a'
    },
    secondary: {
      main: '#999BBE', //main: '#c4d9e3',
      dark: '#ED5C14'
    },
    error: {
      main: '#ed5c14'
    },
    warning: {
      main: '#d90004c2'
    },
    info: {
      main: '#999BBE',
      light: '#e5e6ee'
    }
  },

  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
    'fontSize': 14,
    'fontWeightLight': 300,
    'fontWeightRegular': 400,
    'fontWeightMedium': 500,
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '#00055C',
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: '#999BBE'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          'fontWeight': '700 !important',
        },
        '& .MuiDataGrid-cell': {
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'yellow',
        },
        '& .inLine': {
          'fontSize': '14px !important',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {          
          fontFamily: [
            'Montserrat',
            'sans-serif',
          ].join(','),
          'fontWeight': 'MEDIUM',
          'fontSize': 'LARGE',
          paddingRight :'10px'
        },
       
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#5F5F5F70', 
          fontFamily: [
            'Montserrat',
            'sans-serif',
          ].join(','),
          'fontWeight': 'LARGE',
          'fontSize': '16px',
        },
      }
    },

    MuiButton: {
      styleOverrides: { root: { fontSize: '1rem' } }
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: { marginTop: 18 },
        selectLabel: { marginTop: 18 },
      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': { width: '0.3em', backgroundColor: 'transparent', borderRadius: '10px', marginTop: '2px' },
          '&::-webkit-scrollbar-track': { boxShadow: 'inset 0 0 0px rgba(0,0,0,0.00)', webkitBoxShadow: 'inset 0 0 0px rgba(0,0,0,0.00)' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(255,255,255,0.2)', outline: '1px solid rgba(255,255,255,0.3)', borderRadius: '10px', marginTop: '2px' },
        }
      }
    },

    MuiGrid: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': { width: '0.8em', backgroundColor: 'transparent', borderRadius: '10px', marginTop: '2px' },
          '&::-webkit-scrollbar-track': { boxShadow: 'inset 0 0 0px rgba(0,0,0,0.00)', webkitBoxShadow: 'inset 0 0 0px rgba(0,0,0,0.00)' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(255,255,255,0.2)', outline: '1px solid rgba(255,255,255,0.3)', borderRadius: '10px', marginTop: '2px' },
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#00055c1a'
        }

      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          opacity: 1,
          overflow: 'initial',
          color: '#ffffff',
          background: '#999BBE', //rbga(0,0,0,0)
          transition: '0.2s',
          borderRadius: 4,         
          '&:before': {
            color:'#ffffff',
            content: '" "',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            transformOrigin: '100%',
            boxShadow: '3px 3px 8px 0 rgba(0,0,0,0.38)',
            borderRadius: 4,
            paddingBottom:1
          },
          '&:after': {
            pointerEvents: 'none',
            transition: '0.2s',
            content: '" "',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            transform: 'translateX(100%)',
            display: 'block',
            width: 8,
            zIndex: 2,
            backgroundColor: '#00055',
            background: 'linear-gradient(to top right, rgba(0,0,0,0.1), rgba(0,0,0,0.1) 45%, transparent, transparent 64%)',
          },

          '&.Mui-selected': {
            color:'#00055c',
            zIndex: 0,
            transition: '1.0s',
            backgroundColor: 'transparent',
            '&:before': { 
              color:'#00055c', 
              boxShadow: '3px 3px 8px 0 rgba(0,0,0,0.38)',
              backgroundColor: '#transparent',
            },
            '&:after': {
              color: '#000055c',
              backgroundColor: 'transparent',
            },
          }
        },

      }
      
    },
  },
});

export default AppTheme;