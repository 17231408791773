import LoaderSuspense from 'components/navigation/loader-suspense';
import CustomMetricRoutes from 'components/shared/custom-metrics/custom-metrics-routes';
import React from 'react';
import { Route } from 'react-router';

const EmploymentDashboardWrapper = React.lazy(() => import('components/business-partner/role/employment-dashboard-wrapper'));
const TargetSetup = React.lazy(() => import('components/setup/targets/target-setup'));
const TargetModal = React.lazy(() => import('components/setup/targets/target-modal'));
const AuditTrail = React.lazy(() => import('components/audit/audit-trail-grid'));
const EmployeeAuditDataGrid = React.lazy(() => import('components/employees/employee-audit-table/employee-audits-data-grid'));
const EmployeeAuditForm = React.lazy(() => import('components/employees/employee-audit-form/employee-audit-form'));

const EmploymentRoutes = () => {
  return (<>
    {CustomMetricRoutes('Employment')}
    <Route
      path='employment'
      element={<LoaderSuspense module='Employment'><EmploymentDashboardWrapper /></LoaderSuspense>} />
    <Route path='employeeAudits'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Employment'><EmployeeAuditDataGrid /></LoaderSuspense>} >
        <Route
          path='auditForm'
          element={<LoaderSuspense outlet modal module='Employment'><EmployeeAuditForm /></LoaderSuspense>} />
        <Route
          path='auditForm/:employeeAuditId'
          element={<LoaderSuspense outlet modal module='Employment'><EmployeeAuditForm /></LoaderSuspense>} />
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='Employment'><AuditTrail module='Employment' type='EmployeeAudit' /></LoaderSuspense>}
        />
      </Route>
    </Route>
    <Route
      path='employment/targets'
      element={<LoaderSuspense outlet module='Employment'><TargetSetup module='Employment' title='Employment Targets' /></LoaderSuspense>}
    >
      <Route
        path='modal'
        element={<LoaderSuspense modal module='Employment'><TargetModal module='Employment' title='Capture Employment Target' /></LoaderSuspense>}
      />
      <Route
        path='modal/:targetId'
        element={<LoaderSuspense modal module='Employment'><TargetModal module='Employment' title='Edit Employment Target' /></LoaderSuspense>}
      />
    </Route>  
  </> 
  );
};

export default EmploymentRoutes;
