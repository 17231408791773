import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch } from 'services/stores/hooks';

interface AlertArgs {
  message: string;
  severity: AlertColor;
  displayTime?: AlertDuration;
}

interface AlertState {
  message: string | null;
  severity: AlertColor | null;
  displayTime: AlertDuration | null;
}

export type AlertDuration = 5000 | 10000 | 20000;
const defaultDuration: AlertDuration = 5000;

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {} as AlertState,
  reducers: {
    showAlert: (state, action: PayloadAction<AlertArgs>) => {
      state.severity = action.payload.severity;
      state.message = action.payload.message;
      state.displayTime = !action.payload.displayTime
        ? defaultDuration
        : action.payload.displayTime;
    },
    clearAlert: (state) => {
      state.severity = null;
      state.message = null;
      state.displayTime = null;
    },
  },
});

export const useAlert = () => {
  const dispatch = useAppDispatch();
  return {
    showAlert: (...params: Parameters<typeof showAlert>) => dispatch(showAlert(...params)),
    clearAlert: (...params: Parameters<typeof clearAlert>) => dispatch(clearAlert(...params)),
  };
};

export const { reducer } = alertSlice;
export const { showAlert, clearAlert } = alertSlice.actions;
