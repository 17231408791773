import React from 'react';
import { Delete } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import PopoverButton from 'components/input/popover-button';
import useModulePermissions from 'helpers/use-module-permissions';
import { ModuleEnum } from 'services/api/main';

interface PopoverDeleteProps {
  delete: () => void;
  module: ModuleEnum;
}

export function PopoverDeleteButton (props: PopoverDeleteProps) {
  const { readOnly } = useModulePermissions(props.module);
  return (
    <PopoverButton disabled={readOnly ?? true} tooltip="Delete record" index={1} muiIcon={<Delete sx={{ color: '#808080' }} />}>
      <Stack direction="column">
        <Typography gutterBottom component="div" textAlign='left' sx={{ width: '100%', p: 1 }}>
          Are you sure you would like to delete this record?
        </Typography>
        <Stack direction={'row'} alignContent={'end'}>
          <Box display='flex' flexDirection='row-reverse' sx={{ p: 1 }}>
            <Button sx={{ padding: 1 }} size='small' variant="contained" onClick={() => props.delete()}>Delete</Button>
          </Box>
        </Stack>
      </Stack>
    </PopoverButton>
  );
}