import * as yup from 'yup';
import { validateDateRange } from 'types/shared/validations';
import { CommonUnitType } from 'services/api/common-types';
import { ExtractUnit } from 'common';

export const ValidationSchema = yup.object({
  batchLocationId: yup.string().nullable(false).required('Please select a location').default(''),
  batchDateRange: validateDateRange(),
  batchRoot: yup.string().nullable(false).required('Please select a root').default(''),

  metricTypes: yup.array(yup.object({
    metricTypeId: yup.string().nullable(false).required('Please select a Metric Type').default(''),
    locationId: yup.string().default(''),
    dateRange: validateDateRange(),
    quantity: yup.object({
      unit: yup.mixed<CommonUnitType>().default('EnergyUnit.Undefined').required('Please enter a unit').defined()
        .test('ValidUnitType', 'Please select a unit', (val) => ExtractUnit(val) !== 'Unknown'),
      value: yup.number().defined().test('Is positive?', 'Must be positive number', (value) => value === undefined || value === null || value >= 0).required('Please enter amount').default(0),
    }),
  })).default([])
});

export const InitialValues = ValidationSchema.getDefault();

export default interface CustomMetricBatchCaptureFormValues extends yup.InferType<typeof ValidationSchema> {}