import React from 'react';
import { Stack, Typography } from '@mui/material';
import Research from 'illustrations/amico-style/research';

interface GridPlaceholderProps {
  isFiltered?: boolean;
  isLoading?: boolean;
};

export default function GridPlaceholder (props: GridPlaceholderProps) {
  return (
    <Stack height='100%' sx={{ mt: 7 }} alignItems='center' justifyContent='center'>
      {props.isLoading ?
        <>
          <img src={require('components/Loading.gif')} alt='loading...' width={70} height={70} />
          <Typography variant='body1' sx={{ color: 'primary.main', fontWeight: 600 }} gutterBottom component='div'>Loading Data...</Typography>
        </> :
        <>
          <Research />
          <Typography variant='body1' sx={{ color: 'primary.main', fontWeight: 600 }} gutterBottom component='div'>
            {props.isFiltered ? 'No data matches search criteria' : 'No data available'}
          </Typography>
        </>}
    </Stack>);
}
