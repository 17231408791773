import LoaderSuspense from 'components/navigation/loader-suspense';
import React from 'react';
import { Route } from 'react-router';

const CareerDevelopmentTable = React.lazy(() => import('components/business-partner/career-development/career-development-data-grid'));
const CareerReviewForm = React.lazy(() => import('components/business-partner/career-development/forms/career-review-form'));
const TrainingForm = React.lazy(() => import('components/business-partner/career-development/forms/training-form'));
const BursaryForm = React.lazy(() => import('components/business-partner/career-development/forms/bursary-form'));
const LearnershipForm = React.lazy(() => import('components/business-partner/career-development/forms/learnership-form'));
const AuditTrail = React.lazy(() => import('components/audit/audit-trail-grid'));

const CareerDevelopmentRoutes = () => {
  return (
    <Route path='career-development'>
      <Route
        path='table'
        element={<LoaderSuspense outlet module='Employment'><CareerDevelopmentTable /></LoaderSuspense>} >
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense modal module='Employment'><AuditTrail module='Communities' type='CareerDevelopment' /></LoaderSuspense>} />
        <Route
          path='modal/training'
          element={<LoaderSuspense modal module='Employment'><TrainingForm /></LoaderSuspense>} />
        <Route
          path='modal/training/:careerDevelopmentId'
          element={<LoaderSuspense modal module='Employment'><TrainingForm /></LoaderSuspense>} />
        <Route
          path='modal/learnership'
          element={<LoaderSuspense modal module='Employment'><LearnershipForm /></LoaderSuspense>} />
        <Route
          path='modal/learnership/:careerDevelopmentId'
          element={<LoaderSuspense modal module='Employment'><LearnershipForm /></LoaderSuspense>} />
        <Route
          path='modal/bursary'
          element={<LoaderSuspense modal module='Employment'><BursaryForm /></LoaderSuspense>} />
        <Route
          path='modal/bursary/:careerDevelopmentId'
          element={<LoaderSuspense modal module='Employment'><BursaryForm /></LoaderSuspense>} />
        <Route
          path='modal/career-review'
          element={<LoaderSuspense modal module='Employment'><CareerReviewForm /></LoaderSuspense>} />
        <Route
          path='modal/career-review/:careerDevelopmentId'
          element={<LoaderSuspense modal module='Employment'><CareerReviewForm /></LoaderSuspense>} />
      </Route>
    </Route>);
};

export default CareerDevelopmentRoutes;
