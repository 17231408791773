import { CommonUnitType } from 'services/api/common-types';
import { UnitTypeEnum } from 'services/api/main';

const MaxDecimalDigits = 5;

function MatchUnitInput (input: string) : RegExpMatchArray | null {
  if(input === '' || input === 'Unknown' || input === undefined) {
    return null;
  }

  return input.match(/(EnergyUnit|VolumeUnit|MassUnit|LengthUnit|ScalarUnit|AreaUnit|CustomScalarUnitsEnum|HybridUnitsEnum)\.(\w+)/);
}

/**
 * Extract the UnitType from the fullname UnitType.
 *  E.g "EnergyUnit.Kilowatt to Energy" 
 * @param input The full unitType name: "EnergyUnit.Kilowatt"
 */
export function ExtractUnitType (input: string): UnitTypeEnum | undefined {
  const unitType = MatchUnitInput(input);
  return unitType ? unitType[1].replace('Unit', '').replace('CustomScalarsEnum', 'CustomScalar') as UnitTypeEnum : undefined;
}

/**
 * Extract the Unit from the fullname UnitType.
 *  E.g "EnergyUnit.Kilowatt to Kilowatt" 
 * @param input The full unitType name: "EnergyUnit.Kilowatt"
 */
export function ExtractUnit (input: CommonUnitType): string {
  const unitType = MatchUnitInput(input);
  return unitType ? unitType[2] : 'Unknown';
}

/**
 * This is used to change a name form "Anything>Something" to "Anything (Something)".
 * Used to the graph titles on Intensity graphs.
 * 
 * @param input The full configured graphname: "Anything>Something"
 */
export function FormatName (input: string): string {
  input = input ?? ''; 
  const parts = input.split('>');
  if (parts.length === 2) {
    if (parts[0] === parts[1])
      return parts[0];
    else
      return `${parts[0]} (${parts[1]})`;
  } else {
    return input;
  }
};

/**
 * Works with .sort from arrays
 * E.g. array.sort((a,b ) => sortAlphabeticallyGeneric(a, b, item => item.name);
 * @param a comparing value A.
 * @param b comparing value B.
 * @param getDisplay Get the property name (String type) you want to compare with.
 */
export function sortAlphabeticallyGeneric (a, b, getDisplay): number {
  var nameA = getDisplay(a).toLocaleUpperCase();
  var nameB = getDisplay(b).toLocaleUpperCase();
  
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

/**
 * Gets the precision (numbers after decimal point) based on the scale of the number
 * E.g. 100.78234 will give a precision of @constant MaxDecimalDigits - 2 = 3 (hence 100.782)
 * @param number the number you want the precision of.
 */
export function getSignificantDigitPrecision (number: number): number {
  return Math.max(MaxDecimalDigits - Math.max(Math.floor(Math.log10(Math.abs(number))), 0), 0);;
};

export function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
