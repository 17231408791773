import { GridValueFormatterParams } from '@mui/x-data-grid';
import { DateObjectUnits, DateTime } from 'luxon';
import { DateDto } from 'services/api/main';

export const formatDate = (param: GridValueFormatterParams) =>
  DateTime.fromObject(param.value as DateObjectUnits).toFormat('dd LLL yyyy');

export const formatDateTime = (param: string | GridValueFormatterParams) => {
  const timeString = typeof param === 'string' ? param : param.value as string;
  return DateTime.fromISO(timeString, { zone: 'utc' })
    .setZone(DateTime.local().zoneName)
    .toFormat('dd LLL yyyy HH:mm');
};

export const formatDateString = (param: string | undefined) => {
  if(!param) return;
  return DateTime.fromISO(param as string).toFormat('dd LLL yyyy');
};

export const formatDateOrNull = (param: GridValueFormatterParams) => {
  if (!param.value) return;
  const casting = param.value as DateObjectUnits;
  return DateTime.fromObject({ year: casting.year, month: casting.month, day: casting.day }).toFormat('dd LLL yyyy');
};

export const formatDateFromDateDto = (dateDto: DateDto, format: string = 'dd LLL yyyy') => 
  DateTime.fromObject(dateDto as DateObjectUnits).toFormat(format);
