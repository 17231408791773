import { map } from 'lodash';
import '../src/global.d';

if (!Number.prototype.hasOwnProperty('roundTo')) {
  Number.prototype.roundTo = function (num: number): number {
    return parseFloat(this.toFixed(num));
  };
}

if (!Number.prototype.hasOwnProperty('addSeparator')) {
  Number.prototype.addSeparator = function (separator: string): string {
    return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  };
}

if (!Array.prototype.hasOwnProperty('toggleElement')) {
  Array.prototype.toggleElement = function <T>(this: T[], item: T): T[] {
    return this.includes(item)
      ? this.filter(i => i !== item)
      : [...this, item];
  };
}

if (!Array.prototype.hasOwnProperty('breadthFirstSearch')) {
  Array.prototype.breadthFirstSearch = function <T>(this: T[], predicate: (i: T) => boolean, getChildren: (i: T) => T[]): T | null {
    if (this.length === 0)
      return null;
    return this.find(predicate)
      ?? this.map(getChildren).flat().breadthFirstSearch(predicate, getChildren)
      ?? null;
  };
}

if (!Array.prototype.hasOwnProperty('any')) {
  Array.prototype.any = function <T>(this: T[], predicate?: (T) => boolean): boolean {
    if (!predicate) {
      return this.length > 0;
    } else {
      for (let i = 0; i < this.length; i++) {
        const element = this[i];
        if (predicate(element))
          return true;
      }
      return false;
    }
  };
}

if (!Array.prototype.hasOwnProperty('last')) {
  Array.prototype.last = function <T>(this: T[]): T {
    return this[this.length - 1];
  };
}

if (!Array.prototype.hasOwnProperty('sumValues')) {
  Array.prototype.sumValues = function <T>(this: T[], propName: string): number {
    return this.reduce(function (a, b) {
      return a + b[propName];
    }, 0);
  };
}

if (!Array.prototype.hasOwnProperty('toDictionary')) {
  Array.prototype.toDictionary = function <T, K = string, V = T>(this: T[], getKey: (entry: T) => K, getValue?: (entry: T) => V): Map<K, V> {
    var output = new Map();
    if (this) {
      getValue ??= (entry) => entry as any;
      
      for (let i = 0; i < this.length; i++) {
        const element = this[i];
        output.set(getKey(element), getValue(element));
      }
    }
    return output;
  };
}

export function convertDictToKeyPair (dictionary): any[] {
  if (dictionary.length === 0)
    return [];

  return map(dictionary,
    (value, key) => ({ key, value })
  );
};
