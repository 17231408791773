import * as yup from 'yup';
import { ModuleEnum, EmissionScopeEnum, TargetRequest, UnitTypeEnum, TargetTypeDto, EnergySavingTypeEnum } from 'services/api/main';
import { SchemaOf, mixed, object, string } from 'yup';
import { CommonUnitType } from 'services/api/common-types';

export interface IntensityType {
  name: string;
  unitType: UnitTypeEnum;
}

const targetTypeSchema: SchemaOf<TargetTypeDto> = yup.object({
  name: yup.string().required('Target type required').default(''),
  unitTypeData: yup.array<UnitTypeEnum[]>(),
  module: yup.mixed<ModuleEnum>().required('Target type required').default('Unknown'),
});

export const TargetBaseValidationSchema: SchemaOf<TargetRequest> = object({
  id: yup.string().optional().default(null).nullable(),
  reportingPeriodId: string().default('').required('Please select a reporting period.').test('validReportingPeriod', (val) => `${val.value} is not a valid date`, (val) => val !== ''),
  businessUnitIds: yup.array().of(yup.string()).default([]).test({ message: 'Need at least one Business Unit selected', test: arr => arr!.length > 0 }).required(),
  targetType: targetTypeSchema.defined(),
  quantity: yup.object({
    unit: yup.mixed<CommonUnitType>().required('Please enter unit'),
    value: yup.number().defined().positive('Must be a positive number').required('Please enter amount').default(0),
  }),
  module: mixed<ModuleEnum>().required('Target type required').default('Unknown'),
  isCumulative: yup.boolean().default(true),
});

export const ValidationSchema = TargetBaseValidationSchema.concat(yup.object({
  scope: yup.mixed<EmissionScopeEnum>().optional().oneOf(['Unknown', 'Scope1', 'Scope2', 'Scope3'], 'Please select a scope').required('The Scope is Required').default('Unknown'),
  configuredEmissionSourceId: yup.string().default(''),
  unitTypesData: yup.array().defined().default(['Mass', 'Energy', 'Volume', 'Unknown', 'Scalar']),
  energySavingType: yup.mixed<EnergySavingTypeEnum>().default('Unknown').oneOf(['Metric', 'Emissions', 'Unknown']).optional(),
  netZeroProjectId: yup.string().when('energySavingType', {
    is: (energySavingType) => energySavingType === 'Metric',
    then: yup.string().required().min(36),
  }),
  intensityType: yup.string().default(''),
  intensityMetricType: yup.string().default(''),
}));

export let InitialValues = ValidationSchema.getDefault();

export const resetInitialValues = (defaultValues?: any) => InitialValues = { ...ValidationSchema.getDefault(), ...defaultValues };
export const setInitialValues = (response: any) => {
  InitialValues = {
    id: response?.id,
    reportingPeriodId: response?.reportingPeriodId ?? '',
    quantity: {
      unit: response?.quantity?.unit,
      value: response?.quantity?.value ?? 0,
    },
    businessUnitIds: response?.businessUnitIds,
    targetType: {
      name: response?.targetType?.name!,
      module: response?.targetType?.module!,
      unitTypeData: response?.targetType?.unitTypeData! ?? [],
    } ,
    scope: response.scope ?? '',
    configuredEmissionSourceId: response.configuredEmissionSourceId ?? '',
    netZeroProjectId: response.netZeroProjectId ?? '',
    unitTypesData: response?.targetType?.unitTypeData!,
    module: response?.module ?? 'Unknown',
    intensityType:  response?.intensityType,
    intensityMetricType:  response?.intensityMetricType,
    energySavingType: response.energySavingType ?? '',
    isCumulative: response?.isCumulative ?? true
  };
};

export default interface TargetModalFormValues extends yup.InferType<typeof ValidationSchema> {}
