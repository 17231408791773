import { GridValueFormatterParams } from '@mui/x-data-grid';
import { ExtractUnit } from 'common';

export type DisplayAs = 'abbreviation' | 'value' | 'both';

export const formatQuantity = (
  param: GridValueFormatterParams,
  formatType: 'value' | 'unit' | 'both'
) => {
  const value = param?.value?.value;
  const unit = ExtractUnit(param?.value?.unit!);

  switch (formatType) {
    case 'value':
      return `${value}`;
    case 'unit':
      return `${unit}`;
    case 'both':
      return `${value} ${unit}`;
    default:
      return `${value}`;
  }
};

export const formatDisplay = (value: any, unit: string | null | undefined, displayAs: DisplayAs) => {
  switch (displayAs) {
    case 'value':
      return `${value}`;
    case 'abbreviation':
      return unit ? unit : '';
    case 'both':
      return `${value} ${unit}`;
    default:
      return `${value}`;
  }
};