import LoaderSuspense from 'components/navigation/loader-suspense';
import React from 'react';
import { Route } from 'react-router';

const OrganizationDashboard = React.lazy(() => import('components/organization/organization-dashboard'));
const OrganizationTable = React.lazy(() => import('components/organization/datagrids/company-data-grid'));
const CompanyForm = React.lazy(() => import('components/organization/forms/company-form'));
const BusinessPartnerForm = React.lazy(() => import('components/business-partner/forms/partner-form'));

const OrganizationRoutes = () => {
  return (<>
    <Route
      // NOTE: organi`z`ation is English (US) spelling - English (UK/ZA) spelling: organi`s`ation
      path='organization'
      element={<LoaderSuspense module='OrganisationalProfile'><OrganizationDashboard /></LoaderSuspense>} />
    <Route
      path='organization/table'
      element={<LoaderSuspense outlet module='OrganisationalProfile'><OrganizationTable /></LoaderSuspense>} >
      <Route
        path='form/'
        element={<LoaderSuspense outlet modal module='OrganisationalProfile'><CompanyForm /></LoaderSuspense>} >
        <Route
          path='businessPartner'
          element={<LoaderSuspense modal module='OrganisationalProfile'><BusinessPartnerForm /></LoaderSuspense>} />
      </Route>
      <Route
        path='form/:companyId'
        element={<LoaderSuspense outlet modal module='OrganisationalProfile'><CompanyForm /></LoaderSuspense>} >
        <Route
          path='businessPartner'
          element={<LoaderSuspense modal module='OrganisationalProfile'><BusinessPartnerForm /></LoaderSuspense>} />
      </Route>
    </Route>
  </>);
};

export default OrganizationRoutes;
