import ApiAuthorizationRoutes from 'components/api-authorization/api-authorization-routes';
import EnergyRoutes from 'components/energy/energy-routes';
import WaterRoutes from 'components/water/water-routes';
import LoaderSuspense from 'components/navigation/loader-suspense';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router';
import WasteRoutes from 'components/waste/waste-routes';
import HealthAndSafetyRoutes from 'components/health-and-safety/health-and-safety-routes';
import ValueChainRoutes from 'components/value-chain/value-chain-routes';
import CommunitiesRoutes from 'components/communities/communities-routes';
import EmploymentRoutes from 'components/business-partner/employment-routes';
import OrganizationRoutes from 'components/organization/organization-routes';
import SetupRoutes from 'components/setup/setup-routes';
import CareerDevelopmentRoutes from 'components/business-partner/career-development-routes';
import BiodiversityRoutes from 'components/biodiversity/biodiversity-routes';

const AlertModal = React.lazy(() => import('components/shared/alert-modal'));
const ErrorNotFound = React.lazy(() => import('components/shared/errors/not-found'));
const ErrorForbidden = React.lazy(() => import('components/shared/errors/forbidden'));
const GenericError = React.lazy(() => import('components/shared/errors/generic-error'));

export default function EconestRouter () {
  return (
    <Routes>
      <Route index element={<Navigate to='energy' />} />
      <Route
        path='*'
        element={<LoaderSuspense allowAnonymous><ErrorNotFound /></LoaderSuspense>}
      />
      <Route
        path='forbidden'
        element={<LoaderSuspense allowAnonymous><ErrorForbidden /></LoaderSuspense>}
      />
      <Route
        path='error'
        element={<LoaderSuspense allowAnonymous><GenericError /></LoaderSuspense>}
      />
      <Route
        path='alertMessage/:alertId'
        element={<LoaderSuspense modal><AlertModal /></LoaderSuspense>}
      />

      {OrganizationRoutes()}
      {EnergyRoutes() /* Can't use component since it has to be a 'Route'... should be better way*/}
      {WaterRoutes()}
      {WasteRoutes()}
      {BiodiversityRoutes()}
      {EmploymentRoutes()}
      {HealthAndSafetyRoutes()}
      {CommunitiesRoutes()}
      {ValueChainRoutes()}
      {CareerDevelopmentRoutes()}
      {SetupRoutes()}
      {ApiAuthorizationRoutes()}
    </Routes>
  );
}
