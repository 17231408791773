import React from 'react';
import authService from 'services/authorization/AuthorizeService';

export type Permission = {access: string, businessUnit: string, module: string};
interface AccessDetails {
  roles: string[];
  permissions: Permission[];
  token: string;
  getPermissions: () => void;
}

const useGetPermissions = (): AccessDetails => {
  const [token, setToken] = React.useState<string | null>();
  
  const getPermissions = React.useCallback(() => {
    authService.getAccessToken().then(setToken);
  }, [setToken]);
  
  React.useEffect(() => {
    const subscriptionId = authService.subscribe(() => getPermissions());
    getPermissions();
    return () => authService.unsubscribe(subscriptionId);
  });

  if (!token) return {} as AccessDetails;

  const base64 = token?.split('.')[1].replace('-', '+').replace('_', '/');
  const object = JSON.parse(window.atob(base64!));

  const mapDistinctPermissions = (permissions: string | string[] | undefined) => {

    const perm = normalizeToArray(permissions);
    const permissionArr = perm.map(p => {
      const [access, businessUnit, module] = p.split('-');
      return { access, businessUnit, module };
    });

    return permissionArr;
  };

  const normalizeToArray = (value?: string | string[]) => typeof value === 'string' ? [value] : (value ?? []);
  
  const roles: string[] = normalizeToArray(object['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
  const permissions = mapDistinctPermissions(object.permissions);

  return { roles, permissions, token, getPermissions };
};

export default useGetPermissions;
