/* eslint-disable max-lines */
import LoaderSuspense from 'components/navigation/loader-suspense';
import CustomMetricRoutes from 'components/shared/custom-metrics/custom-metrics-routes';
import React from 'react';
import { Route } from 'react-router';

const BiodiversityDashboard = React.lazy(() => import('components/biodiversity/biodiversity-dashboard-wrapper'));
const BiodiversityTable = React.lazy(() => import('components/biodiversity/datagrid/biodiversity-data-grid'));
const BiodiversityModal = React.lazy(() => import('components/biodiversity/form/biodiversity-modal'));
const AuditTrail = React.lazy(() => import('components/audit/audit-trail-grid'));

const BiodiversityRoutes = () => {
  return (
    <>
      {CustomMetricRoutes('Biodiversity')}
      <Route
        path='biodiversity'
        element={<LoaderSuspense outlet module='Biodiversity'><BiodiversityDashboard /></LoaderSuspense>} />
      <Route
        path='biodiversity/table'
        element={<LoaderSuspense outlet module='Biodiversity'><BiodiversityTable /></LoaderSuspense>}>
        <Route
          path='modal'
          element={<LoaderSuspense outlet modal module='Biodiversity'><BiodiversityModal /></LoaderSuspense>} />
        <Route
          path='modal/:recordId'
          element={<LoaderSuspense outlet modal module='Biodiversity'><BiodiversityModal /></LoaderSuspense>} />
        <Route
          path='modal/:recordId/audit-trail'
          element={<LoaderSuspense outlet modal module='Biodiversity'><AuditTrail module='Biodiversity' type='Biodiversity' /></LoaderSuspense>} />
      </Route>
    </>);
};

export default BiodiversityRoutes;