import { DateTime, Duration } from 'luxon';

const defaultDateRange = (): Required<DateRange> => {
  const startOfMonth = DateTime.now().minus(Duration.fromObject({ months: 1 })).startOf('month');
  const endOfMonth = startOfMonth.endOf('month');
  const defaultStartDate: DateDto = { day: startOfMonth.day, month: startOfMonth.month, year: startOfMonth.year };
  const defaultEndDate: DateDto = { day: endOfMonth.day, month: endOfMonth.month, year: endOfMonth.year };
  
  return { startDate: defaultStartDate, endDate: defaultEndDate };
};

export default defaultDateRange;