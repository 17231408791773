/* eslint-disable max-lines */
import { dtsApi } from './dts-api.g';

/* 
Tags to be implemented using enhanceEndpoints();
SEE: https://github.com/reduxjs/redux-toolkit/issues/1855

EXAMPLE:
api.enhanceEndpoints({
  endpoints: {
    getABC: {
      providesTags: []
    },
    postABC: {
      invalidatesTags: []
    }
  }
})
*/

export default dtsApi.enhanceEndpoints({
  endpoints: {
    
  }
});