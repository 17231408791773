import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationsRequest, LocationsResponse } from '../api/main/main-api.g';

interface GeoArgs {
  center: google.maps.LatLngLiteral;
}

interface GeoState {
  center: google.maps.LatLngLiteral;
  markers: LocationsRequest[];
}

export const geoSlice = createSlice({
  name: 'geo',
  initialState: {
    center: { lat: -26.132437244995298, lng: 28.06804641273018 },
    markers: [],
  } as GeoState,
  reducers: {
    updateCenter: (state, action: PayloadAction<GeoArgs>) => {
      state.center = action.payload.center;
    },
    addMarker: (state, action: PayloadAction<LocationsRequest>) => {
      state.markers.push(action.payload);
    },
    addMultiMarkers: (state, action: PayloadAction<LocationsResponse[]>) => {
      let markerArray: LocationsRequest[] = [];

      action.payload.map((location) =>
        markerArray.push({
          type: location.type,
          name: location.name,
          streetNumber: location.streetNumber,
          shortName: location.shortName,
          longName: location.longName,
          suburb: location.suburb,
          city: location.city,
          state: location.state,
          postCode: location.postCode,
          country: location.country,
          description: location.description,
          point: location.point,
          formattedAddress: location.formattedAddress!,
        })
      );
      state.markers.push(...markerArray);
    },

    removeTemp: (state) => {
      state.markers = state.markers.filter((e) => e.type !== 'Pin');
    },
  },
});

export const { reducer } = geoSlice;
export const { updateCenter, addMarker, addMultiMarkers, removeTemp } =
  geoSlice.actions;
