import { Button, SxProps } from '@mui/material';
import ConfirmationModal from 'components/shared/confirmation-modal';
import React from 'react';

interface ConfirmButtonProps<T> {
  item?: T;
  onConfirm: () => void;
  title: string;
  buttonText: string;
  message: string;
  muiIcon?: React.ReactNode,
  buttonSx?: SxProps;
  confirmText?: string;
  cancelText?: string;
  color?: 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'contained' | 'outlined' | 'text'
}

export default function ConfirmButton <T extends unknown> ({ item, onConfirm, buttonText, muiIcon, title, message, buttonSx, variant = 'contained', color = 'primary', confirmText, cancelText }: ConfirmButtonProps<T>) {

  const [openConfirm, setOpenConfirm] = React.useState(false);

  return (
    <>
      <Button color={color} variant={variant} sx={buttonSx} onClick={() => { setOpenConfirm(true); }}>
        {muiIcon ?? null}
        {buttonText ?? 'Open Confirmation'}
      </Button>

      <ConfirmationModal 
        isOpen={openConfirm} 
        confirmText={confirmText}
        cancelText={cancelText} 
        onConfirm={() => onConfirm()} 
        onClose={() => setOpenConfirm(false)}
        title={title}
        message={message}
      />
    </>
  );
}
