import React from 'react';
import { Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { format } from 'formatters/user-response-formatter';
import { DataLoad } from 'services/api/common-types';
import { Dictionary } from 'lodash';
import { identityApi } from 'services/api/identity/identity-api';
import { UserResponse } from 'services/api/identity/identity-types';

interface CaptureByProps extends StackProps {
  userId: string;
}

export default function CaptureBy (props: CaptureByProps) {
  const { userId, ...stackProps } = props;
  const { data: usersDictionary }: DataLoad<Dictionary<UserResponse>> = identityApi.useGetUsersQuery();
  const user = usersDictionary?.[userId];

  return (
    <Stack {...stackProps}>
      <Tooltip title={
        <div>
          <Typography>Changed By: {format(user)} </Typography>
          <Typography>Email: {user?.email ?? 'N/A'} </Typography>
        </div>
      } placement='right-start'>
        <Typography>{format(user)} </Typography>
      </Tooltip>
    </Stack>);
}