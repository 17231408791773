import React from 'react';
import authService from 'services/authorization/AuthorizeService';

const useIsAuthenticated = (enabled: boolean = true) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(null);

  const checkIsAuthenticated = React.useCallback(() => {
    if (enabled)
      authService.isAuthenticated().then(setIsAuthenticated);
  }, [enabled, setIsAuthenticated]);
  
  React.useEffect(() => {
    const subscriptionId = authService.subscribe(() => checkIsAuthenticated());
    checkIsAuthenticated();
    return () => authService.unsubscribe(subscriptionId);
  });
  
  return [isAuthenticated, checkIsAuthenticated];
};

export default useIsAuthenticated;
