import { CalendarMonth, CalendarToday } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import { Button, Paper, Stack, CircularProgress, Typography, FormHelperText, SxProps, Theme, FormControlTypeMap, FormControl, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';
import { api, ReportingPeriodResponse } from 'services/api/main';
import Popover from '@mui/material/Popover';
import { FieldMetaProps } from 'formik';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

interface ReportingPeriodDropdownProps {
  filter?: 'Annual' | 'BiAnnual' | 'Quarterly' | 'Monthly';
  fieldMetaProps?: FieldMetaProps<string>;
  sx?: SxProps<Theme>;
  buttonSx?: SxProps; 
  value: string | undefined;
  formControlProps?: DefaultComponentProps<FormControlTypeMap<{}, 'div'>>;
  onChange?: (id: string) => void;
}

export default function ReportingPeriodDropdown ({ filter = 'Annual', fieldMetaProps, sx, buttonSx = { height: '100%', minWidth: '200px' }, value, formControlProps, onChange }: ReportingPeriodDropdownProps) {
  const { data: reportingPeriodsUnsorted, isLoading } = api.useGetApiCommonReportingPeriodQuery();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | HTMLElement | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClose = React.useCallback((evt: React.SyntheticEvent<Element, Event>) => {
    setAnchorEl(null);
    setOpen(false);
  }, [setAnchorEl]);

  const handleOpen = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e?.currentTarget);
    setOpen(true);
  }, [setAnchorEl]);

  function sortByLatestYearFirst (periods: ReportingPeriodResponse[]): ReportingPeriodResponse[] {
    if (!periods) return [];

    const compareDates = (periodA: ReportingPeriodResponse, periodB: ReportingPeriodResponse): number => {
      if (periodA.periodType === 'Annual') {
        const startYear = new Date(periodA.startDate!).getFullYear();
        const endYear = new Date(periodB.endDate!).getFullYear();
        return endYear - startYear;
      } else {
        return 0;
      }
    };
    return periods.sort((periodA, periodB) => compareDates(periodA!, periodB!));
  };

  const reportingPeriods = sortByLatestYearFirst(reportingPeriodsUnsorted ? [...reportingPeriodsUnsorted] : []);

  const renderTree = (node: ReportingPeriodResponse | undefined) => {
    if (!node) return;

    return (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        label={
          <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }} >
            <Box>
              <CalendarToday />
              {node.name!}
            </Box>
            <Button onClick={e => { 
              onChange?.(node.id);
              handleClose(e); 
            }}>
              Select
            </Button>
          </Stack>}
        id={node.id!} >
        {Array.isArray(reportingPeriods?.filter(x => x.parentId === node.id))
          ? reportingPeriods?.filter(x => x.parentId === node.id).map((childNode) => renderTree(childNode))
          : null
        }
      </TreeItem>
    );
  };

  const id = open ? 'reporting-period-popover' : undefined;
  const reportingPeriodsFil = reportingPeriods!.filter(rp => rp.periodType === filter);

  return (
    <>
      {
        isLoading
          ? <CircularProgress sx={{ width: '100px', mr: '100px' }} /> :
          <Stack
            direction='row'
            justifyContent='center'
            sx={sx}>
            <FormControl
              {...formControlProps}
              fullWidth>
              <>
                <Button variant="outlined" sx={buttonSx} color='primary' onClick={handleOpen} startIcon={<CalendarMonth />}>                    
                  <Typography variant="body1" sx={{ color: 'primary.main', fontWeight: 600, mt: 'auto', mb: 'auto', ml: 1 }} gutterBottom component="div">
                    {!Boolean(value) || value === '' ? 'Select Reporting Period' : reportingPeriods?.find(i => i.id === value)!.name}
                  </Typography>
                </Button>
                {(Boolean(fieldMetaProps) && Boolean(fieldMetaProps?.error) && Boolean(fieldMetaProps!.touched)) ?
                  <FormHelperText sx={{ mt: 0, mb: 1 }} error>{fieldMetaProps!.error}</FormHelperText> :
                  null}
              </>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}>
                <Paper sx={{ height: '100%', overflow: 'auto', minWidth: '250px', width: '100%', margin: '10p' }}>
                  <Stack direction='column' width="100%" minWidth='250px'>
                    <TreeView
                      aria-label="file system navigator"
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      sx={{ overflowY: 'auto' }} >
                      {reportingPeriodsFil.map(rp => renderTree(rp))}
                    </TreeView>
                  </Stack>
                </Paper>
              </Popover>
            </FormControl>
          </Stack>
      }
    </>
  );
}
