import React from 'react';
import { Box, Stack, StackProps } from '@mui/material';
import { DateRange } from 'services/api/main/main-api.g';
import DatePicker from './date-picker';

interface DateRangePickerProps extends Omit<StackProps, 'onChange'> {
  value?: DateRange
  onChange?: (args: DateRange) => void;
  name?: string;
  elementWidth?: number | string;
  elementSx?: any;
}

const defaultName = 'dateRange';

const DateRangePicker = (props: DateRangePickerProps) => {
  const { value, onChange, name, elementWidth, elementSx, ...stackProps } = props;
  const finalName = name || defaultName;
  const startName = !name ? 'startDate' : `${finalName}.startDate`;
  const endName = !name ? 'endDate' : `${finalName}.endDate`;

  return (
    <Stack spacing={1} {...stackProps}>
      <Box>
        <DatePicker
          label="Start Date"
          name={startName}
          InputProps={{ sx: { ...elementSx, width: elementWidth ?? '100%' } }}
          disableMaskedInput
        />
      </Box>
      <Box>
        <DatePicker
          label="End Date"
          name={endName}
          InputProps={{ sx: { ...elementSx, width: elementWidth ?? '100%' } }}
          disableMaskedInput
        />
      </Box>
    </Stack>
  );
};
export default DateRangePicker;
