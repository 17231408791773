import React from 'react';
import { Switch, SwitchProps, Stack, StackProps, Typography, TypographyProps } from '@mui/material';

export interface SwitchControlledProps extends SwitchProps {
  title?: string;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse',
  justifyContent?: StackProps['justifyContent'];
  stackProps?: StackProps;
  titleProps?: TypographyProps;
}

const SwitchControlled = React.memo(({
  title,
  direction = 'row',
  justifyContent = 'normal',
  stackProps,
  titleProps,
  ...switchProps
}: SwitchControlledProps) => {
  return (
    <Stack spacing={1} {...stackProps} direction={direction} justifyContent={justifyContent}>
      {title ? <Typography color="primary" fontWeight='normal' {...titleProps} sx={{ mt: 1, mb: 1, ...(titleProps?.sx ?? {}) }}>{title}</Typography> : null}
      <Switch
        {...switchProps}
        aria-label='controlled'
      />
    </Stack>
  );
});

export default SwitchControlled;