import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { Portal } from '@mui/base';

const ModalLoader = () => (
  <Portal>
    <Dialog PaperProps={{ sx: { overflow: 'visible' } }} open>
      <DialogContent sx={{ pr: 0, pl: 0, overflow: 'visible' }}>
        <div style={styles.container}>
          <img src={require('components/Loading.gif')} alt="loading..." width={80} height={80} />
        </div>
      </DialogContent>
    </Dialog>
  </Portal>
);

const styles: { [name: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    minHeight: 512,
    minWidth: 512,
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default ModalLoader;