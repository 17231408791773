import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import QuantityDisplayTooltip from 'components/display/quantity/quantity-display-tooltip';
import CaptureBy from 'components/shared/capture-by';
import { formatDateTime } from 'components/display/formatters/date-formats';
import { PopoverDeleteButton } from 'components/shared/popover-buttons/delete-popover-button';
import { compareQuantityValues, compareStringValues, compareUsers } from 'components/display/formatters/comparators';
import { Dictionary } from 'lodash';
import { UserResponse } from 'services/api/identity/identity-types';
import { ModuleEnum } from 'services/api/main';

export function getColumns (module: ModuleEnum, deleteTarget: (params: any) => void, allowedToEdit: boolean | undefined, users?: Dictionary<UserResponse>): GridColDef[] {
  return [
    { field: 'module', headerName: 'Module', width: 160, editable: false, hide: true },
    { field: 'targetTypeName', headerName: 'Target Type', width: 160, editable: false },
    {
      field: 'quantity', headerName: 'Value',
      renderCell: (param) => <QuantityDisplayTooltip quantity={param.value} />, width: 140, align: 'left',
      sortComparator: (a, b) => compareQuantityValues(a, b)
    },
    {
      field: 'captureById', headerName: 'Captured By', width: 200,
      renderCell: (params) => <CaptureBy userId={params.value} />,
      sortComparator: (a, b) => compareUsers(users, a, b)
    },
    { field: 'captureTime', headerName: 'Date Created', type: 'date', width: 200, valueFormatter: formatDateTime },
    {
      field: 'reportingPeriod', headerName: 'Reporting Period', width: 140, editable: false, renderCell: (param) =>
        (<>{param?.row?.reportingPeriod?.name}</>),
      sortComparator: (a, b) => compareStringValues(a?.startDate, b?.endDate)
    },
    {
      field: 'id', headerName: 'Actions', width: 180, renderCell: (params) =>
        ( allowedToEdit ? <PopoverDeleteButton module={module} delete={() => deleteTarget({ targetId: params?.id.toString() })} /> : '')
    },
  ];
};