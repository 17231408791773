import React, { ReactNode } from 'react';
import { Stack, SxProps } from '@mui/material';

// TODO: Remove, use 'sideBarChildren' prop on Modal
const ActionSidebar = (props: { children: ReactNode, sx?: SxProps }) => {
  return (
    <Stack
      direction='column'
      sx={{
        position: 'absolute',
        top: '65px',
        right: '0',
        boxShadow: 'inset 6px 0px 6px -3px rgba(0,0,0,0.3)',
        marginRight: '-40px',
        height: 'auto',
        borderRadius: '0 4px 4px 0',
        backgroundColor: 'white',
        ...props.sx }}>
      {props.children}
    </Stack>
  );
};

export default ActionSidebar;