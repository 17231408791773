import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import authService from 'services/authorization/AuthorizeService';
import type { GetIdentityApiUsersResponse } from './identity-types';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_IDENTITYSERVER_BASE_URL,
  prepareHeaders: async (headers) => {
    const token = await authService.getAccessToken();
    if (token) headers.set('Authorization', `Bearer ${token}`);

    return headers;
  }
});

export const identityApi = createApi({
  reducerPath: 'identityApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUsers: builder.query<GetIdentityApiUsersResponse, void>({
      query: () => 'users',
    }),
  }),
});

export const { useGetUsersQuery } = identityApi;