/* eslint-disable max-lines */
import { docsApi } from './docs-api.g';

/* 
Tags to be implemented using enhanceEndpoints();
SEE: https://github.com/reduxjs/redux-toolkit/issues/1855

EXAMPLE:
api.enhanceEndpoints({
  endpoints: {
    getABC: {
      providesTags: []
    },
    postABC: {
      invalidatesTags: []
    }
  }
})
*/

export default docsApi.enhanceEndpoints({
  endpoints: {
    getAllDocuments: {
      providesTags: ['Documents']
    },
    getLinkedFileSummaries: {
      providesTags: ['Documents']
    },
    postFile: {
      invalidatesTags: ['Documents']
    },
    deleteDocument: {
      invalidatesTags: ['Documents']
    },
    getDocumentsByType: {
      providesTags: ['Documents']
    }
  }
});