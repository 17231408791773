import { format } from 'formatters/user-response-formatter';
import { DateTime } from 'luxon';

export const compareGridDateStrings = (a, b): number => {
  return DateTime.fromObject(a).toMillis() - DateTime.fromObject(b).toMillis();
};

export const compareGridDateTimeStrings = (a, b): number => {
  return DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis();
};

export const compareQuantityValues = (a, b): number => {
  return a?.value - b?.value;
};

export const compareStringValues = (a: string, b: string): number => {
  return a.localeCompare(b, 'en', { sensitivity: 'base', ignorePunctuation: true, numeric: true });
};

export const compareUsers = (users, a, b): number => a === b ? 0 : (format(users?.[a]) > format(users?.[b]) ? 1 : -1);