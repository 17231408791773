import React from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/query';
import { showAlert } from 'services/alerts/alert-service';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useMutationNotification =
<QueryArg,
  BaseQuery extends BaseQueryFn,
  TagTypes extends string,
  ResultType,
  ReducerPath extends string = string>
(apiCall: UseMutation<MutationDefinition<QueryArg, BaseQuery, TagTypes, ResultType, ReducerPath>>, onSuccess: () => void, entityName: string, mutationType: 'delete' | 'save' = 'save') => {
  const apiCallOut = apiCall();
  const appDispatch = useAppDispatch();
  const [notifiedId, setNotifiedId] = React.useState<string>();

  React.useEffect(() => {
    if (Boolean(notifiedId) && notifiedId === apiCallOut[1].requestId) return;

    if (apiCallOut[1].isSuccess) {
      appDispatch(showAlert({ severity: 'success', message: `${entityName} successfully ${mutationType === 'save' ? 'saved' : 'deleted'}` }));
      onSuccess();
      setNotifiedId(apiCallOut[1].requestId);
      return;
    }

    if (apiCallOut[1].isError) {
      appDispatch(showAlert({ severity: 'error', message: `Error ${mutationType === 'save' ? 'saving' : 'deleting'} ${entityName}` }));
      setNotifiedId(apiCallOut[1].requestId);
      return;
    }
  }, [apiCallOut, notifiedId, entityName, mutationType, appDispatch, onSuccess]);

  return apiCallOut;
};