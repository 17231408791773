import React from 'react';
import { GridRowParams } from '@mui/x-data-grid';
import { DataLoad } from 'services/api/common-types';
import { useNavigate } from 'react-router';
import {
  api,
  ModuleEnum,
  TargetResponse,
} from 'services/api/main';
import { Dictionary } from 'lodash';
import { useMutationNotification } from 'services/stores/hooks';
import { getColumns } from './target-columns';
import EconestDataGrid from 'components/display/econest-data-grid';
import useGetPermissions from 'helpers/use-permissions';
import { identityApi } from 'services/api/identity/identity-api';
import { UserResponse } from 'services/api/identity/identity-types';

interface TargetSetupProps {
  title?: string;
  module: ModuleEnum
}

const TargetSetup = ({ title, module }: TargetSetupProps) => {
  const navigate = useNavigate();
  const [rowsData, setRowsData] = React.useState([] as any[]);
  const { roles } = useGetPermissions();
  const { data: targetsData, isLoading: isLoadingTargets }: DataLoad<TargetResponse[]> = api.useGetApiTargetAllQuery({ module: module });
  const { data: users, isLoading: isLoadingUsers }: DataLoad<Dictionary<UserResponse>> = identityApi.useGetUsersQuery();

  const [deleteTarget,] = useMutationNotification(api.useDeleteApiTargetByTargetIdMutation, () => {}, 'Target', 'delete');
  const allowedToEdit: boolean | undefined = React.useMemo(() => { 
    if(!roles) return;
    return roles?.includes('Admin') === true || roles?.includes('Executive') === true;
  }, [roles]);
  
  React.useEffect(() => {
    if (!targetsData) return;
    const rows: Dictionary<any>[] = targetsData!.map(row => (
      {
        ...row,
        targetTypeName: row.targetType?.name,
      }));
      
    setRowsData(rows);
  }, [targetsData, module]);

  const HandleRowClicked = (rowData: GridRowParams<{ [key: string]: any }>) =>
    navigate(`${window.location.pathname.replace(/\/$/, '')}/modal/${rowData.id.valueOf().toString()}`);
  
  const columns = React.useMemo(() => getColumns(module, deleteTarget, allowedToEdit, users), [module, deleteTarget, allowedToEdit, users]);

  return (
    <EconestDataGrid
      title={title ? title : 'Targets'}
      columns={columns}
      rows={rowsData}
      HandleRowClicked={allowedToEdit ? HandleRowClicked : undefined}
      isLoading={isLoadingTargets || isLoadingUsers}
      addModalUrl={allowedToEdit ? 'modal' : undefined}
      module={module}
    />
  );
};

export default TargetSetup;
