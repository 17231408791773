import { baseApi as api } from './base-query';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllTemplates: build.query<
      GetAllTemplatesResponse,
      GetAllTemplatesRequest
    >({
      query: () => ({ url: `/templates` }),
    }),
    getTemplatesForType: build.query<
      GetTemplatesForTypeResponse,
      GetTemplatesForTypeRequest
    >({
      query: (queryArg) => ({ url: `/templates/${queryArg.templateType}` }),
    }),
    apply: build.mutation<ApplyResponse, ApplyRequest>({
      query: (queryArg) => ({
        url: `/templates/${queryArg.templateId}/apply`,
        method: 'POST',
        body: queryArg.reportRequest,
      }),
    }),
    addTemplate: build.mutation<AddTemplateResponse, AddTemplateRequest>({
      query: (queryArg) => ({
        url: `/addTemplate/${queryArg.templateTypeId}`,
        method: 'POST',
        body: queryArg.createTemplateDto,
      }),
    }),
    uploadFile: build.mutation<UploadFileResponse, UploadFileRequest>({
      query: (queryArg) => ({
        url: `/add-file-to-template/${queryArg.templateId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as dtsApi };
export type GetAllTemplatesResponse =
  /** status 200 Payload of Array of TemplateDto */ TemplateDto[];
export type GetAllTemplatesRequest = void;
export type GetTemplatesForTypeResponse =
  /** status 200 Payload of Array of TemplateDto */ TemplateDto[];
export type GetTemplatesForTypeRequest = {
  templateType: string;
};
export type ApplyResponse =
  /** status 200 Payload of AppliedTemplateDto */ AppliedTemplateDto;
export type ApplyRequest = {
  templateId: string;
  /** JSON Data */
  reportRequest: ReportRequest;
};
export type AddTemplateResponse =
  /** status 200 Payload of TemplateDto */ TemplateDto;
export type AddTemplateRequest = {
  templateTypeId: string;
  /** JSON Data */
  createTemplateDto: CreateTemplateDto;
};
export type UploadFileResponse = unknown;
export type UploadFileRequest = {
  templateId: string;
  /** File data */
  body: Blob;
};
export type DocumentKey = {
  documentId?: string;
};
export type ApplicabilityDto = {
  attributeName?: string;
  attributeValues?: string[];
};
export type TemplateAttributeDto = {
  attributeName?: string;
  attributeValue?: string;
};
export type TranslationDto = {
  cultureRef?: string;
  translation1?: string;
  translation2?: string;
};
export type TemplateDto = {
  id?: string;
  parentId?: string | null;
  variantParentId?: string;
  typeId?: string;
  name?: string;
  editableDocumentKey?: DocumentKey;
  printedDocumentKey?: DocumentKey;
  culture?: string;
  description?: string;
  active?: boolean;
  applicability?: ApplicabilityDto[];
  attributes?: TemplateAttributeDto[];
  translations?: TranslationDto[];
  keyWords?: string;
  modifiedOn?: string;
  modifiedBy?: string;
};
export type AppliedTemplateDto = {
  id?: string;
  entityId?: string;
  version?: number;
  templateId?: string;
  printedDocumentKey?: DocumentKey;
};
export type ReportRequest = {
  reportingPeriodId?: string | null;
  entityId?: string | null;
  businessUnitIds: string[];
  companyName?: string;
  companyShortName?: string;
  module?: string;
};
export type CreateTemplateDto = {
  parentId?: string | null;
  typeId?: string;
  name?: string;
  description?: string;
  culture?: string;
  keywords?: string;
  applicability?: ApplicabilityDto[];
  attributes?: TemplateAttributeDto[];
  translations?: TranslationDto[];
};
export const {
  useGetAllTemplatesQuery,
  useGetTemplatesForTypeQuery,
  useApplyMutation,
  useAddTemplateMutation,
  useUploadFileMutation,
} = injectedRtkApi;
