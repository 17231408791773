import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import api from 'services/api/main/main-api-tagged';
import docsApi from 'services/api/docs/docs-api-tagged';
import dtsApi from 'services/api/dts/dts-api-tagged';
import { identityApi } from 'services/api/identity/identity-api';
import { alertSlice } from '../alerts/alert-service';
import { geoSlice } from '../geo/geo-service';
import { loaderSlice } from '../loader/loader-service';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
    [docsApi.reducerPath]: docsApi.reducer,
    [dtsApi.reducerPath]: dtsApi.reducer,
    [identityApi.reducerPath]: identityApi.reducer,
    alerts: alertSlice.reducer,
    geo: geoSlice.reducer,
    loadingState: loaderSlice.reducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware).concat(docsApi.middleware).concat(dtsApi.middleware).concat(identityApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
