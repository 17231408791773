/* eslint-disable max-lines */
import React from 'react';
import { Settings, Assessment, Timeline } from '@mui/icons-material';
import {
  Biodiversity, Communities, Employees, EnergyAndEmissions,
  HealthAndSafety,
  OrganisationalProfile,
  ValueChain,
  Waste, Water
} from 'icons/menu-icons';
import {
  AssessmentResults,
  CarbonTax,
  Companies, Disclosures, EmployeeRoles, ExternalQuestionnaire,
  FundingApplication,
  Intensity, InternalQuestionnaire, MonitoringAndEvaluation,
  NetZero,
  SafetyIncidents, Scope1,
  Scope2, Scope3, SocialIncidents, Suppliers,
  WasteDisposal, WaterMeterInfo, Target
} from 'icons/sub-menu-icons';
import { FeatureFlagSettings } from 'services/api/main';

export type MenuItemType = {
  id?: string;
  index?: number;
  modules?: string[]; 
  label: string;
  url: string;
  icon: JSX.Element;
  menuOpen?: boolean;
  disabled?: boolean;
  subMenu?: MenuItemType[];
  secondaryMenu?: MenuItemType[];
  hidden?: boolean | null;
};

export const GetMenuItems = (featureFlags: FeatureFlagSettings): MenuItemType[] => [
  {
    label: 'Organisational Profile', url: '/organization', icon: <OrganisationalProfile />, menuOpen: false,
    id: 'OrganisationalProfile',
    disabled: false,
    subMenu: [
      { label: 'Company', url: '/organization/table', icon: <Companies /> },
      { label: 'Disclosures', url: '/disclosures/OrganisationalProfile', icon: <Disclosures /> },
    ]
  },
  {
    label: 'Energy & Emissions', url: '/energy', icon: <EnergyAndEmissions />, menuOpen: false,
    id: 'Energy',
    subMenu: [
      { label: 'Scope 1', url: '/energy/Scope1/table', icon: <Scope1 /> },
      { label: 'Scope 2', url: '/energy/Scope2/table', icon: <Scope2 /> },
      {
        label: 'Scope 3', url: '/energy/scope3/table', icon: <Scope3 />,
        secondaryMenu: [
          { label: 'Relevance Assessment', url: '/energy/Scope3/entries', icon: <AssessmentResults /> },
          { label: 'Stakeholder Questionnaire', url: '/energy/Scope3/stakeholder', icon: <ExternalQuestionnaire /> },
          { label: 'Internal Questionnaire', url: '/energy/Scope3/internal', icon: <InternalQuestionnaire /> },
        ]
      },
      {
        label: 'Energy & GHG Saving', url: '/energy/net-zero/project/table', icon: <NetZero />,
        id: 'NetZero',
        disabled: false,
        secondaryMenu: [
          { label: 'Applications', url: '/energy/net-zero/application/table', icon: <FundingApplication /> }
        ]
      },
      { label: 'Carbon Tax', url: '/energy/carbon-tax/table', icon: <CarbonTax />, disabled: false, id: 'CarbonTax' },
      { label: 'Data Management', url: '/energy/data-management/table', icon: <Timeline />, hidden: !featureFlags?.energyFlags?.dataManagement },
      { label: 'Targets', url: '/energy/targets', icon: <Target color='#00055C' /> },
      { label: 'Custom Metrics', url: '/energy/metrics', icon: <Intensity /> },
      { label: 'Disclosures', url: '/disclosures/Energy', icon: <Disclosures /> },
    ]
  },
  {
    label: 'Water', url: '/water', icon: <Water />, menuOpen: false,
    id: 'Water',
    disabled: false,
    subMenu: [
      { label: 'Water Meter Info', url: '/water/table', icon: <WaterMeterInfo /> },
      { label: 'Targets', url: '/water/targets', icon: <Target color='#00055C' /> },
      { label: 'Custom Metrics', url: '/water/metrics', icon: <Intensity /> },
      { label: 'Disclosures', url: '/disclosures/Water', icon: <Disclosures /> },
    ]
  },
  {
    label: 'Waste', url: '/waste', icon: <Waste />, menuOpen: false,
    id: 'Waste',
    disabled: false,
    subMenu: [
      { label: 'Disposal', url: '/waste/disposal/table', icon: <WasteDisposal /> },
      { label: 'Data Management', url: '/waste/data-management/table', icon: <Timeline />, hidden: !featureFlags?.wasteFlags?.dataManagement },
      { label: 'Targets', url: '/waste/targets', icon: <Target color='#00055C' /> },
      { label: 'Custom Metrics', url: '/waste/metrics', icon: <Intensity /> },
      { label: 'Disclosures', url: '/disclosures/Waste', icon: <Disclosures /> },
    ]
  },
  {
    label: 'Biodiversity', url: '/biodiversity', icon: <Biodiversity />, menuOpen: false,
    id: 'Biodiversity',
    disabled: false,
    subMenu: [
      { label: 'Impacts', url: '/biodiversity/table', icon: <Assessment /> },
      { label: 'Custom Metrics', url: '/biodiversity/metrics', icon: <Intensity /> },
      { label: 'Disclosures', url: '/disclosures/Biodiversity', icon: <Disclosures /> }
    ]
  },
  {
    label: 'Employment', url: '/employment', icon: <Employees />, menuOpen: false,
    id: 'Employment',
    disabled: false,
    subMenu: [
      { label: 'Employee Audits', url: '/employeeAudits/table', icon: <EmployeeRoles /> },
      { label: 'Targets', url: '/employment/targets', icon: <Target color='#00055C' /> },
      { label: 'Custom Metrics', url: '/employment/metrics', icon: <Intensity /> },
      { label: 'Disclosures', url: '/disclosures/Employment', icon: <Disclosures /> },
    ]
  },
  {
    label: 'Health & Safety', url: '/health-and-safety', icon: <HealthAndSafety />, menuOpen: false,
    id: 'HealthAndSafety',
    disabled: false,
    subMenu: [
      { label: 'Incidents', url: '/safety/incidents/table', icon: <SafetyIncidents /> },
      { label: 'Targets', url: '/health-and-safety/targets', icon: <Target color='#00055C' /> },
      { label: 'Frequency Metric', url: '/HealthAndSafety/metrics', icon: <Intensity /> },
      { label: 'Disclosures', url: '/disclosures/HealthAndSafety', icon: <Disclosures /> },
    ]
  },
  {
    label: 'Communities', url: '/communities', icon: <Communities />, menuOpen: false,
    id: 'Communities',
    disabled: false,
    subMenu: [
      { label: 'Impact Assessments', url: '/communities/impact-assessment/table', icon: <Assessment /> },
      { label: 'Funding Application', url: '/communities/csi/table', icon: <FundingApplication /> },
      { label: 'Monitoring & Evaluation', url: '/communities/projects/table', icon: <MonitoringAndEvaluation /> },
      { label: 'Social Incidents', url: '/communities/social-incidents/table', icon: <SocialIncidents /> },
      { label: 'Custom Metrics', url: '/communities/metrics', icon: <Intensity /> },
      { label: 'Disclosures', url: '/disclosures/Communities', icon: <Disclosures /> },
    ]
  },
  {
    label: 'Value Chain', url: '/value-chain', icon: <ValueChain />, menuOpen: false,
    id: 'ValueChain',
    disabled: false,
    subMenu: [
      { label: 'Supplier Review', url: '/value-chain/supplier-review/table', icon: <Suppliers /> },
      { label: 'Disclosures', url: '/disclosures/ValueChain', icon: <Disclosures /> },
    ]
  },
];

export const TechMenuItems = [
  {
    label: 'Settings', url: '/setup', icon: <Settings sx={{ color:'#ffffff' }}/> , menuOpen: false,
    id: 'Settings',
    requiredRole: 'Admin',
    disabled: false,
    subMenu: [] 
  },
];
