import React, { useEffect } from 'react';
import { TextField, Stack } from '@mui/material';
import GenericDropdown from './generic-dropdown';
import { useField, useFormikContext } from 'formik';
import { UnitResponse, UnitTypeEnum, useGetApiCommonUnitsByUnitTypeQuery } from 'services/api/main';
import { DataLoad } from 'services/api/common-types';
import { Dictionary } from 'lodash';
import IHasQuantity from 'types/shared/has-quantity';
import EnergyFormValuesInferred from 'components/energy/energy/form/single/form-values';

interface QuantityCapture {
  id?: string;
  displayColumn?: boolean;
  path?: string | null;
  hideValue?: boolean;
  unitType?: UnitTypeEnum;
  name?: string;
  unitTypeReadOnly?: boolean;
  unitReadOnly?: boolean;
  filterUnitTypes?: string[];
  unitTypeOnChange?: (u, id, f) => void;
  onChange?: React.Dispatch<React.SetStateAction<UnitResponse | null>>;
}

const UnitQuantityCapture = (props: QuantityCapture) => {
 
  useEffect(() => {
    const handleWheel = (_event: WheelEvent) => {
      const activeElement = document.activeElement as HTMLInputElement;
      if (activeElement && activeElement.type === 'number') {
        activeElement.blur();
      }
    };

    document.addEventListener('wheel', handleWheel);

    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const { displayColumn, path, hideValue, unitType, unitReadOnly, name = 'quantity', id, onChange } = props;
  const { values, setFieldValue } = useFormikContext<IHasQuantity>();
  const [quantityFieldValue, metaQuantityFieldValue] = useField(`${path ? path : ''}${path ? '.' : ''}${name}.value`);
  const [field] = useField<EnergyFormValuesInferred>(`${path ? path : ''}`);
  
  const finalUnitType = unitType ?? field?.value?.unitType ?? 'Unknown';

  const unitDictionary: DataLoad<Dictionary<UnitResponse>>
    = useGetApiCommonUnitsByUnitTypeQuery({ unitType: finalUnitType });
  useEffect(() => {
    if(typeof props.unitTypeOnChange === 'function') {
      props.unitTypeOnChange!(values.unitType, values.configuredEmissionSourceId, setFieldValue)!;
    }
  }, [values.unitType, values.configuredEmissionSourceId, setFieldValue, props.unitTypeOnChange]);

  const handleUnitChange = (selectedUnit: UnitResponse) => {
    if (onChange) {
      onChange(selectedUnit);
    }
  };
  
  return (
    <Stack sx={{ flex: 2 }} direction={displayColumn ? 'column' : 'row'} spacing={1} >
      <TextField
        id="outline-basic"
        sx={{ flex: 1, minWidth: '100px' }}
        autoComplete="off"
        label="Enter Quantity"
        variant="outlined"
        hidden={hideValue}
        error={(!!metaQuantityFieldValue && !!metaQuantityFieldValue.error && metaQuantityFieldValue.touched)}
        helperText={(!!metaQuantityFieldValue && !!metaQuantityFieldValue.error && metaQuantityFieldValue.touched) ? metaQuantityFieldValue.error : null}
        {...quantityFieldValue}
        value={quantityFieldValue.value || ''}
        onChange={quantityFieldValue.onChange}
        type='number' />
      {
        (unitType ?? values.unitType) !== 'Scalar'
          ? <GenericDropdown
            key={unitDictionary?.requestId!}
            stackSx={{ flex: 1, minWidth: '100px' }}
            selectElementId={`${id}-unit-select`}
            selectElementLabel='Select Unit'
            data={!!unitDictionary.data ? Object.values(unitDictionary!.data!).filter(u => u.isEnabled === true) : []}
            getKey={d => d.id!}
            getDisplay={d => `${d.name} - ${d.abbreviation}`}
            disabled={unitReadOnly}
            getSelectedDisplay={d => `${d.abbreviation}`}
            isLoading={unitDictionary.isFetching}
            name={`${path ? path : ''}${path ? '.' : ''}${name}.unit`}
            onChange={handleUnitChange} />
          : null
      }
    </Stack>
  );
};

export default UnitQuantityCapture;