import { baseApi as api } from './base-query';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiAlertsByAlertId: build.query<
      GetApiAlertsByAlertIdResponse,
      GetApiAlertsByAlertIdArg
    >({
      query: (queryArg) => ({ url: `/api/alerts/${queryArg.alertId}` }),
    }),
    getApiAlerts: build.query<GetApiAlertsResponse, GetApiAlertsArg>({
      query: () => ({ url: `/api/alerts` }),
    }),
    putApiAlerts: build.mutation<PutApiAlertsResponse, PutApiAlertsArg>({
      query: (queryArg) => ({
        url: `/api/alerts`,
        method: 'PUT',
        body: queryArg.alertRequest,
      }),
    }),
    putApiBiodiversity: build.mutation<
      PutApiBiodiversityResponse,
      PutApiBiodiversityArg
    >({
      query: (queryArg) => ({
        url: `/api/biodiversity`,
        method: 'PUT',
        body: queryArg.biodiversityImpactRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiBiodiversityPaginated: build.query<
      GetApiBiodiversityPaginatedResponse,
      GetApiBiodiversityPaginatedArg
    >({
      query: (queryArg) => ({
        url: `/api/biodiversity/paginated`,
        params: {
          PageNumber: queryArg.pageNumber,
          PageSize: queryArg.pageSize,
          SkipCount: queryArg.skipCount,
          OperationId: queryArg.operationId,
          OperationType: queryArg.operationType,
          ImpactDriver: queryArg.impactDriver,
          IsPositive: queryArg.isPositive,
        },
      }),
    }),
    getApiBiodiversityById: build.query<
      GetApiBiodiversityByIdResponse,
      GetApiBiodiversityByIdArg
    >({
      query: (queryArg) => ({ url: `/api/biodiversity/${queryArg.id}` }),
    }),
    deleteApiBiodiversityById: build.mutation<
      DeleteApiBiodiversityByIdResponse,
      DeleteApiBiodiversityByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/biodiversity/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiBiodiversityByIdSignOff: build.mutation<
      PostApiBiodiversityByIdSignOffResponse,
      PostApiBiodiversityByIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/biodiversity/${queryArg.id}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    getApiBiodiversityByIdAuditTrail: build.query<
      GetApiBiodiversityByIdAuditTrailResponse,
      GetApiBiodiversityByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/biodiversity/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiBiodiversityByIdChangeSummary: build.query<
      GetApiBiodiversityByIdChangeSummaryResponse,
      GetApiBiodiversityByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/biodiversity/${queryArg.id}/change-summary`,
      }),
    }),
    getApiBiodiversityImpactRating: build.query<
      GetApiBiodiversityImpactRatingResponse,
      GetApiBiodiversityImpactRatingArg
    >({
      query: () => ({ url: `/api/biodiversity/impact-rating` }),
    }),
    getApiBiodiversityImpactDriver: build.query<
      GetApiBiodiversityImpactDriverResponse,
      GetApiBiodiversityImpactDriverArg
    >({
      query: () => ({ url: `/api/biodiversity/impact-driver` }),
    }),
    getApiBiodiversityDashboard: build.query<
      GetApiBiodiversityDashboardResponse,
      GetApiBiodiversityDashboardArg
    >({
      query: (queryArg) => ({
        url: `/api/biodiversity/dashboard`,
        params: {
          ReportingPeriodId: queryArg.reportingPeriodId,
          BusinessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    getApiCareerDevelopmentBursaryByBursaryGuid: build.query<
      GetApiCareerDevelopmentBursaryByBursaryGuidResponse,
      GetApiCareerDevelopmentBursaryByBursaryGuidArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/bursary/${queryArg.bursaryGuid}`,
      }),
    }),
    putApiCareerDevelopmentBursary: build.mutation<
      PutApiCareerDevelopmentBursaryResponse,
      PutApiCareerDevelopmentBursaryArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/bursary`,
        method: 'PUT',
        body: queryArg.bursaryRequest,
      }),
    }),
    getApiBusinessPartnerGender: build.query<
      GetApiBusinessPartnerGenderResponse,
      GetApiBusinessPartnerGenderArg
    >({
      query: () => ({ url: `/api/business-partner/gender` }),
    }),
    getApiBusinessPartnerEthnicity: build.query<
      GetApiBusinessPartnerEthnicityResponse,
      GetApiBusinessPartnerEthnicityArg
    >({
      query: () => ({ url: `/api/business-partner/ethnicity` }),
    }),
    getApiBusinessPartnerByBpGuid: build.query<
      GetApiBusinessPartnerByBpGuidResponse,
      GetApiBusinessPartnerByBpGuidArg
    >({
      query: (queryArg) => ({
        url: `/api/business-partner/${queryArg.bpGuid}`,
      }),
    }),
    getApiBusinessPartnerByIdByIdentificationValue: build.query<
      GetApiBusinessPartnerByIdByIdentificationValueResponse,
      GetApiBusinessPartnerByIdByIdentificationValueArg
    >({
      query: (queryArg) => ({
        url: `/api/business-partner/byId/${queryArg.identificationValue}`,
      }),
    }),
    getApiBusinessPartnerSearchBySearchTerm: build.query<
      GetApiBusinessPartnerSearchBySearchTermResponse,
      GetApiBusinessPartnerSearchBySearchTermArg
    >({
      query: (queryArg) => ({
        url: `/api/business-partner/search/${queryArg.searchTerm}`,
      }),
    }),
    putApiBusinessPartner: build.mutation<
      PutApiBusinessPartnerResponse,
      PutApiBusinessPartnerArg
    >({
      query: (queryArg) => ({
        url: `/api/business-partner`,
        method: 'PUT',
        body: queryArg.businessPartnerRequest,
      }),
    }),
    getApiCommonBusinessUnits: build.query<
      GetApiCommonBusinessUnitsResponse,
      GetApiCommonBusinessUnitsArg
    >({
      query: () => ({ url: `/api/common/business-units` }),
    }),
    getApiEnergyCarbonTax: build.query<
      GetApiEnergyCarbonTaxResponse,
      GetApiEnergyCarbonTaxArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/carbon-tax`,
        params: {
          reportingPeriodId: queryArg.reportingPeriodId,
          businessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    putApiEnergyCarbonTax: build.mutation<
      PutApiEnergyCarbonTaxResponse,
      PutApiEnergyCarbonTaxArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/carbon-tax`,
        method: 'PUT',
        body: queryArg.carbonTaxInputRequest,
      }),
    }),
    postApiEnergyCarbonTaxCalculatedValues: build.mutation<
      PostApiEnergyCarbonTaxCalculatedValuesResponse,
      PostApiEnergyCarbonTaxCalculatedValuesArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/carbon-tax/calculated-values`,
        method: 'POST',
        body: queryArg.carbonTaxInputRequest,
      }),
    }),
    getApiEnergyCarbonTaxRate: build.query<
      GetApiEnergyCarbonTaxRateResponse,
      GetApiEnergyCarbonTaxRateArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/carbon-tax/rate`,
        params: { reportingPeriodId: queryArg.reportingPeriodId },
      }),
    }),
    getApiEnergyCarbonTaxSchedules: build.query<
      GetApiEnergyCarbonTaxSchedulesResponse,
      GetApiEnergyCarbonTaxSchedulesArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/carbon-tax/schedules`,
        params: {
          reportingPeriodId: queryArg.reportingPeriodId,
          businessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    postApiEnergyCarbonTaxByIdSignOff: build.mutation<
      PostApiEnergyCarbonTaxByIdSignOffResponse,
      PostApiEnergyCarbonTaxByIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/carbon-tax/${queryArg.id}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    deleteApiEnergyCarbonTaxByCarbonTaxInputId: build.mutation<
      DeleteApiEnergyCarbonTaxByCarbonTaxInputIdResponse,
      DeleteApiEnergyCarbonTaxByCarbonTaxInputIdArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/carbon-tax/${queryArg.carbonTaxInputId}`,
        method: 'DELETE',
      }),
    }),
    getApiCareerDevelopment: build.query<
      GetApiCareerDevelopmentResponse,
      GetApiCareerDevelopmentArg
    >({
      query: () => ({ url: `/api/career-development` }),
    }),
    postApiCareerDevelopmentByCareerDevelopmentIdSignOff: build.mutation<
      PostApiCareerDevelopmentByCareerDevelopmentIdSignOffResponse,
      PostApiCareerDevelopmentByCareerDevelopmentIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/${queryArg.careerDevelopmentId}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    deleteApiCareerDevelopmentByCareerDevelopmentId: build.mutation<
      DeleteApiCareerDevelopmentByCareerDevelopmentIdResponse,
      DeleteApiCareerDevelopmentByCareerDevelopmentIdArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/${queryArg.careerDevelopmentId}`,
        method: 'DELETE',
      }),
    }),
    getApiCareerDevelopmentByIdAuditTrail: build.query<
      GetApiCareerDevelopmentByIdAuditTrailResponse,
      GetApiCareerDevelopmentByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiCareerDevelopmentCareerReviewByCareerReviewGuid: build.query<
      GetApiCareerDevelopmentCareerReviewByCareerReviewGuidResponse,
      GetApiCareerDevelopmentCareerReviewByCareerReviewGuidArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/career-review/${queryArg.careerReviewGuid}`,
      }),
    }),
    putApiCareerDevelopmentCareerReview: build.mutation<
      PutApiCareerDevelopmentCareerReviewResponse,
      PutApiCareerDevelopmentCareerReviewArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/career-review`,
        method: 'PUT',
        body: queryArg.careerReviewRequest,
      }),
    }),
    getApiCompanyByCompanyGuid: build.query<
      GetApiCompanyByCompanyGuidResponse,
      GetApiCompanyByCompanyGuidArg
    >({
      query: (queryArg) => ({ url: `/api/company/${queryArg.companyGuid}` }),
    }),
    deleteApiCompanyByCompanyGuid: build.mutation<
      DeleteApiCompanyByCompanyGuidResponse,
      DeleteApiCompanyByCompanyGuidArg
    >({
      query: (queryArg) => ({
        url: `/api/company/${queryArg.companyGuid}`,
        method: 'DELETE',
      }),
    }),
    getApiCompanySearchBySearchTerm: build.query<
      GetApiCompanySearchBySearchTermResponse,
      GetApiCompanySearchBySearchTermArg
    >({
      query: (queryArg) => ({
        url: `/api/company/search/${queryArg.searchTerm}`,
      }),
    }),
    getApiCompany: build.query<GetApiCompanyResponse, GetApiCompanyArg>({
      query: () => ({ url: `/api/company` }),
    }),
    putApiCompany: build.mutation<PutApiCompanyResponse, PutApiCompanyArg>({
      query: (queryArg) => ({
        url: `/api/company`,
        method: 'PUT',
        body: queryArg.companyRequest,
      }),
    }),
    getApiCompanyTypes: build.query<
      GetApiCompanyTypesResponse,
      GetApiCompanyTypesArg
    >({
      query: () => ({ url: `/api/company/types` }),
    }),
    getApiCompanyRelationshipsDefinitions: build.query<
      GetApiCompanyRelationshipsDefinitionsResponse,
      GetApiCompanyRelationshipsDefinitionsArg
    >({
      query: () => ({ url: `/api/company/relationships/definitions` }),
    }),
    getApiCommonCustomMetricByCustomMetricId: build.query<
      GetApiCommonCustomMetricByCustomMetricIdResponse,
      GetApiCommonCustomMetricByCustomMetricIdArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric/${queryArg.customMetricId}`,
      }),
    }),
    getApiCommonCustomMetric: build.query<
      GetApiCommonCustomMetricResponse,
      GetApiCommonCustomMetricArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric`,
        params: {
          module: queryArg['module'],
          isIntensity: queryArg.isIntensity,
          MetricTypeId: queryArg.metricTypeId,
          LocationId: queryArg.locationId,
          StateId: queryArg.stateId,
          StartDate: queryArg.startDate,
          EndDate: queryArg.endDate,
        },
      }),
    }),
    putApiCommonCustomMetric: build.mutation<
      PutApiCommonCustomMetricResponse,
      PutApiCommonCustomMetricArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric`,
        method: 'PUT',
        body: queryArg.customMetricRequest,
        params: { id: queryArg.id },
      }),
    }),
    deleteApiCommonCustomMetric: build.mutation<
      DeleteApiCommonCustomMetricResponse,
      DeleteApiCommonCustomMetricArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric`,
        method: 'DELETE',
        params: { customMetricId: queryArg.customMetricId },
      }),
    }),
    getApiCommonCustomMetricNormalized: build.query<
      GetApiCommonCustomMetricNormalizedResponse,
      GetApiCommonCustomMetricNormalizedArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric/normalized`,
        params: {
          Module: queryArg['module'],
          IsIntensity: queryArg.isIntensity,
          ReportingPeriodId: queryArg.reportingPeriodId,
          BusinessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    getApiCommonCustomMetricTypes: build.query<
      GetApiCommonCustomMetricTypesResponse,
      GetApiCommonCustomMetricTypesArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric/types`,
        params: {
          module: queryArg['module'],
          isIntensity: queryArg.isIntensity,
        },
      }),
    }),
    getApiCommonCustomMetricTypeRoots: build.query<
      GetApiCommonCustomMetricTypeRootsResponse,
      GetApiCommonCustomMetricTypeRootsArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric/type-roots`,
        params: {
          module: queryArg['module'],
          isIntensity: queryArg.isIntensity,
        },
      }),
    }),
    putApiCommonCustomMetricBatch: build.mutation<
      PutApiCommonCustomMetricBatchResponse,
      PutApiCommonCustomMetricBatchArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric/batch`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiCommonCustomMetricInputTemplates: build.query<
      GetApiCommonCustomMetricInputTemplatesResponse,
      GetApiCommonCustomMetricInputTemplatesArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric/input-templates`,
        params: {
          locationId: queryArg.locationId,
          module: queryArg['module'],
          isIntensity: queryArg.isIntensity,
          root: queryArg.root,
        },
      }),
    }),
    postApiCommonCustomMetricByCustomMetricIdSignOff: build.mutation<
      PostApiCommonCustomMetricByCustomMetricIdSignOffResponse,
      PostApiCommonCustomMetricByCustomMetricIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric/${queryArg.customMetricId}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    getApiCommonCustomMetricByCustomMetricIdAuditTrail: build.query<
      GetApiCommonCustomMetricByCustomMetricIdAuditTrailResponse,
      GetApiCommonCustomMetricByCustomMetricIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric/${queryArg.customMetricId}/audit-trail`,
      }),
    }),
    getApiCommonCustomMetricByIdChangeSummary: build.query<
      GetApiCommonCustomMetricByIdChangeSummaryResponse,
      GetApiCommonCustomMetricByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/common/custom-metric/${queryArg.id}/change-summary`,
      }),
    }),
    getApiDashboards: build.query<
      GetApiDashboardsResponse,
      GetApiDashboardsArg
    >({
      query: (queryArg) => ({
        url: `/api/dashboards`,
        params: { dashboardType: queryArg.dashboardType },
      }),
    }),
    patchApiDashboards: build.mutation<
      PatchApiDashboardsResponse,
      PatchApiDashboardsArg
    >({
      query: (queryArg) => ({
        url: `/api/dashboards`,
        method: 'PATCH',
        body: queryArg.dashboardRequest,
      }),
    }),
    getApiDashboardsById: build.query<
      GetApiDashboardsByIdResponse,
      GetApiDashboardsByIdArg
    >({
      query: (queryArg) => ({ url: `/api/dashboards/${queryArg.id}` }),
    }),
    getApiCommonDateEnumFrequency: build.query<
      GetApiCommonDateEnumFrequencyResponse,
      GetApiCommonDateEnumFrequencyArg
    >({
      query: () => ({ url: `/api/common/date-enum/frequency` }),
    }),
    getApiCommonDateEnumFrequencyWithHourly: build.query<
      GetApiCommonDateEnumFrequencyWithHourlyResponse,
      GetApiCommonDateEnumFrequencyWithHourlyArg
    >({
      query: () => ({ url: `/api/common/date-enum/frequency-with-hourly` }),
    }),
    postApiDisclosures: build.mutation<
      PostApiDisclosuresResponse,
      PostApiDisclosuresArg
    >({
      query: (queryArg) => ({
        url: `/api/disclosures`,
        method: 'POST',
        body: queryArg.disclosuresRequest,
      }),
    }),
    getApiDisclosuresByStandardIdAndModuleId: build.query<
      GetApiDisclosuresByStandardIdAndModuleIdResponse,
      GetApiDisclosuresByStandardIdAndModuleIdArg
    >({
      query: (queryArg) => ({
        url: `/api/disclosures/${queryArg.standardId}/${queryArg.moduleId}`,
      }),
    }),
    getApiDisclosuresChildren: build.query<
      GetApiDisclosuresChildrenResponse,
      GetApiDisclosuresChildrenArg
    >({
      query: (queryArg) => ({
        url: `/api/disclosures/children`,
        params: { parentId: queryArg.parentId },
      }),
    }),
    getApiDisclosuresByDisclosureId: build.query<
      GetApiDisclosuresByDisclosureIdResponse,
      GetApiDisclosuresByDisclosureIdArg
    >({
      query: (queryArg) => ({
        url: `/api/disclosures/${queryArg.disclosureId}`,
      }),
    }),
    patchApiDisclosuresAnswers: build.mutation<
      PatchApiDisclosuresAnswersResponse,
      PatchApiDisclosuresAnswersArg
    >({
      query: (queryArg) => ({
        url: `/api/disclosures/answers`,
        method: 'PATCH',
        body: queryArg.patchDisclosureAnswersDto,
      }),
    }),
    getApiDisclosuresAnswers: build.query<
      GetApiDisclosuresAnswersResponse,
      GetApiDisclosuresAnswersArg
    >({
      query: (queryArg) => ({
        url: `/api/disclosures/answers`,
        params: {
          disclosureId: queryArg.disclosureId,
          businessUnitId: queryArg.businessUnitId,
          reportingPeriodId: queryArg.reportingPeriodId,
          indexingValue: queryArg.indexingValue,
        },
      }),
    }),
    getApiDisclosuresCompleteness: build.query<
      GetApiDisclosuresCompletenessResponse,
      GetApiDisclosuresCompletenessArg
    >({
      query: (queryArg) => ({
        url: `/api/disclosures/completeness`,
        params: {
          standardId: queryArg.standardId,
          targetId: queryArg.targetId,
        },
      }),
    }),
    putApiDisclosuresMapping: build.mutation<
      PutApiDisclosuresMappingResponse,
      PutApiDisclosuresMappingArg
    >({
      query: (queryArg) => ({
        url: `/api/disclosures/mapping`,
        method: 'PUT',
        body: queryArg.disclosureMapRequest,
      }),
    }),
    putApiDisclosuresIndexing: build.mutation<
      PutApiDisclosuresIndexingResponse,
      PutApiDisclosuresIndexingArg
    >({
      query: (queryArg) => ({
        url: `/api/disclosures/indexing`,
        method: 'PUT',
        body: queryArg.disclosureIndexRequest,
      }),
    }),
    getApiDisclosuresResolvers: build.query<
      GetApiDisclosuresResolversResponse,
      GetApiDisclosuresResolversArg
    >({
      query: () => ({ url: `/api/disclosures/resolvers` }),
    }),
    getApiEnergyEmissions: build.query<
      GetApiEnergyEmissionsResponse,
      GetApiEnergyEmissionsArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/emissions`,
        params: {
          Scope: queryArg.scope,
          ReportingPeriodId: queryArg.reportingPeriodId,
          BusinessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    getApiEnergyEmissionsPaginated: build.query<
      GetApiEnergyEmissionsPaginatedResponse,
      GetApiEnergyEmissionsPaginatedArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/emissions/paginated`,
        params: {
          PageNumber: queryArg.pageNumber,
          PageSize: queryArg.pageSize,
          ConfiguredEmissionSourceId: queryArg.configuredEmissionSourceId,
          LocationId: queryArg.locationId,
          StateId: queryArg.stateId,
          HasAlerts: queryArg.hasAlerts,
          HasWaste: queryArg.hasWaste,
          StartDate: queryArg.startDate,
          EndDate: queryArg.endDate,
          scope: queryArg.scope,
        },
      }),
    }),
    getApiCommonGases: build.query<
      GetApiCommonGasesResponse,
      GetApiCommonGasesArg
    >({
      query: () => ({ url: `/api/common/gases` }),
    }),
    getApiEnergySourcesById: build.query<
      GetApiEnergySourcesByIdResponse,
      GetApiEnergySourcesByIdArg
    >({
      query: (queryArg) => ({ url: `/api/energy/sources/${queryArg.id}` }),
    }),
    getApiEnergySourcesSearch: build.query<
      GetApiEnergySourcesSearchResponse,
      GetApiEnergySourcesSearchArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/sources/search`,
        params: {
          Name: queryArg.name,
          Scope: queryArg.scope,
          ConfiguredOnly: queryArg.configuredOnly,
        },
      }),
    }),
    putApiEnergySourcesDisplayName: build.mutation<
      PutApiEnergySourcesDisplayNameResponse,
      PutApiEnergySourcesDisplayNameArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/sources/display-name`,
        method: 'PUT',
        body: queryArg.configuredEmissionSourceRequest,
        params: { emissionSourceId: queryArg.emissionSourceId },
      }),
    }),
    getApiEnergySourcesDashboard: build.query<
      GetApiEnergySourcesDashboardResponse,
      GetApiEnergySourcesDashboardArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/sources/dashboard`,
        params: { scope: queryArg.scope },
      }),
    }),
    getApiEnergySources: build.query<
      GetApiEnergySourcesResponse,
      GetApiEnergySourcesArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/sources`,
        params: { scope: queryArg.scope },
      }),
    }),
    postApiEnergySources: build.mutation<
      PostApiEnergySourcesResponse,
      PostApiEnergySourcesArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/sources`,
        method: 'POST',
        body: queryArg.emissionSourceRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiEnergySourcesByConfiguredEmissionSourceIdEmissionSources: build.query<
      GetApiEnergySourcesByConfiguredEmissionSourceIdEmissionSourcesResponse,
      GetApiEnergySourcesByConfiguredEmissionSourceIdEmissionSourcesArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/sources/${queryArg.configuredEmissionSourceId}/emission-sources`,
      }),
    }),
    getApiEnergySourcesEmissionFactorProvider: build.query<
      GetApiEnergySourcesEmissionFactorProviderResponse,
      GetApiEnergySourcesEmissionFactorProviderArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/sources/emission-factor-provider`,
        params: { searchTerm: queryArg.searchTerm },
      }),
    }),
    postApiEnergySourcesGas: build.mutation<
      PostApiEnergySourcesGasResponse,
      PostApiEnergySourcesGasArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/sources/gas`,
        method: 'POST',
        body: queryArg.emissionSourceGasRequest,
        params: { emissionSourceId: queryArg.emissionSourceId },
      }),
    }),
    getApiEmployeeAuditByEmployeeAuditId: build.query<
      GetApiEmployeeAuditByEmployeeAuditIdResponse,
      GetApiEmployeeAuditByEmployeeAuditIdArg
    >({
      query: (queryArg) => ({
        url: `/api/employee-audit/${queryArg.employeeAuditId}`,
      }),
    }),
    deleteApiEmployeeAuditByEmployeeAuditId: build.mutation<
      DeleteApiEmployeeAuditByEmployeeAuditIdResponse,
      DeleteApiEmployeeAuditByEmployeeAuditIdArg
    >({
      query: (queryArg) => ({
        url: `/api/employee-audit/${queryArg.employeeAuditId}`,
        method: 'DELETE',
      }),
    }),
    getApiEmployeeAudit: build.query<
      GetApiEmployeeAuditResponse,
      GetApiEmployeeAuditArg
    >({
      query: () => ({ url: `/api/employee-audit` }),
    }),
    putApiEmployeeAudit: build.mutation<
      PutApiEmployeeAuditResponse,
      PutApiEmployeeAuditArg
    >({
      query: (queryArg) => ({
        url: `/api/employee-audit`,
        method: 'PUT',
        body: queryArg.employeeAuditRequest,
        params: { id: queryArg.id },
      }),
    }),
    postApiEmployeeAuditByIdSignOff: build.mutation<
      PostApiEmployeeAuditByIdSignOffResponse,
      PostApiEmployeeAuditByIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/employee-audit/${queryArg.id}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    getApiEmployeeAuditByIdAuditTrail: build.query<
      GetApiEmployeeAuditByIdAuditTrailResponse,
      GetApiEmployeeAuditByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/employee-audit/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiEmployeeAuditByIdChangeSummary: build.query<
      GetApiEmployeeAuditByIdChangeSummaryResponse,
      GetApiEmployeeAuditByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/employee-audit/${queryArg.id}/change-summary`,
      }),
    }),
    getApiEmployeeAuditDashboard: build.query<
      GetApiEmployeeAuditDashboardResponse,
      GetApiEmployeeAuditDashboardArg
    >({
      query: (queryArg) => ({
        url: `/api/employee-audit/dashboard`,
        params: {
          ReportingPeriodId: queryArg.reportingPeriodId,
          BusinessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    getApiEmployeeAuditPaginated: build.query<
      GetApiEmployeeAuditPaginatedResponse,
      GetApiEmployeeAuditPaginatedArg
    >({
      query: (queryArg) => ({
        url: `/api/employee-audit/paginated`,
        params: {
          PageNumber: queryArg.pageNumber,
          PageSize: queryArg.pageSize,
          LocationId: queryArg.locationId,
          StateId: queryArg.stateId,
          ReportingPeriodId: queryArg.reportingPeriodId,
        },
      }),
    }),
    getApiEmployeeAuditAgeGroups: build.query<
      GetApiEmployeeAuditAgeGroupsResponse,
      GetApiEmployeeAuditAgeGroupsArg
    >({
      query: () => ({ url: `/api/employee-audit/age-groups` }),
    }),
    getApiEnergyInputTemplates: build.query<
      GetApiEnergyInputTemplatesResponse,
      GetApiEnergyInputTemplatesArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/input-templates`,
        params: { locationId: queryArg.locationId, scope: queryArg.scope },
      }),
    }),
    deleteApiEnergyById: build.mutation<
      DeleteApiEnergyByIdResponse,
      DeleteApiEnergyByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiEnergyById: build.query<
      GetApiEnergyByIdResponse,
      GetApiEnergyByIdArg
    >({
      query: (queryArg) => ({ url: `/api/energy/${queryArg.id}` }),
    }),
    putApiEnergy: build.mutation<PutApiEnergyResponse, PutApiEnergyArg>({
      query: (queryArg) => ({
        url: `/api/energy`,
        method: 'PUT',
        body: queryArg.energyRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiEnergyGetBatch: build.query<
      GetApiEnergyGetBatchResponse,
      GetApiEnergyGetBatchArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/get-batch`,
        params: { ids: queryArg.ids },
      }),
    }),
    putApiEnergyBatch: build.mutation<
      PutApiEnergyBatchResponse,
      PutApiEnergyBatchArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/batch`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiEnergyByIdCalculationLogs: build.query<
      GetApiEnergyByIdCalculationLogsResponse,
      GetApiEnergyByIdCalculationLogsArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/${queryArg.id}/calculation-logs`,
      }),
    }),
    postApiEnergyByIdSignOff: build.mutation<
      PostApiEnergyByIdSignOffResponse,
      PostApiEnergyByIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/${queryArg.id}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    getApiEnergyByIdAuditTrail: build.query<
      GetApiEnergyByIdAuditTrailResponse,
      GetApiEnergyByIdAuditTrailArg
    >({
      query: (queryArg) => ({ url: `/api/energy/${queryArg.id}/audit-trail` }),
    }),
    getApiEnergyByIdChangeSummary: build.query<
      GetApiEnergyByIdChangeSummaryResponse,
      GetApiEnergyByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/${queryArg.id}/change-summary`,
      }),
    }),
    getApiEnergyMetrics: build.query<
      GetApiEnergyMetricsResponse,
      GetApiEnergyMetricsArg
    >({
      query: () => ({ url: `/api/energy/metrics` }),
    }),
    getApiEnergyMetricsByMetricIdUnits: build.query<
      GetApiEnergyMetricsByMetricIdUnitsResponse,
      GetApiEnergyMetricsByMetricIdUnitsArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/metrics/${queryArg.metricId}/units`,
        params: {
          IsSelectable: queryArg.isSelectable,
          Abbreviation: queryArg.abbreviation,
          Name: queryArg.name,
        },
      }),
    }),
    patchApiEnergyMetricsByMetricIdUnits: build.mutation<
      PatchApiEnergyMetricsByMetricIdUnitsResponse,
      PatchApiEnergyMetricsByMetricIdUnitsArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/metrics/${queryArg.metricId}/units`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getApiEntityManagementEntityTemplate: build.query<
      GetApiEntityManagementEntityTemplateResponse,
      GetApiEntityManagementEntityTemplateArg
    >({
      query: () => ({ url: `/api/entity-management/entity-template` }),
    }),
    putApiEntityManagementEntityTemplate: build.mutation<
      PutApiEntityManagementEntityTemplateResponse,
      PutApiEntityManagementEntityTemplateArg
    >({
      query: (queryArg) => ({
        url: `/api/entity-management/entity-template`,
        method: 'PUT',
        body: queryArg.excelTemplateRequest,
      }),
    }),
    getApiEntityManagementEntityTemplateByTemplateId: build.query<
      GetApiEntityManagementEntityTemplateByTemplateIdResponse,
      GetApiEntityManagementEntityTemplateByTemplateIdArg
    >({
      query: (queryArg) => ({
        url: `/api/entity-management/entity-template/${queryArg.templateId}`,
      }),
    }),
    getApiEntityManagementEntityTemplateHistory: build.query<
      GetApiEntityManagementEntityTemplateHistoryResponse,
      GetApiEntityManagementEntityTemplateHistoryArg
    >({
      query: () => ({ url: `/api/entity-management/entity-template/history` }),
    }),
    postApiEntityManagementEntityTemplateByFileName: build.mutation<
      PostApiEntityManagementEntityTemplateByFileNameResponse,
      PostApiEntityManagementEntityTemplateByFileNameArg
    >({
      query: (queryArg) => ({
        url: `/api/entity-management/entity-template/${queryArg.fileName}`,
        method: 'POST',
        body: queryArg.body,
        params: { templateId: queryArg.templateId },
      }),
    }),
    getApiFeedbackCategoryStakeholderSolicitation: build.query<
      GetApiFeedbackCategoryStakeholderSolicitationResponse,
      GetApiFeedbackCategoryStakeholderSolicitationArg
    >({
      query: () => ({ url: `/api/feedback-category/stakeholder-solicitation` }),
    }),
    getApiFeedbackCategoryInternalSolicitation: build.query<
      GetApiFeedbackCategoryInternalSolicitationResponse,
      GetApiFeedbackCategoryInternalSolicitationArg
    >({
      query: () => ({ url: `/api/feedback-category/internal-solicitation` }),
    }),
    putApiCommunitiesCsi: build.mutation<
      PutApiCommunitiesCsiResponse,
      PutApiCommunitiesCsiArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/csi`,
        method: 'PUT',
        body: queryArg.fundingApplicationRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiCommunitiesCsi: build.query<
      GetApiCommunitiesCsiResponse,
      GetApiCommunitiesCsiArg
    >({
      query: () => ({ url: `/api/communities/csi` }),
    }),
    postApiCommunitiesCsiFromDocumentByFileName: build.mutation<
      PostApiCommunitiesCsiFromDocumentByFileNameResponse,
      PostApiCommunitiesCsiFromDocumentByFileNameArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/csi/from-document/${queryArg.fileName}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiCommunitiesCsiByByGrouping: build.query<
      GetApiCommunitiesCsiByByGroupingResponse,
      GetApiCommunitiesCsiByByGroupingArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/csi/by/${queryArg.grouping}`,
      }),
    }),
    getApiCommunitiesCsiById: build.query<
      GetApiCommunitiesCsiByIdResponse,
      GetApiCommunitiesCsiByIdArg
    >({
      query: (queryArg) => ({ url: `/api/communities/csi/${queryArg.id}` }),
    }),
    postApiCommunitiesCsiByFundingApplicationIdSignOff: build.mutation<
      PostApiCommunitiesCsiByFundingApplicationIdSignOffResponse,
      PostApiCommunitiesCsiByFundingApplicationIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/csi/${queryArg.fundingApplicationId}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    deleteApiCommunitiesCsiByFundingApplicationId: build.mutation<
      DeleteApiCommunitiesCsiByFundingApplicationIdResponse,
      DeleteApiCommunitiesCsiByFundingApplicationIdArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/csi/${queryArg.fundingApplicationId}`,
        method: 'DELETE',
      }),
    }),
    getApiCommunitiesCsiByIdAuditTrail: build.query<
      GetApiCommunitiesCsiByIdAuditTrailResponse,
      GetApiCommunitiesCsiByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/csi/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiCommunitiesCsiByIdChangeSummary: build.query<
      GetApiCommunitiesCsiByIdChangeSummaryResponse,
      GetApiCommunitiesCsiByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/csi/${queryArg.id}/change-summary`,
      }),
    }),
    putApiCommunitiesImpactAssessment: build.mutation<
      PutApiCommunitiesImpactAssessmentResponse,
      PutApiCommunitiesImpactAssessmentArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/impact-assessment`,
        method: 'PUT',
        body: queryArg.impactAssessmentRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiCommunitiesImpactAssessment: build.query<
      GetApiCommunitiesImpactAssessmentResponse,
      GetApiCommunitiesImpactAssessmentArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/impact-assessment`,
        params: {
          PageNumber: queryArg.pageNumber,
          PageSize: queryArg.pageSize,
          ImpactAssessmentType: queryArg.impactAssessmentType,
          IsPubliclyDisclosed: queryArg.isPubliclyDisclosed,
          LocationId: queryArg.locationId,
          StateId: queryArg.stateId,
          StartDate: queryArg.startDate,
          EndDate: queryArg.endDate,
        },
      }),
    }),
    getApiCommunitiesImpactAssessmentDashboard: build.query<
      GetApiCommunitiesImpactAssessmentDashboardResponse,
      GetApiCommunitiesImpactAssessmentDashboardArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/impact-assessment/dashboard`,
        params: {
          ReportingPeriodId: queryArg.reportingPeriodId,
          BusinessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    getApiCommunitiesImpactAssessmentById: build.query<
      GetApiCommunitiesImpactAssessmentByIdResponse,
      GetApiCommunitiesImpactAssessmentByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/impact-assessment/${queryArg.id}`,
      }),
    }),
    deleteApiCommunitiesImpactAssessmentById: build.mutation<
      DeleteApiCommunitiesImpactAssessmentByIdResponse,
      DeleteApiCommunitiesImpactAssessmentByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/impact-assessment/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiCommunitiesImpactAssessmentByIdAuditTrail: build.query<
      GetApiCommunitiesImpactAssessmentByIdAuditTrailResponse,
      GetApiCommunitiesImpactAssessmentByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/impact-assessment/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiCommunitiesImpactAssessmentByIdChangeSummary: build.query<
      GetApiCommunitiesImpactAssessmentByIdChangeSummaryResponse,
      GetApiCommunitiesImpactAssessmentByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/impact-assessment/${queryArg.id}/change-summary`,
      }),
    }),
    postApiCommunitiesImpactAssessmentByIdSignOff: build.mutation<
      PostApiCommunitiesImpactAssessmentByIdSignOffResponse,
      PostApiCommunitiesImpactAssessmentByIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/impact-assessment/${queryArg.id}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    putApiHealthAndSafetyIncident: build.mutation<
      PutApiHealthAndSafetyIncidentResponse,
      PutApiHealthAndSafetyIncidentArg
    >({
      query: (queryArg) => ({
        url: `/api/health-and-safety/incident`,
        method: 'PUT',
        body: queryArg.healthAndSafetyIncidentRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiHealthAndSafetyIncident: build.query<
      GetApiHealthAndSafetyIncidentResponse,
      GetApiHealthAndSafetyIncidentArg
    >({
      query: (queryArg) => ({
        url: `/api/health-and-safety/incident`,
        params: {
          PageNumber: queryArg.pageNumber,
          PageSize: queryArg.pageSize,
          SkipCount: queryArg.skipCount,
          LocationId: queryArg.locationId,
          IsPermanent: queryArg.isPermanent,
          StateId: queryArg.stateId,
          StartDate: queryArg.startDate,
          EndDate: queryArg.endDate,
          IsHealthIncident: queryArg.isHealthIncident,
          GRICategory: queryArg.griCategory,
        },
      }),
    }),
    getApiHealthAndSafetyIncidentById: build.query<
      GetApiHealthAndSafetyIncidentByIdResponse,
      GetApiHealthAndSafetyIncidentByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/health-and-safety/incident/${queryArg.id}`,
      }),
    }),
    deleteApiHealthAndSafetyIncidentById: build.mutation<
      DeleteApiHealthAndSafetyIncidentByIdResponse,
      DeleteApiHealthAndSafetyIncidentByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/health-and-safety/incident/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiHealthAndSafetyIncidentCategories: build.query<
      GetApiHealthAndSafetyIncidentCategoriesResponse,
      GetApiHealthAndSafetyIncidentCategoriesArg
    >({
      query: () => ({ url: `/api/health-and-safety/incident/categories` }),
    }),
    getApiHealthAndSafetyIncidentDashboard: build.query<
      GetApiHealthAndSafetyIncidentDashboardResponse,
      GetApiHealthAndSafetyIncidentDashboardArg
    >({
      query: (queryArg) => ({
        url: `/api/health-and-safety/incident/dashboard`,
        params: {
          ReportingPeriodId: queryArg.reportingPeriodId,
          BusinessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    postApiHealthAndSafetyIncidentByIdSignOff: build.mutation<
      PostApiHealthAndSafetyIncidentByIdSignOffResponse,
      PostApiHealthAndSafetyIncidentByIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/health-and-safety/incident/${queryArg.id}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    getApiHealthAndSafetyIncidentByIdAuditTrail: build.query<
      GetApiHealthAndSafetyIncidentByIdAuditTrailResponse,
      GetApiHealthAndSafetyIncidentByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/health-and-safety/incident/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiHealthAndSafetyIncidentByIdChangeSummary: build.query<
      GetApiHealthAndSafetyIncidentByIdChangeSummaryResponse,
      GetApiHealthAndSafetyIncidentByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/health-and-safety/incident/${queryArg.id}/change-summary`,
      }),
    }),
    getApiCommunitiesIndicatorIndicators: build.query<
      GetApiCommunitiesIndicatorIndicatorsResponse,
      GetApiCommunitiesIndicatorIndicatorsArg
    >({
      query: () => ({ url: `/api/communities/indicator/indicators` }),
    }),
    postApiEnergyIndirectFromTemplateByFileName: build.mutation<
      PostApiEnergyIndirectFromTemplateByFileNameResponse,
      PostApiEnergyIndirectFromTemplateByFileNameArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/indirect/from-template/${queryArg.fileName}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiInternalFeedback: build.query<
      GetApiInternalFeedbackResponse,
      GetApiInternalFeedbackArg
    >({
      query: () => ({ url: `/api/internal-feedback` }),
    }),
    postApiInternalFeedback: build.mutation<
      PostApiInternalFeedbackResponse,
      PostApiInternalFeedbackArg
    >({
      query: (queryArg) => ({
        url: `/api/internal-feedback`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiInternalFeedbackById: build.query<
      GetApiInternalFeedbackByIdResponse,
      GetApiInternalFeedbackByIdArg
    >({
      query: (queryArg) => ({ url: `/api/internal-feedback/${queryArg.id}` }),
    }),
    getApiCareerDevelopmentLearnershipByLearnershipGuid: build.query<
      GetApiCareerDevelopmentLearnershipByLearnershipGuidResponse,
      GetApiCareerDevelopmentLearnershipByLearnershipGuidArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/learnership/${queryArg.learnershipGuid}`,
      }),
    }),
    putApiCareerDevelopmentLearnership: build.mutation<
      PutApiCareerDevelopmentLearnershipResponse,
      PutApiCareerDevelopmentLearnershipArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/learnership`,
        method: 'PUT',
        body: queryArg.learnershipRequest,
      }),
    }),
    postApiCommonLocations: build.mutation<
      PostApiCommonLocationsResponse,
      PostApiCommonLocationsArg
    >({
      query: (queryArg) => ({
        url: `/api/common/locations`,
        method: 'POST',
        body: queryArg.locationsRequest,
      }),
    }),
    getApiCommonLocations: build.query<
      GetApiCommonLocationsResponse,
      GetApiCommonLocationsArg
    >({
      query: () => ({ url: `/api/common/locations` }),
    }),
    getApiCommonLocationsLockedPeriods: build.query<
      GetApiCommonLocationsLockedPeriodsResponse,
      GetApiCommonLocationsLockedPeriodsArg
    >({
      query: () => ({ url: `/api/common/locations/locked-periods` }),
    }),
    getApiCommonLocationsByLocationId: build.query<
      GetApiCommonLocationsByLocationIdResponse,
      GetApiCommonLocationsByLocationIdArg
    >({
      query: (queryArg) => ({
        url: `/api/common/locations/${queryArg.locationId}`,
      }),
    }),
    getApiCommonLockedPeriodsMissingEmissionSources: build.query<
      GetApiCommonLockedPeriodsMissingEmissionSourcesResponse,
      GetApiCommonLockedPeriodsMissingEmissionSourcesArg
    >({
      query: (queryArg) => ({
        url: `/api/common/locked-periods/missing-emission-sources`,
        params: {
          Id: queryArg.id,
          LocationId: queryArg.locationId,
          ReportingPeriodId: queryArg.reportingPeriodId,
        },
      }),
    }),
    putApiCommonLockedPeriods: build.mutation<
      PutApiCommonLockedPeriodsResponse,
      PutApiCommonLockedPeriodsArg
    >({
      query: (queryArg) => ({
        url: `/api/common/locked-periods`,
        method: 'PUT',
        body: queryArg.lockPeriodRequest,
      }),
    }),
    deleteApiCommonLockedPeriodsByLockPeriodId: build.mutation<
      DeleteApiCommonLockedPeriodsByLockPeriodIdResponse,
      DeleteApiCommonLockedPeriodsByLockPeriodIdArg
    >({
      query: (queryArg) => ({
        url: `/api/common/locked-periods/${queryArg.lockPeriodId}`,
        method: 'DELETE',
      }),
    }),
    getApiEntityManagementMappedEntities: build.query<
      GetApiEntityManagementMappedEntitiesResponse,
      GetApiEntityManagementMappedEntitiesArg
    >({
      query: () => ({ url: `/api/entity-management/mapped-entities` }),
    }),
    getApiEntityManagementMappedEntitiesByMappedEntityName: build.query<
      GetApiEntityManagementMappedEntitiesByMappedEntityNameResponse,
      GetApiEntityManagementMappedEntitiesByMappedEntityNameArg
    >({
      query: (queryArg) => ({
        url: `/api/entity-management/mapped-entities/${queryArg.mappedEntityName}`,
      }),
    }),
    putApiNetZeroApplication: build.mutation<
      PutApiNetZeroApplicationResponse,
      PutApiNetZeroApplicationArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/application`,
        method: 'PUT',
        body: queryArg.netZeroProjectApplicationRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiNetZeroApplication: build.query<
      GetApiNetZeroApplicationResponse,
      GetApiNetZeroApplicationArg
    >({
      query: () => ({ url: `/api/net-zero/application` }),
    }),
    getApiNetZeroApplicationById: build.query<
      GetApiNetZeroApplicationByIdResponse,
      GetApiNetZeroApplicationByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/application/${queryArg.id}`,
      }),
    }),
    deleteApiNetZeroApplicationById: build.mutation<
      DeleteApiNetZeroApplicationByIdResponse,
      DeleteApiNetZeroApplicationByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/application/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiNetZeroApplicationAll: build.query<
      GetApiNetZeroApplicationAllResponse,
      GetApiNetZeroApplicationAllArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/application/all`,
        params: {
          ReportingPeriodId: queryArg.reportingPeriodId,
          BusinessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    postApiNetZeroApplicationByNetZeroProjectApplicationIdSignOff:
      build.mutation<
        PostApiNetZeroApplicationByNetZeroProjectApplicationIdSignOffResponse,
        PostApiNetZeroApplicationByNetZeroProjectApplicationIdSignOffArg
      >({
        query: (queryArg) => ({
          url: `/api/net-zero/application/${queryArg.netZeroProjectApplicationId}/sign-off`,
          method: 'POST',
          body: queryArg.signOffRequest,
        }),
      }),
    getApiNetZeroApplicationByIdAuditTrail: build.query<
      GetApiNetZeroApplicationByIdAuditTrailResponse,
      GetApiNetZeroApplicationByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/application/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiNetZeroApplicationByIdChangeSummary: build.query<
      GetApiNetZeroApplicationByIdChangeSummaryResponse,
      GetApiNetZeroApplicationByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/application/${queryArg.id}/change-summary`,
      }),
    }),
    getApiNetZeroProjectTypes: build.query<
      GetApiNetZeroProjectTypesResponse,
      GetApiNetZeroProjectTypesArg
    >({
      query: () => ({ url: `/api/net-zero/project-types` }),
    }),
    putApiNetZeroProject: build.mutation<
      PutApiNetZeroProjectResponse,
      PutApiNetZeroProjectArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project`,
        method: 'PUT',
        body: queryArg.netZeroProjectRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiNetZeroProject: build.query<
      GetApiNetZeroProjectResponse,
      GetApiNetZeroProjectArg
    >({
      query: () => ({ url: `/api/net-zero/project` }),
    }),
    getApiNetZeroProjectById: build.query<
      GetApiNetZeroProjectByIdResponse,
      GetApiNetZeroProjectByIdArg
    >({
      query: (queryArg) => ({ url: `/api/net-zero/project/${queryArg.id}` }),
    }),
    deleteApiNetZeroProjectById: build.mutation<
      DeleteApiNetZeroProjectByIdResponse,
      DeleteApiNetZeroProjectByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiNetZeroProjectByNetZeroProjectIdSignOff: build.mutation<
      PostApiNetZeroProjectByNetZeroProjectIdSignOffResponse,
      PostApiNetZeroProjectByNetZeroProjectIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/${queryArg.netZeroProjectId}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    getApiNetZeroProjectByIdAuditTrail: build.query<
      GetApiNetZeroProjectByIdAuditTrailResponse,
      GetApiNetZeroProjectByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiNetZeroProjectByIdChangeSummary: build.query<
      GetApiNetZeroProjectByIdChangeSummaryResponse,
      GetApiNetZeroProjectByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/${queryArg.id}/change-summary`,
      }),
    }),
    getApiNetZeroProjectByProjectIdIndicator: build.query<
      GetApiNetZeroProjectByProjectIdIndicatorResponse,
      GetApiNetZeroProjectByProjectIdIndicatorArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/${queryArg.projectId}/indicator`,
      }),
    }),
    putApiNetZeroProjectByProjectIdIndicator: build.mutation<
      PutApiNetZeroProjectByProjectIdIndicatorResponse,
      PutApiNetZeroProjectByProjectIdIndicatorArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/${queryArg.projectId}/indicator`,
        method: 'PUT',
        body: queryArg.netZeroProjectIndicatorRequest,
      }),
    }),
    getApiNetZeroProjectByProjectIdIndicatorAndIndicatorId: build.query<
      GetApiNetZeroProjectByProjectIdIndicatorAndIndicatorIdResponse,
      GetApiNetZeroProjectByProjectIdIndicatorAndIndicatorIdArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/${queryArg.projectId}/indicator/${queryArg.indicatorId}`,
      }),
    }),
    deleteApiNetZeroProjectByProjectIdIndicatorAndIndicatorId: build.mutation<
      DeleteApiNetZeroProjectByProjectIdIndicatorAndIndicatorIdResponse,
      DeleteApiNetZeroProjectByProjectIdIndicatorAndIndicatorIdArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/${queryArg.projectId}/indicator/${queryArg.indicatorId}`,
        method: 'DELETE',
      }),
    }),
    postApiNetZeroProjectIndicatorByIndicatorIdSignOff: build.mutation<
      PostApiNetZeroProjectIndicatorByIndicatorIdSignOffResponse,
      PostApiNetZeroProjectIndicatorByIndicatorIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/indicator/${queryArg.indicatorId}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    putApiNetZeroProjectType: build.mutation<
      PutApiNetZeroProjectTypeResponse,
      PutApiNetZeroProjectTypeArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/type`,
        method: 'PUT',
        body: queryArg.netZeroProjectTypeRequest,
      }),
    }),
    getApiNetZeroProjectTypeByProjectTypeId: build.query<
      GetApiNetZeroProjectTypeByProjectTypeIdResponse,
      GetApiNetZeroProjectTypeByProjectTypeIdArg
    >({
      query: (queryArg) => ({
        url: `/api/net-zero/project/type/${queryArg.projectTypeId}`,
      }),
    }),
    getApiNetZeroProjectTypeAll: build.query<
      GetApiNetZeroProjectTypeAllResponse,
      GetApiNetZeroProjectTypeAllArg
    >({
      query: () => ({ url: `/api/net-zero/project/type/all` }),
    }),
    getApiNotifications: build.query<
      GetApiNotificationsResponse,
      GetApiNotificationsArg
    >({
      query: () => ({ url: `/api/notifications` }),
    }),
    putApiNotifications: build.mutation<
      PutApiNotificationsResponse,
      PutApiNotificationsArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications`,
        method: 'PUT',
        body: queryArg.userNotificationRequest,
      }),
    }),
    putApiOperation: build.mutation<
      PutApiOperationResponse,
      PutApiOperationArg
    >({
      query: (queryArg) => ({
        url: `/api/operation`,
        method: 'PUT',
        body: queryArg.operationRequest,
      }),
    }),
    getApiOperation: build.query<GetApiOperationResponse, GetApiOperationArg>({
      query: () => ({ url: `/api/operation` }),
    }),
    getApiOperationById: build.query<
      GetApiOperationByIdResponse,
      GetApiOperationByIdArg
    >({
      query: (queryArg) => ({ url: `/api/operation/${queryArg.id}` }),
    }),
    deleteApiOperationById: build.mutation<
      DeleteApiOperationByIdResponse,
      DeleteApiOperationByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/operation/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiOperationOperationTypes: build.query<
      GetApiOperationOperationTypesResponse,
      GetApiOperationOperationTypesArg
    >({
      query: () => ({ url: `/api/operation/operation-types` }),
    }),
    getApiCommunitiesProjectProjects: build.query<
      GetApiCommunitiesProjectProjectsResponse,
      GetApiCommunitiesProjectProjectsArg
    >({
      query: () => ({ url: `/api/communities/project/projects` }),
    }),
    getApiCommunitiesProjectByProjectId: build.query<
      GetApiCommunitiesProjectByProjectIdResponse,
      GetApiCommunitiesProjectByProjectIdArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/${queryArg.projectId}`,
      }),
    }),
    deleteApiCommunitiesProjectByProjectId: build.mutation<
      DeleteApiCommunitiesProjectByProjectIdResponse,
      DeleteApiCommunitiesProjectByProjectIdArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/${queryArg.projectId}`,
        method: 'DELETE',
      }),
    }),
    getApiCommunitiesProjectByProjectIdPayments: build.query<
      GetApiCommunitiesProjectByProjectIdPaymentsResponse,
      GetApiCommunitiesProjectByProjectIdPaymentsArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/${queryArg.projectId}/payments`,
      }),
    }),
    postApiCommunitiesProjectPayments: build.mutation<
      PostApiCommunitiesProjectPaymentsResponse,
      PostApiCommunitiesProjectPaymentsArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/payments`,
        method: 'POST',
        body: queryArg.projectPaymentsBulkRequest,
      }),
    }),
    putApiCommunitiesProjectPayment: build.mutation<
      PutApiCommunitiesProjectPaymentResponse,
      PutApiCommunitiesProjectPaymentArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/payment`,
        method: 'PUT',
        body: queryArg.projectPaymentRequest,
      }),
    }),
    getApiCommunitiesProjectPaymentByProjectPaymentId: build.query<
      GetApiCommunitiesProjectPaymentByProjectPaymentIdResponse,
      GetApiCommunitiesProjectPaymentByProjectPaymentIdArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/payment/${queryArg.projectPaymentId}`,
      }),
    }),
    deleteApiCommunitiesProjectPaymentByProjectPaymentId: build.mutation<
      DeleteApiCommunitiesProjectPaymentByProjectPaymentIdResponse,
      DeleteApiCommunitiesProjectPaymentByProjectPaymentIdArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/payment/${queryArg.projectPaymentId}`,
        method: 'DELETE',
      }),
    }),
    getApiCommunitiesProjectByProjectIdReports: build.query<
      GetApiCommunitiesProjectByProjectIdReportsResponse,
      GetApiCommunitiesProjectByProjectIdReportsArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/${queryArg.projectId}/reports`,
      }),
    }),
    postApiCommunitiesProjectByProjectIdRequestReport: build.mutation<
      PostApiCommunitiesProjectByProjectIdRequestReportResponse,
      PostApiCommunitiesProjectByProjectIdRequestReportArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/${queryArg.projectId}/request-report`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteApiCommunitiesProjectByProjectReportIdReport: build.mutation<
      DeleteApiCommunitiesProjectByProjectReportIdReportResponse,
      DeleteApiCommunitiesProjectByProjectReportIdReportArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/${queryArg.projectReportId}/report`,
        method: 'DELETE',
      }),
    }),
    getApiCommunitiesProjectByProjectIdIndicators: build.query<
      GetApiCommunitiesProjectByProjectIdIndicatorsResponse,
      GetApiCommunitiesProjectByProjectIdIndicatorsArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/${queryArg.projectId}/indicators`,
      }),
    }),
    postApiCommunitiesProjectByProjectIdIndicators: build.mutation<
      PostApiCommunitiesProjectByProjectIdIndicatorsResponse,
      PostApiCommunitiesProjectByProjectIdIndicatorsArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/project/${queryArg.projectId}/indicators`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiPublicCommunitiesProjectReportByProjectReportIdAnswers:
      build.mutation<
        PutApiPublicCommunitiesProjectReportByProjectReportIdAnswersResponse,
        PutApiPublicCommunitiesProjectReportByProjectReportIdAnswersArg
      >({
        query: (queryArg) => ({
          url: `/api/public/communities/project/report/${queryArg.projectReportId}/answers`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    getApiPublicCommunitiesProjectReportByProjectReportIdAnswers: build.query<
      GetApiPublicCommunitiesProjectReportByProjectReportIdAnswersResponse,
      GetApiPublicCommunitiesProjectReportByProjectReportIdAnswersArg
    >({
      query: (queryArg) => ({
        url: `/api/public/communities/project/report/${queryArg.projectReportId}/answers`,
      }),
    }),
    getApiCommonReportingPeriod: build.query<
      GetApiCommonReportingPeriodResponse,
      GetApiCommonReportingPeriodArg
    >({
      query: () => ({ url: `/api/common/reportingPeriod` }),
    }),
    putApiCommonReportingPeriod: build.mutation<
      PutApiCommonReportingPeriodResponse,
      PutApiCommonReportingPeriodArg
    >({
      query: (queryArg) => ({
        url: `/api/common/reportingPeriod`,
        method: 'PUT',
        body: queryArg.reportingPeriodRequest,
      }),
    }),
    deleteApiCommonReportingPeriodByReportingPeriodId: build.mutation<
      DeleteApiCommonReportingPeriodByReportingPeriodIdResponse,
      DeleteApiCommonReportingPeriodByReportingPeriodIdArg
    >({
      query: (queryArg) => ({
        url: `/api/common/reportingPeriod/${queryArg.reportingPeriodId}`,
        method: 'DELETE',
      }),
    }),
    getApiBusinessPartnerRoles: build.query<
      GetApiBusinessPartnerRolesResponse,
      GetApiBusinessPartnerRolesArg
    >({
      query: () => ({ url: `/api/business-partner/roles` }),
    }),
    putApiBusinessPartnerRoles: build.mutation<
      PutApiBusinessPartnerRolesResponse,
      PutApiBusinessPartnerRolesArg
    >({
      query: (queryArg) => ({
        url: `/api/business-partner/roles`,
        method: 'PUT',
        body: queryArg.roleRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiBusinessPartnerRolesByRoleGuid: build.query<
      GetApiBusinessPartnerRolesByRoleGuidResponse,
      GetApiBusinessPartnerRolesByRoleGuidArg
    >({
      query: (queryArg) => ({
        url: `/api/business-partner/roles/${queryArg.roleGuid}`,
      }),
    }),
    putApiEnergyScope3: build.mutation<
      PutApiEnergyScope3Response,
      PutApiEnergyScope3Arg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3`,
        method: 'PUT',
        body: queryArg.scope3EnergyRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiEnergyScope3: build.query<
      GetApiEnergyScope3Response,
      GetApiEnergyScope3Arg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3`,
        params: { scope3EnergyId: queryArg.scope3EnergyId },
      }),
    }),
    putApiEnergyScope3Batch: build.mutation<
      PutApiEnergyScope3BatchResponse,
      PutApiEnergyScope3BatchArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3/batch`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiEnergyScope3InputTemplates: build.query<
      GetApiEnergyScope3InputTemplatesResponse,
      GetApiEnergyScope3InputTemplatesArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3/input-templates`,
        params: {
          locationId: queryArg.locationId,
          scope3CategoryId: queryArg.scope3CategoryId,
        },
      }),
    }),
    postApiEnergyScope3ByScope3EnergyIdSignOff: build.mutation<
      PostApiEnergyScope3ByScope3EnergyIdSignOffResponse,
      PostApiEnergyScope3ByScope3EnergyIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3/${queryArg.scope3EnergyId}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    deleteApiEnergyScope3ByScope3EnergyId: build.mutation<
      DeleteApiEnergyScope3ByScope3EnergyIdResponse,
      DeleteApiEnergyScope3ByScope3EnergyIdArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3/${queryArg.scope3EnergyId}`,
        method: 'DELETE',
      }),
    }),
    getApiEnergyScope3Emissions: build.query<
      GetApiEnergyScope3EmissionsResponse,
      GetApiEnergyScope3EmissionsArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3/emissions`,
        params: {
          Scope: queryArg.scope,
          ReportingPeriodId: queryArg.reportingPeriodId,
          BusinessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    getApiEnergyScope3Paginated: build.query<
      GetApiEnergyScope3PaginatedResponse,
      GetApiEnergyScope3PaginatedArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3/paginated`,
        params: {
          PageNumber: queryArg.pageNumber,
          PageSize: queryArg.pageSize,
          ConfiguredEmissionSourceId: queryArg.configuredEmissionSourceId,
          LocationId: queryArg.locationId,
          StateId: queryArg.stateId,
          HasAlerts: queryArg.hasAlerts,
          HasWaste: queryArg.hasWaste,
          StartDate: queryArg.startDate,
          EndDate: queryArg.endDate,
        },
      }),
    }),
    getApiEnergyScope3ByIdAuditTrail: build.query<
      GetApiEnergyScope3ByIdAuditTrailResponse,
      GetApiEnergyScope3ByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiEnergyScope3ByIdChangeSummary: build.query<
      GetApiEnergyScope3ByIdChangeSummaryResponse,
      GetApiEnergyScope3ByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3/${queryArg.id}/change-summary`,
      }),
    }),
    getApiEnergyScope3Categories: build.query<
      GetApiEnergyScope3CategoriesResponse,
      GetApiEnergyScope3CategoriesArg
    >({
      query: () => ({ url: `/api/energy/scope3/categories` }),
    }),
    postApiEnergyScope3OnceOff: build.mutation<
      PostApiEnergyScope3OnceOffResponse,
      PostApiEnergyScope3OnceOffArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3/once-off`,
        method: 'POST',
        body: queryArg.scope3EnergyRequest,
        params: { calculateEffective: queryArg.calculateEffective },
      }),
    }),
    getApiEnergyScope3GetBatch: build.query<
      GetApiEnergyScope3GetBatchResponse,
      GetApiEnergyScope3GetBatchArg
    >({
      query: (queryArg) => ({
        url: `/api/energy/scope3/get-batch`,
        params: { ids: queryArg.ids },
      }),
    }),
    getApiCommonSectors: build.query<
      GetApiCommonSectorsResponse,
      GetApiCommonSectorsArg
    >({
      query: () => ({ url: `/api/common/sectors` }),
    }),
    getApiCommonSettingsReportingStructure: build.query<
      GetApiCommonSettingsReportingStructureResponse,
      GetApiCommonSettingsReportingStructureArg
    >({
      query: () => ({ url: `/api/common/settings/reporting-structure` }),
    }),
    getApiCommonSettingsDashboardPalette: build.query<
      GetApiCommonSettingsDashboardPaletteResponse,
      GetApiCommonSettingsDashboardPaletteArg
    >({
      query: () => ({ url: `/api/common/settings/dashboard-palette` }),
    }),
    getApiCommonSettingsFeatureFlags: build.query<
      GetApiCommonSettingsFeatureFlagsResponse,
      GetApiCommonSettingsFeatureFlagsArg
    >({
      query: () => ({ url: `/api/common/settings/feature-flags` }),
    }),
    getApiSignoff: build.query<GetApiSignoffResponse, GetApiSignoffArg>({
      query: () => ({ url: `/api/signoff` }),
    }),
    getApiSignoffByEntityNameEntityStates: build.query<
      GetApiSignoffByEntityNameEntityStatesResponse,
      GetApiSignoffByEntityNameEntityStatesArg
    >({
      query: (queryArg) => ({
        url: `/api/signoff/${queryArg.entityName}/entity-states`,
      }),
    }),
    putApiSignoffRequiredDocuments: build.mutation<
      PutApiSignoffRequiredDocumentsResponse,
      PutApiSignoffRequiredDocumentsArg
    >({
      query: (queryArg) => ({
        url: `/api/signoff/required-documents`,
        method: 'PUT',
        body: queryArg.requiredDocumentSignOffRequest,
      }),
    }),
    deleteApiSignoffById: build.mutation<
      DeleteApiSignoffByIdResponse,
      DeleteApiSignoffByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/signoff/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiSignoffByEntityNameAllowedTransitions: build.query<
      GetApiSignoffByEntityNameAllowedTransitionsResponse,
      GetApiSignoffByEntityNameAllowedTransitionsArg
    >({
      query: (queryArg) => ({
        url: `/api/signoff/${queryArg.entityName}/allowed-transitions`,
      }),
    }),
    getApiCommunitiesSocialIncidentCategories: build.query<
      GetApiCommunitiesSocialIncidentCategoriesResponse,
      GetApiCommunitiesSocialIncidentCategoriesArg
    >({
      query: () => ({ url: `/api/communities/social-incident/categories` }),
    }),
    getApiCommunitiesSocialIncident: build.query<
      GetApiCommunitiesSocialIncidentResponse,
      GetApiCommunitiesSocialIncidentArg
    >({
      query: () => ({ url: `/api/communities/social-incident` }),
    }),
    putApiCommunitiesSocialIncident: build.mutation<
      PutApiCommunitiesSocialIncidentResponse,
      PutApiCommunitiesSocialIncidentArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/social-incident`,
        method: 'PUT',
        body: queryArg.socialIncidentRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiCommunitiesSocialIncidentBySocialIncidentId: build.query<
      GetApiCommunitiesSocialIncidentBySocialIncidentIdResponse,
      GetApiCommunitiesSocialIncidentBySocialIncidentIdArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/social-incident/${queryArg.socialIncidentId}`,
      }),
    }),
    deleteApiCommunitiesSocialIncidentBySocialIncidentId: build.mutation<
      DeleteApiCommunitiesSocialIncidentBySocialIncidentIdResponse,
      DeleteApiCommunitiesSocialIncidentBySocialIncidentIdArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/social-incident/${queryArg.socialIncidentId}`,
        method: 'DELETE',
      }),
    }),
    postApiCommunitiesSocialIncidentBySocialIncidentIdSignOff: build.mutation<
      PostApiCommunitiesSocialIncidentBySocialIncidentIdSignOffResponse,
      PostApiCommunitiesSocialIncidentBySocialIncidentIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/social-incident/${queryArg.socialIncidentId}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    getApiCommunitiesSocialIncidentByIdAuditTrail: build.query<
      GetApiCommunitiesSocialIncidentByIdAuditTrailResponse,
      GetApiCommunitiesSocialIncidentByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/communities/social-incident/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiStakeholderFeedback: build.query<
      GetApiStakeholderFeedbackResponse,
      GetApiStakeholderFeedbackArg
    >({
      query: () => ({ url: `/api/stakeholder-feedback` }),
    }),
    postApiStakeholderFeedback: build.mutation<
      PostApiStakeholderFeedbackResponse,
      PostApiStakeholderFeedbackArg
    >({
      query: (queryArg) => ({
        url: `/api/stakeholder-feedback`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiStakeholderFeedbackById: build.query<
      GetApiStakeholderFeedbackByIdResponse,
      GetApiStakeholderFeedbackByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/stakeholder-feedback/${queryArg.id}`,
      }),
    }),
    getApiStandards: build.query<GetApiStandardsResponse, GetApiStandardsArg>({
      query: () => ({ url: `/api/standards` }),
    }),
    getApiStandardsVersions: build.query<
      GetApiStandardsVersionsResponse,
      GetApiStandardsVersionsArg
    >({
      query: () => ({ url: `/api/standards/versions` }),
    }),
    getApiValueChainSupplierReviewBySupplierReviewGuid: build.query<
      GetApiValueChainSupplierReviewBySupplierReviewGuidResponse,
      GetApiValueChainSupplierReviewBySupplierReviewGuidArg
    >({
      query: (queryArg) => ({
        url: `/api/value-chain/supplier-review/${queryArg.supplierReviewGuid}`,
      }),
    }),
    deleteApiValueChainSupplierReviewBySupplierReviewGuid: build.mutation<
      DeleteApiValueChainSupplierReviewBySupplierReviewGuidResponse,
      DeleteApiValueChainSupplierReviewBySupplierReviewGuidArg
    >({
      query: (queryArg) => ({
        url: `/api/value-chain/supplier-review/${queryArg.supplierReviewGuid}`,
        method: 'DELETE',
      }),
    }),
    getApiValueChainSupplierReview: build.query<
      GetApiValueChainSupplierReviewResponse,
      GetApiValueChainSupplierReviewArg
    >({
      query: () => ({ url: `/api/value-chain/supplier-review` }),
    }),
    putApiValueChainSupplierReview: build.mutation<
      PutApiValueChainSupplierReviewResponse,
      PutApiValueChainSupplierReviewArg
    >({
      query: (queryArg) => ({
        url: `/api/value-chain/supplier-review`,
        method: 'PUT',
        body: queryArg.supplierReviewRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiValueChainSupplierReviewBySupplierReviewGuidAuditTrail: build.query<
      GetApiValueChainSupplierReviewBySupplierReviewGuidAuditTrailResponse,
      GetApiValueChainSupplierReviewBySupplierReviewGuidAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/value-chain/supplier-review/${queryArg.supplierReviewGuid}/audit-trail`,
        params: { id: queryArg.id },
      }),
    }),
    postApiValueChainSupplierReviewBySupplierReviewGuidSignOff: build.mutation<
      PostApiValueChainSupplierReviewBySupplierReviewGuidSignOffResponse,
      PostApiValueChainSupplierReviewBySupplierReviewGuidSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/value-chain/supplier-review/${queryArg.supplierReviewGuid}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
        params: { supplierReviewId: queryArg.supplierReviewId },
      }),
    }),
    putApiTarget: build.mutation<PutApiTargetResponse, PutApiTargetArg>({
      query: (queryArg) => ({
        url: `/api/target`,
        method: 'PUT',
        body: queryArg.targetRequest,
      }),
    }),
    getApiTarget: build.query<GetApiTargetResponse, GetApiTargetArg>({
      query: (queryArg) => ({
        url: `/api/target`,
        params: {
          Module: queryArg['module'],
          ReportingPeriodId: queryArg.reportingPeriodId,
        },
      }),
    }),
    putApiTargetEnergy: build.mutation<
      PutApiTargetEnergyResponse,
      PutApiTargetEnergyArg
    >({
      query: (queryArg) => ({
        url: `/api/target/energy`,
        method: 'PUT',
        body: queryArg.energyTargetRequest,
      }),
    }),
    putApiTargetEnergyIntensity: build.mutation<
      PutApiTargetEnergyIntensityResponse,
      PutApiTargetEnergyIntensityArg
    >({
      query: (queryArg) => ({
        url: `/api/target/energy-intensity`,
        method: 'PUT',
        body: queryArg.energyIntensityTargetRequest,
      }),
    }),
    putApiTargetNetzero: build.mutation<
      PutApiTargetNetzeroResponse,
      PutApiTargetNetzeroArg
    >({
      query: (queryArg) => ({
        url: `/api/target/netzero`,
        method: 'PUT',
        body: queryArg.netZeroProjectTargetRequest,
      }),
    }),
    getApiTargetIntensityTargets: build.query<
      GetApiTargetIntensityTargetsResponse,
      GetApiTargetIntensityTargetsArg
    >({
      query: () => ({ url: `/api/target/intensity-targets` }),
    }),
    getApiTargetByTargetId: build.query<
      GetApiTargetByTargetIdResponse,
      GetApiTargetByTargetIdArg
    >({
      query: (queryArg) => ({ url: `/api/target/${queryArg.targetId}` }),
    }),
    deleteApiTargetByTargetId: build.mutation<
      DeleteApiTargetByTargetIdResponse,
      DeleteApiTargetByTargetIdArg
    >({
      query: (queryArg) => ({
        url: `/api/target/${queryArg.targetId}`,
        method: 'DELETE',
      }),
    }),
    getApiTargetAll: build.query<GetApiTargetAllResponse, GetApiTargetAllArg>({
      query: (queryArg) => ({
        url: `/api/target/all`,
        params: { module: queryArg['module'] },
      }),
    }),
    getApiTargetTypes: build.query<
      GetApiTargetTypesResponse,
      GetApiTargetTypesArg
    >({
      query: (queryArg) => ({
        url: `/api/target/types`,
        params: { module: queryArg['module'] },
      }),
    }),
    getApiCareerDevelopmentTrainingByTrainingGuid: build.query<
      GetApiCareerDevelopmentTrainingByTrainingGuidResponse,
      GetApiCareerDevelopmentTrainingByTrainingGuidArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/training/${queryArg.trainingGuid}`,
      }),
    }),
    putApiCareerDevelopmentTraining: build.mutation<
      PutApiCareerDevelopmentTrainingResponse,
      PutApiCareerDevelopmentTrainingArg
    >({
      query: (queryArg) => ({
        url: `/api/career-development/training`,
        method: 'PUT',
        body: queryArg.trainingRequest,
      }),
    }),
    getApiCommonUnitsByUnitType: build.query<
      GetApiCommonUnitsByUnitTypeResponse,
      GetApiCommonUnitsByUnitTypeArg
    >({
      query: (queryArg) => ({ url: `/api/common/units/${queryArg.unitType}` }),
    }),
    patchApiCommonUnitsByUnitType: build.mutation<
      PatchApiCommonUnitsByUnitTypeResponse,
      PatchApiCommonUnitsByUnitTypeArg
    >({
      query: (queryArg) => ({
        url: `/api/common/units/${queryArg.unitType}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getApiCommonUnitsTypes: build.query<
      GetApiCommonUnitsTypesResponse,
      GetApiCommonUnitsTypesArg
    >({
      query: () => ({ url: `/api/common/units/types` }),
    }),
    putApiWasteDisposal: build.mutation<
      PutApiWasteDisposalResponse,
      PutApiWasteDisposalArg
    >({
      query: (queryArg) => ({
        url: `/api/waste-disposal`,
        method: 'PUT',
        body: queryArg.wasteDisposalRequest,
        params: { id: queryArg.id },
      }),
    }),
    getApiWasteDisposal: build.query<
      GetApiWasteDisposalResponse,
      GetApiWasteDisposalArg
    >({
      query: (queryArg) => ({
        url: `/api/waste-disposal`,
        params: { wasteDisposalId: queryArg.wasteDisposalId },
      }),
    }),
    putApiWasteDisposalBatch: build.mutation<
      PutApiWasteDisposalBatchResponse,
      PutApiWasteDisposalBatchArg
    >({
      query: (queryArg) => ({
        url: `/api/waste-disposal/batch`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiWasteDisposalInputTemplates: build.query<
      GetApiWasteDisposalInputTemplatesResponse,
      GetApiWasteDisposalInputTemplatesArg
    >({
      query: (queryArg) => ({
        url: `/api/waste-disposal/input-templates`,
        params: { locationId: queryArg.locationId },
      }),
    }),
    deleteApiWasteDisposalByWasteDisposalId: build.mutation<
      DeleteApiWasteDisposalByWasteDisposalIdResponse,
      DeleteApiWasteDisposalByWasteDisposalIdArg
    >({
      query: (queryArg) => ({
        url: `/api/waste-disposal/${queryArg.wasteDisposalId}`,
        method: 'DELETE',
      }),
    }),
    postApiWasteDisposalByWasteDisposalIdSignOff: build.mutation<
      PostApiWasteDisposalByWasteDisposalIdSignOffResponse,
      PostApiWasteDisposalByWasteDisposalIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/waste-disposal/${queryArg.wasteDisposalId}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    getApiWasteDisposalDashboard: build.query<
      GetApiWasteDisposalDashboardResponse,
      GetApiWasteDisposalDashboardArg
    >({
      query: (queryArg) => ({
        url: `/api/waste-disposal/dashboard`,
        params: {
          ReportingPeriodId: queryArg.reportingPeriodId,
          BusinessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    getApiWasteDisposalTable: build.query<
      GetApiWasteDisposalTableResponse,
      GetApiWasteDisposalTableArg
    >({
      query: () => ({ url: `/api/waste-disposal/table` }),
    }),
    getApiWasteDisposalByIdAuditTrail: build.query<
      GetApiWasteDisposalByIdAuditTrailResponse,
      GetApiWasteDisposalByIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/waste-disposal/${queryArg.id}/audit-trail`,
      }),
    }),
    getApiWasteDisposalByIdChangeSummary: build.query<
      GetApiWasteDisposalByIdChangeSummaryResponse,
      GetApiWasteDisposalByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/waste-disposal/${queryArg.id}/change-summary`,
      }),
    }),
    getApiWastemovementByWasteMovementGuid: build.query<
      GetApiWastemovementByWasteMovementGuidResponse,
      GetApiWastemovementByWasteMovementGuidArg
    >({
      query: (queryArg) => ({
        url: `/api/wastemovement/${queryArg.wasteMovementGuid}`,
        params: { wasteMovementDetailsGuid: queryArg.wasteMovementDetailsGuid },
      }),
    }),
    getFacilitymovementsByFacilityMovementGuid: build.query<
      GetFacilitymovementsByFacilityMovementGuidResponse,
      GetFacilitymovementsByFacilityMovementGuidArg
    >({
      query: (queryArg) => ({
        url: `/facilitymovements/${queryArg.facilityMovementGuid}`,
      }),
    }),
    putApiWastemovement: build.mutation<
      PutApiWastemovementResponse,
      PutApiWastemovementArg
    >({
      query: (queryArg) => ({
        url: `/api/wastemovement`,
        method: 'PUT',
        body: queryArg.wasteMovementRequest,
      }),
    }),
    getApiWasteSource: build.query<
      GetApiWasteSourceResponse,
      GetApiWasteSourceArg
    >({
      query: () => ({ url: `/api/waste-source` }),
    }),
    getApiWasteSourceDisplayName: build.query<
      GetApiWasteSourceDisplayNameResponse,
      GetApiWasteSourceDisplayNameArg
    >({
      query: () => ({ url: `/api/waste-source/display-name` }),
    }),
    getApiWaterMeter: build.query<
      GetApiWaterMeterResponse,
      GetApiWaterMeterArg
    >({
      query: () => ({ url: `/api/water/meter` }),
    }),
    putApiWaterMeter: build.mutation<
      PutApiWaterMeterResponse,
      PutApiWaterMeterArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter`,
        method: 'PUT',
        body: queryArg.waterMeterRequest,
      }),
    }),
    getApiWaterMeterByWaterMeterIdReadings: build.query<
      GetApiWaterMeterByWaterMeterIdReadingsResponse,
      GetApiWaterMeterByWaterMeterIdReadingsArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter/${queryArg.waterMeterId}/readings`,
        params: {
          PageNumber: queryArg.pageNumber,
          PageSize: queryArg.pageSize,
          SkipCount: queryArg.skipCount,
        },
      }),
    }),
    getApiWaterMeterByWaterMeterIdCalibrations: build.query<
      GetApiWaterMeterByWaterMeterIdCalibrationsResponse,
      GetApiWaterMeterByWaterMeterIdCalibrationsArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter/${queryArg.waterMeterId}/calibrations`,
      }),
    }),
    putApiWaterMeterCalibration: build.mutation<
      PutApiWaterMeterCalibrationResponse,
      PutApiWaterMeterCalibrationArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter/calibration`,
        method: 'PUT',
        body: queryArg.waterMeterCalibrationRequest,
      }),
    }),
    getApiWaterMeterReading: build.query<
      GetApiWaterMeterReadingResponse,
      GetApiWaterMeterReadingArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter-reading`,
        params: {
          ReportingPeriodId: queryArg.reportingPeriodId,
          BusinessUnitIds: queryArg.businessUnitIds,
        },
      }),
    }),
    putApiWaterMeterReading: build.mutation<
      PutApiWaterMeterReadingResponse,
      PutApiWaterMeterReadingArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter-reading`,
        method: 'PUT',
        body: queryArg.waterMeterReadingRequest,
        params: { id: queryArg.id },
      }),
    }),
    deleteApiWaterMeterReading: build.mutation<
      DeleteApiWaterMeterReadingResponse,
      DeleteApiWaterMeterReadingArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter-reading`,
        method: 'DELETE',
        params: { waterMeterReadingId: queryArg.waterMeterReadingId },
      }),
    }),
    postApiWaterMeterReadingFromTemplateByFileName: build.mutation<
      PostApiWaterMeterReadingFromTemplateByFileNameResponse,
      PostApiWaterMeterReadingFromTemplateByFileNameArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter-reading/from-template/${queryArg.fileName}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiWaterMeterReadingByWaterMeterReadingIdSignOff: build.mutation<
      PostApiWaterMeterReadingByWaterMeterReadingIdSignOffResponse,
      PostApiWaterMeterReadingByWaterMeterReadingIdSignOffArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter-reading/${queryArg.waterMeterReadingId}/sign-off`,
        method: 'POST',
        body: queryArg.signOffRequest,
      }),
    }),
    getApiWaterMeterReadingByWaterMeterReadingIdAuditTrail: build.query<
      GetApiWaterMeterReadingByWaterMeterReadingIdAuditTrailResponse,
      GetApiWaterMeterReadingByWaterMeterReadingIdAuditTrailArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter-reading/${queryArg.waterMeterReadingId}/audit-trail`,
      }),
    }),
    getApiWaterMeterReadingByIdChangeSummary: build.query<
      GetApiWaterMeterReadingByIdChangeSummaryResponse,
      GetApiWaterMeterReadingByIdChangeSummaryArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter-reading/${queryArg.id}/change-summary`,
      }),
    }),
    getApiWaterMeterReadingByWaterMeterReadingId: build.query<
      GetApiWaterMeterReadingByWaterMeterReadingIdResponse,
      GetApiWaterMeterReadingByWaterMeterReadingIdArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter-reading/${queryArg.waterMeterReadingId}`,
      }),
    }),
    putApiWaterMeterReadingUsage: build.mutation<
      PutApiWaterMeterReadingUsageResponse,
      PutApiWaterMeterReadingUsageArg
    >({
      query: (queryArg) => ({
        url: `/api/water/meter-reading/usage`,
        method: 'PUT',
        body: queryArg.waterUsageRequest,
      }),
    }),
    getApiWaterSourceQualities: build.query<
      GetApiWaterSourceQualitiesResponse,
      GetApiWaterSourceQualitiesArg
    >({
      query: () => ({ url: `/api/water/source/qualities` }),
    }),
    getApiWaterSourceTypes: build.query<
      GetApiWaterSourceTypesResponse,
      GetApiWaterSourceTypesArg
    >({
      query: () => ({ url: `/api/water/source/types` }),
    }),
    getApiWaterSource: build.query<
      GetApiWaterSourceResponse,
      GetApiWaterSourceArg
    >({
      query: () => ({ url: `/api/water/source` }),
    }),
    putApiWaterSource: build.mutation<
      PutApiWaterSourceResponse,
      PutApiWaterSourceArg
    >({
      query: (queryArg) => ({
        url: `/api/water/source`,
        method: 'PUT',
        body: queryArg.waterSourceRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetApiAlertsByAlertIdResponse =
  /** status 200 Success */ AlertResponse;
export type GetApiAlertsByAlertIdArg = {
  alertId: string;
};
export type GetApiAlertsResponse = /** status 200 Success */ AlertResponse[];
export type GetApiAlertsArg = void;
export type PutApiAlertsResponse = /** status 200 Success */ string;
export type PutApiAlertsArg = {
  alertRequest: AlertRequest;
};
export type PutApiBiodiversityResponse = /** status 200 Success */ string;
export type PutApiBiodiversityArg = {
  id?: string;
  biodiversityImpactRequest: BiodiversityImpactRequest;
};
export type GetApiBiodiversityPaginatedResponse =
  /** status 200 Success */ BiodiversityImpactResponsePaginationResponse;
export type GetApiBiodiversityPaginatedArg = {
  pageNumber?: number;
  pageSize?: number;
  skipCount?: number;
  operationId?: string;
  operationType?: OperationTypeEnum;
  impactDriver?: ImpactDriverEnum;
  isPositive?: boolean;
};
export type GetApiBiodiversityByIdResponse =
  /** status 200 Success */ BiodiversityImpactResponse;
export type GetApiBiodiversityByIdArg = {
  id: string;
};
export type DeleteApiBiodiversityByIdResponse = unknown;
export type DeleteApiBiodiversityByIdArg = {
  id: string;
};
export type PostApiBiodiversityByIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiBiodiversityByIdSignOffArg = {
  id: string;
  signOffRequest: SignOffRequest;
};
export type GetApiBiodiversityByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiBiodiversityByIdAuditTrailArg = {
  id: string;
};
export type GetApiBiodiversityByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiBiodiversityByIdChangeSummaryArg = {
  id: string;
};
export type GetApiBiodiversityImpactRatingResponse = /** status 200 Success */ {
  [key: string]: ImpactRatingResponse;
};
export type GetApiBiodiversityImpactRatingArg = void;
export type GetApiBiodiversityImpactDriverResponse = /** status 200 Success */ {
  Energy?: string;
  LandUse?: string;
  Materials?: string;
  WaterUse?: string;
  Disturbances?: string;
  Emissions?: string;
  Waste?: string;
  Soil?: string;
  Water?: string;
  Unknown?: string;
};
export type GetApiBiodiversityImpactDriverArg = void;
export type GetApiBiodiversityDashboardResponse =
  /** status 200 Success */ BiodiversityDashboardResponse[];
export type GetApiBiodiversityDashboardArg = {
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type GetApiCareerDevelopmentBursaryByBursaryGuidResponse =
  /** status 200 Success */ BursaryResponse;
export type GetApiCareerDevelopmentBursaryByBursaryGuidArg = {
  bursaryGuid: string;
};
export type PutApiCareerDevelopmentBursaryResponse =
  /** status 200 Success */ string;
export type PutApiCareerDevelopmentBursaryArg = {
  bursaryRequest: BursaryRequest;
};
export type GetApiBusinessPartnerGenderResponse = /** status 200 Success */ {
  Unknown?: string;
  Male?: string;
  Female?: string;
  Other?: string;
};
export type GetApiBusinessPartnerGenderArg = void;
export type GetApiBusinessPartnerEthnicityResponse = /** status 200 Success */ {
  Unknown?: string;
  Black?: string;
  Coloured?: string;
  Indian?: string;
  White?: string;
  Other?: string;
};
export type GetApiBusinessPartnerEthnicityArg = void;
export type GetApiBusinessPartnerByBpGuidResponse =
  /** status 200 Success */ BusinessPartnerResponse;
export type GetApiBusinessPartnerByBpGuidArg = {
  bpGuid: string;
};
export type GetApiBusinessPartnerByIdByIdentificationValueResponse =
  /** status 200 Success */ BusinessPartnerResponse;
export type GetApiBusinessPartnerByIdByIdentificationValueArg = {
  identificationValue: string;
};
export type GetApiBusinessPartnerSearchBySearchTermResponse =
  /** status 200 Success */ BusinessPartnerResponse[];
export type GetApiBusinessPartnerSearchBySearchTermArg = {
  searchTerm: string;
};
export type PutApiBusinessPartnerResponse = /** status 200 Success */ string;
export type PutApiBusinessPartnerArg = {
  businessPartnerRequest: BusinessPartnerRequest;
};
export type GetApiCommonBusinessUnitsResponse = /** status 200 Success */ {
  [key: string]: string;
};
export type GetApiCommonBusinessUnitsArg = void;
export type GetApiEnergyCarbonTaxResponse =
  /** status 200 Success */ CarbonTaxInputResponse[];
export type GetApiEnergyCarbonTaxArg = {
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type PutApiEnergyCarbonTaxResponse =
  /** status 200 Success */ CarbonTaxSaveInputResponse;
export type PutApiEnergyCarbonTaxArg = {
  carbonTaxInputRequest: CarbonTaxInputRequest;
};
export type PostApiEnergyCarbonTaxCalculatedValuesResponse =
  /** status 200 Success */ CarbonTaxInputResponse;
export type PostApiEnergyCarbonTaxCalculatedValuesArg = {
  carbonTaxInputRequest: CarbonTaxInputRequest;
};
export type GetApiEnergyCarbonTaxRateResponse =
  /** status 200 Success */ number;
export type GetApiEnergyCarbonTaxRateArg = {
  reportingPeriodId?: string;
};
export type GetApiEnergyCarbonTaxSchedulesResponse =
  /** status 200 Success */ CarbonTaxScheduleResponse[];
export type GetApiEnergyCarbonTaxSchedulesArg = {
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type PostApiEnergyCarbonTaxByIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiEnergyCarbonTaxByIdSignOffArg = {
  id: string;
  signOffRequest: SignOffRequest;
};
export type DeleteApiEnergyCarbonTaxByCarbonTaxInputIdResponse = unknown;
export type DeleteApiEnergyCarbonTaxByCarbonTaxInputIdArg = {
  carbonTaxInputId: string;
};
export type GetApiCareerDevelopmentResponse =
  /** status 200 Success */ TrainingResponse[];
export type GetApiCareerDevelopmentArg = void;
export type PostApiCareerDevelopmentByCareerDevelopmentIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiCareerDevelopmentByCareerDevelopmentIdSignOffArg = {
  careerDevelopmentId: string;
  signOffRequest: SignOffRequest;
};
export type DeleteApiCareerDevelopmentByCareerDevelopmentIdResponse = unknown;
export type DeleteApiCareerDevelopmentByCareerDevelopmentIdArg = {
  careerDevelopmentId: string;
};
export type GetApiCareerDevelopmentByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiCareerDevelopmentByIdAuditTrailArg = {
  id: string;
};
export type GetApiCareerDevelopmentCareerReviewByCareerReviewGuidResponse =
  /** status 200 Success */ CareerReviewResponse;
export type GetApiCareerDevelopmentCareerReviewByCareerReviewGuidArg = {
  careerReviewGuid: string;
};
export type PutApiCareerDevelopmentCareerReviewResponse =
  /** status 200 Success */ string;
export type PutApiCareerDevelopmentCareerReviewArg = {
  careerReviewRequest: CareerReviewRequest;
};
export type GetApiCompanyByCompanyGuidResponse =
  /** status 200 Success */ CompanyResponse;
export type GetApiCompanyByCompanyGuidArg = {
  companyGuid: string;
};
export type DeleteApiCompanyByCompanyGuidResponse = unknown;
export type DeleteApiCompanyByCompanyGuidArg = {
  companyGuid: string;
};
export type GetApiCompanySearchBySearchTermResponse =
  /** status 200 Success */ CompanyResponse[];
export type GetApiCompanySearchBySearchTermArg = {
  searchTerm: string;
};
export type GetApiCompanyResponse = /** status 200 Success */ CompanyResponse[];
export type GetApiCompanyArg = void;
export type PutApiCompanyResponse = /** status 200 Success */ string;
export type PutApiCompanyArg = {
  companyRequest: CompanyRequest;
};
export type GetApiCompanyTypesResponse = /** status 200 Success */ {
  [key: string]: CompanyTypeResponse;
};
export type GetApiCompanyTypesArg = void;
export type GetApiCompanyRelationshipsDefinitionsResponse =
  /** status 200 Success */ {
    [key: string]: CompanyRelationshipTypeResponse;
  };
export type GetApiCompanyRelationshipsDefinitionsArg = void;
export type GetApiCommonCustomMetricByCustomMetricIdResponse =
  /** status 200 Success */ CustomMetricResponse;
export type GetApiCommonCustomMetricByCustomMetricIdArg = {
  customMetricId: string;
};
export type GetApiCommonCustomMetricResponse =
  /** status 200 Success */ CustomMetricResponse[];
export type GetApiCommonCustomMetricArg = {
  module?: ModuleEnum;
  isIntensity?: boolean;
  metricTypeId?: string;
  locationId?: string;
  stateId?: string;
  startDate?: DateOnly;
  endDate?: DateOnly;
};
export type PutApiCommonCustomMetricResponse = /** status 200 Success */ string;
export type PutApiCommonCustomMetricArg = {
  id?: string;
  customMetricRequest: CustomMetricRequest;
};
export type DeleteApiCommonCustomMetricResponse = unknown;
export type DeleteApiCommonCustomMetricArg = {
  customMetricId?: string;
};
export type GetApiCommonCustomMetricNormalizedResponse =
  /** status 200 Success */ NormalizedCustomMetricResponse[];
export type GetApiCommonCustomMetricNormalizedArg = {
  module?: ModuleEnum;
  isIntensity?: boolean;
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type GetApiCommonCustomMetricTypesResponse = /** status 200 Success */ {
  [key: string]: CustomMetricTypeResponse;
};
export type GetApiCommonCustomMetricTypesArg = {
  module?: ModuleEnum;
  isIntensity?: boolean;
};
export type GetApiCommonCustomMetricTypeRootsResponse =
  /** status 200 Success */ {
    [key: string]: CustomMetricTypeResponse[];
  };
export type GetApiCommonCustomMetricTypeRootsArg = {
  module?: ModuleEnum;
  isIntensity?: boolean;
};
export type PutApiCommonCustomMetricBatchResponse =
  /** status 200 Success */ string[];
export type PutApiCommonCustomMetricBatchArg = {
  body: CustomMetricRequest[];
};
export type GetApiCommonCustomMetricInputTemplatesResponse =
  /** status 200 Success */ CustomMetricInputTemplateResponse[];
export type GetApiCommonCustomMetricInputTemplatesArg = {
  locationId?: string;
  module?: ModuleEnum;
  isIntensity?: boolean;
  root?: string;
};
export type PostApiCommonCustomMetricByCustomMetricIdSignOffResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PostApiCommonCustomMetricByCustomMetricIdSignOffArg = {
  customMetricId: string;
  signOffRequest: SignOffRequest;
};
export type GetApiCommonCustomMetricByCustomMetricIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiCommonCustomMetricByCustomMetricIdAuditTrailArg = {
  customMetricId: string;
};
export type GetApiCommonCustomMetricByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiCommonCustomMetricByIdChangeSummaryArg = {
  id: string;
};
export type GetApiDashboardsResponse =
  /** status 200 Success */ DashboardResponse;
export type GetApiDashboardsArg = {
  dashboardType?: string;
};
export type PatchApiDashboardsResponse =
  /** status 200 Success */ DashboardResponse;
export type PatchApiDashboardsArg = {
  dashboardRequest: DashboardRequest;
};
export type GetApiDashboardsByIdResponse =
  /** status 200 Success */ DashboardResponse;
export type GetApiDashboardsByIdArg = {
  id: string;
};
export type GetApiCommonDateEnumFrequencyResponse =
  /** status 200 Success */ DateFrequency[];
export type GetApiCommonDateEnumFrequencyArg = void;
export type GetApiCommonDateEnumFrequencyWithHourlyResponse =
  /** status 200 Success */ DateFrequency[];
export type GetApiCommonDateEnumFrequencyWithHourlyArg = void;
export type PostApiDisclosuresResponse = /** status 200 Success */ string;
export type PostApiDisclosuresArg = {
  disclosuresRequest: DisclosuresRequest;
};
export type GetApiDisclosuresByStandardIdAndModuleIdResponse =
  /** status 200 Success */ DisclosureResponse[];
export type GetApiDisclosuresByStandardIdAndModuleIdArg = {
  standardId: StandardEnum;
  moduleId: ModuleEnum;
};
export type GetApiDisclosuresChildrenResponse =
  /** status 200 Success */ DisclosureResponse[];
export type GetApiDisclosuresChildrenArg = {
  parentId?: string;
};
export type GetApiDisclosuresByDisclosureIdResponse =
  /** status 200 Success */ DisclosureResponse[];
export type GetApiDisclosuresByDisclosureIdArg = {
  disclosureId: string;
};
export type PatchApiDisclosuresAnswersResponse =
  /** status 200 Success */ string[];
export type PatchApiDisclosuresAnswersArg = {
  patchDisclosureAnswersDto: PatchDisclosureAnswersDto;
};
export type GetApiDisclosuresAnswersResponse =
  /** status 200 Success */ DisclosureAnswerEntry[];
export type GetApiDisclosuresAnswersArg = {
  disclosureId?: string;
  businessUnitId?: string;
  reportingPeriodId?: string;
  indexingValue?: string;
};
export type GetApiDisclosuresCompletenessResponse =
  /** status 200 Success */ DisclosureCompletenessResponse;
export type GetApiDisclosuresCompletenessArg = {
  standardId?: StandardEnum;
  targetId?: StandardEnum;
};
export type PutApiDisclosuresMappingResponse =
  /** status 200 Success */ DisclosureMapResponse;
export type PutApiDisclosuresMappingArg = {
  disclosureMapRequest: DisclosureMapRequest;
};
export type PutApiDisclosuresIndexingResponse =
  /** status 200 Success */ string[];
export type PutApiDisclosuresIndexingArg = {
  disclosureIndexRequest: DisclosureIndexRequest;
};
export type GetApiDisclosuresResolversResponse =
  /** status 200 Success */ ResolverEntry[];
export type GetApiDisclosuresResolversArg = void;
export type GetApiEnergyEmissionsResponse =
  /** status 200 Success */ EnergyDashboardDto[];
export type GetApiEnergyEmissionsArg = {
  scope?: EmissionScopeEnum;
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type GetApiEnergyEmissionsPaginatedResponse =
  /** status 200 Success */ EnergyDataGridDtoPaginationResponse;
export type GetApiEnergyEmissionsPaginatedArg = {
  pageNumber?: number;
  pageSize?: number;
  configuredEmissionSourceId?: string;
  locationId?: string;
  stateId?: string;
  hasAlerts?: boolean;
  hasWaste?: boolean;
  startDate?: DateOnly;
  endDate?: DateOnly;
  scope?: EmissionScopeEnum;
};
export type GetApiCommonGasesResponse = /** status 200 Success */ {
  [key: string]: EmissionGasResponse;
};
export type GetApiCommonGasesArg = void;
export type GetApiEnergySourcesByIdResponse =
  /** status 200 Success */ EmissionSourceResponse;
export type GetApiEnergySourcesByIdArg = {
  id: string;
};
export type GetApiEnergySourcesSearchResponse =
  /** status 200 Success */ EmissionSourceResponse[];
export type GetApiEnergySourcesSearchArg = {
  name?: string;
  scope?: EmissionScopeEnum;
  configuredOnly?: boolean;
};
export type PutApiEnergySourcesDisplayNameResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PutApiEnergySourcesDisplayNameArg = {
  emissionSourceId?: string;
  configuredEmissionSourceRequest: ConfiguredEmissionSourceRequest;
};
export type GetApiEnergySourcesDashboardResponse = /** status 200 Success */ {
  [key: string]: ConfiguredEmissionSourceDashboardResponse;
};
export type GetApiEnergySourcesDashboardArg = {
  scope?: EmissionScopeEnum;
};
export type GetApiEnergySourcesResponse = /** status 200 Success */ {
  [key: string]: ConfiguredEmissionSourceResponse;
};
export type GetApiEnergySourcesArg = {
  scope?: EmissionScopeEnum;
};
export type PostApiEnergySourcesResponse = /** status 200 Success */ string;
export type PostApiEnergySourcesArg = {
  id?: string;
  emissionSourceRequest: EmissionSourceRequest;
};
export type GetApiEnergySourcesByConfiguredEmissionSourceIdEmissionSourcesResponse =
  /** status 200 Success */ EmissionSourceResponse[];
export type GetApiEnergySourcesByConfiguredEmissionSourceIdEmissionSourcesArg =
  {
    configuredEmissionSourceId: string;
  };
export type GetApiEnergySourcesEmissionFactorProviderResponse =
  /** status 200 Success */ EmissionFactorCategoryDto[];
export type GetApiEnergySourcesEmissionFactorProviderArg = {
  searchTerm?: string;
};
export type PostApiEnergySourcesGasResponse = /** status 200 Success */ string;
export type PostApiEnergySourcesGasArg = {
  emissionSourceId?: string;
  emissionSourceGasRequest: EmissionSourceGasRequest;
};
export type GetApiEmployeeAuditByEmployeeAuditIdResponse =
  /** status 200 Success */ EmployeeAuditResponse;
export type GetApiEmployeeAuditByEmployeeAuditIdArg = {
  employeeAuditId: string;
};
export type DeleteApiEmployeeAuditByEmployeeAuditIdResponse = unknown;
export type DeleteApiEmployeeAuditByEmployeeAuditIdArg = {
  employeeAuditId: string;
};
export type GetApiEmployeeAuditResponse =
  /** status 200 Success */ EmployeeAuditResponse[];
export type GetApiEmployeeAuditArg = void;
export type PutApiEmployeeAuditResponse = /** status 200 Success */ string;
export type PutApiEmployeeAuditArg = {
  id?: string;
  employeeAuditRequest: EmployeeAuditRequest;
};
export type PostApiEmployeeAuditByIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiEmployeeAuditByIdSignOffArg = {
  id: string;
  signOffRequest: SignOffRequest;
};
export type GetApiEmployeeAuditByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiEmployeeAuditByIdAuditTrailArg = {
  id: string;
};
export type GetApiEmployeeAuditByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiEmployeeAuditByIdChangeSummaryArg = {
  id: string;
};
export type GetApiEmployeeAuditDashboardResponse =
  /** status 200 Success */ EmployeeAuditDashboardResponse[];
export type GetApiEmployeeAuditDashboardArg = {
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type GetApiEmployeeAuditPaginatedResponse =
  /** status 200 Success */ EmployeeAuditResponsePaginationResponse;
export type GetApiEmployeeAuditPaginatedArg = {
  pageNumber?: number;
  pageSize?: number;
  locationId?: string;
  stateId?: string;
  reportingPeriodId?: string;
};
export type GetApiEmployeeAuditAgeGroupsResponse = /** status 200 Success */ {
  Unknown?: string;
  LessThanThirty?: string;
  BetweenThirtyAndFifty?: string;
  GreaterThanFifty?: string;
};
export type GetApiEmployeeAuditAgeGroupsArg = void;
export type GetApiEnergyInputTemplatesResponse =
  /** status 200 Success */ EnergyInputTemplateResponse[];
export type GetApiEnergyInputTemplatesArg = {
  locationId?: string;
  scope?: EmissionScopeEnum;
};
export type DeleteApiEnergyByIdResponse = unknown;
export type DeleteApiEnergyByIdArg = {
  id: string;
};
export type GetApiEnergyByIdResponse = /** status 200 Success */ EnergyResponse;
export type GetApiEnergyByIdArg = {
  id: string;
};
export type PutApiEnergyResponse = /** status 200 Success */ string;
export type PutApiEnergyArg = {
  id?: string;
  energyRequest: EnergyRequest;
};
export type GetApiEnergyGetBatchResponse =
  /** status 200 Success */ EnergyResponse[];
export type GetApiEnergyGetBatchArg = {
  ids?: string[];
};
export type PutApiEnergyBatchResponse = /** status 200 Success */ string[];
export type PutApiEnergyBatchArg = {
  body: EnergyRequest[];
};
export type GetApiEnergyByIdCalculationLogsResponse =
  /** status 200 Success */ string[];
export type GetApiEnergyByIdCalculationLogsArg = {
  id: string;
};
export type PostApiEnergyByIdSignOffResponse = /** status 200 Success */ string;
export type PostApiEnergyByIdSignOffArg = {
  id: string;
  signOffRequest: SignOffRequest;
};
export type GetApiEnergyByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiEnergyByIdAuditTrailArg = {
  id: string;
};
export type GetApiEnergyByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiEnergyByIdChangeSummaryArg = {
  id: string;
};
export type GetApiEnergyMetricsResponse =
  /** status 200 Success */ EnergyMetricSearchDto[];
export type GetApiEnergyMetricsArg = void;
export type GetApiEnergyMetricsByMetricIdUnitsResponse =
  /** status 200 Success */ EnergyUnitSearchDto[];
export type GetApiEnergyMetricsByMetricIdUnitsArg = {
  metricId: EnergyMetricEnum;
  isSelectable?: boolean;
  abbreviation?: string;
  name?: string;
};
export type PatchApiEnergyMetricsByMetricIdUnitsResponse = unknown;
export type PatchApiEnergyMetricsByMetricIdUnitsArg = {
  metricId: EnergyMetricEnum;
  body: EnergyUnitDto[];
};
export type GetApiEntityManagementEntityTemplateResponse =
  /** status 200 Success */ ExcelTemplateResponse[];
export type GetApiEntityManagementEntityTemplateArg = void;
export type PutApiEntityManagementEntityTemplateResponse =
  /** status 200 Success */ string;
export type PutApiEntityManagementEntityTemplateArg = {
  excelTemplateRequest: ExcelTemplateRequest;
};
export type GetApiEntityManagementEntityTemplateByTemplateIdResponse =
  /** status 200 Success */ ExcelTemplateResponse;
export type GetApiEntityManagementEntityTemplateByTemplateIdArg = {
  templateId: string;
};
export type GetApiEntityManagementEntityTemplateHistoryResponse =
  /** status 200 Success */ ExcelTemplateExecutionResponse[];
export type GetApiEntityManagementEntityTemplateHistoryArg = void;
export type PostApiEntityManagementEntityTemplateByFileNameResponse =
  /** status 200 Success */ string;
export type PostApiEntityManagementEntityTemplateByFileNameArg = {
  templateId?: string;
  fileName: string;
  /** Upload File */
  body: Blob;
};
export type GetApiFeedbackCategoryStakeholderSolicitationResponse =
  /** status 200 Success */ StakeholderFeedbackSolicitationResponse[];
export type GetApiFeedbackCategoryStakeholderSolicitationArg = void;
export type GetApiFeedbackCategoryInternalSolicitationResponse =
  /** status 200 Success */ InternalFeedbackSolicitationResponse[];
export type GetApiFeedbackCategoryInternalSolicitationArg = void;
export type PutApiCommunitiesCsiResponse = unknown;
export type PutApiCommunitiesCsiArg = {
  id?: string;
  fundingApplicationRequest: FundingApplicationRequest;
};
export type GetApiCommunitiesCsiResponse =
  /** status 200 Success */ FundingApplicationSearchResponse[];
export type GetApiCommunitiesCsiArg = void;
export type PostApiCommunitiesCsiFromDocumentByFileNameResponse =
  /** status 200 Success */ FundingApplicationOcrResponse;
export type PostApiCommunitiesCsiFromDocumentByFileNameArg = {
  fileName: string;
  /** Upload File */
  body: Blob;
};
export type GetApiCommunitiesCsiByByGroupingResponse =
  /** status 200 Success */ {
    [key: string]: number;
  };
export type GetApiCommunitiesCsiByByGroupingArg = {
  grouping: FundingApplicationGroupingEnum;
};
export type GetApiCommunitiesCsiByIdResponse =
  /** status 200 Success */ FundingApplicationResponse;
export type GetApiCommunitiesCsiByIdArg = {
  id: string;
};
export type PostApiCommunitiesCsiByFundingApplicationIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiCommunitiesCsiByFundingApplicationIdSignOffArg = {
  fundingApplicationId: string;
  signOffRequest: SignOffRequest;
};
export type DeleteApiCommunitiesCsiByFundingApplicationIdResponse = unknown;
export type DeleteApiCommunitiesCsiByFundingApplicationIdArg = {
  fundingApplicationId: string;
};
export type GetApiCommunitiesCsiByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiCommunitiesCsiByIdAuditTrailArg = {
  id: string;
};
export type GetApiCommunitiesCsiByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiCommunitiesCsiByIdChangeSummaryArg = {
  id: string;
};
export type PutApiCommunitiesImpactAssessmentResponse = unknown;
export type PutApiCommunitiesImpactAssessmentArg = {
  id?: string;
  impactAssessmentRequest: ImpactAssessmentRequest;
};
export type GetApiCommunitiesImpactAssessmentResponse =
  /** status 200 Success */ ImpactAssessmentResponsePaginationResponse;
export type GetApiCommunitiesImpactAssessmentArg = {
  pageNumber?: number;
  pageSize?: number;
  impactAssessmentType?: ImpactAssessmentTypeEnum;
  isPubliclyDisclosed?: boolean;
  locationId?: string;
  stateId?: string;
  startDate?: DateOnly;
  endDate?: DateOnly;
};
export type GetApiCommunitiesImpactAssessmentDashboardResponse =
  /** status 200 Success */ ImpactAssessmentResponse[];
export type GetApiCommunitiesImpactAssessmentDashboardArg = {
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type GetApiCommunitiesImpactAssessmentByIdResponse =
  /** status 200 Success */ ImpactAssessmentResponse;
export type GetApiCommunitiesImpactAssessmentByIdArg = {
  id: string;
};
export type DeleteApiCommunitiesImpactAssessmentByIdResponse =
  /** status 200 Success */ string;
export type DeleteApiCommunitiesImpactAssessmentByIdArg = {
  id: string;
};
export type GetApiCommunitiesImpactAssessmentByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiCommunitiesImpactAssessmentByIdAuditTrailArg = {
  id: string;
};
export type GetApiCommunitiesImpactAssessmentByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiCommunitiesImpactAssessmentByIdChangeSummaryArg = {
  id: string;
};
export type PostApiCommunitiesImpactAssessmentByIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiCommunitiesImpactAssessmentByIdSignOffArg = {
  id: string;
  signOffRequest: SignOffRequest;
};
export type PutApiHealthAndSafetyIncidentResponse = unknown;
export type PutApiHealthAndSafetyIncidentArg = {
  id?: string;
  healthAndSafetyIncidentRequest: HealthAndSafetyIncidentRequest;
};
export type GetApiHealthAndSafetyIncidentResponse =
  /** status 200 Success */ HealthAndSafetyIncidentResponsePaginationResponse;
export type GetApiHealthAndSafetyIncidentArg = {
  pageNumber?: number;
  pageSize?: number;
  skipCount?: number;
  locationId?: string;
  isPermanent?: boolean;
  stateId?: string;
  startDate?: DateOnly;
  endDate?: DateOnly;
  isHealthIncident?: boolean;
  griCategory?: GriIncidentCategorizationEnum;
};
export type GetApiHealthAndSafetyIncidentByIdResponse =
  /** status 200 Success */ HealthAndSafetyIncidentResponse;
export type GetApiHealthAndSafetyIncidentByIdArg = {
  id: string;
};
export type DeleteApiHealthAndSafetyIncidentByIdResponse = unknown;
export type DeleteApiHealthAndSafetyIncidentByIdArg = {
  id: string;
};
export type GetApiHealthAndSafetyIncidentCategoriesResponse =
  /** status 200 Success */ {
    Undetermined?: string;
    None?: string;
    FirstAidIncident?: string;
    CloseCallOrDangerousOccurrence?: string;
    MedicalTreatmentCase?: string;
    RestrictedWorkCase?: string;
    LostTimeInjury?: string;
    Fatality?: string;
  };
export type GetApiHealthAndSafetyIncidentCategoriesArg = void;
export type GetApiHealthAndSafetyIncidentDashboardResponse =
  /** status 200 Success */ HealthAndSafetyIncidentDashboardDto[];
export type GetApiHealthAndSafetyIncidentDashboardArg = {
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type PostApiHealthAndSafetyIncidentByIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiHealthAndSafetyIncidentByIdSignOffArg = {
  id: string;
  signOffRequest: SignOffRequest;
};
export type GetApiHealthAndSafetyIncidentByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiHealthAndSafetyIncidentByIdAuditTrailArg = {
  id: string;
};
export type GetApiHealthAndSafetyIncidentByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiHealthAndSafetyIncidentByIdChangeSummaryArg = {
  id: string;
};
export type GetApiCommunitiesIndicatorIndicatorsResponse =
  /** status 200 Success */ IndicatorResponse[];
export type GetApiCommunitiesIndicatorIndicatorsArg = void;
export type PostApiEnergyIndirectFromTemplateByFileNameResponse =
  /** status 200 Success */ EnergyOcrResponse;
export type PostApiEnergyIndirectFromTemplateByFileNameArg = {
  fileName: string;
  /** Upload File */
  body: Blob;
};
export type GetApiInternalFeedbackResponse =
  /** status 200 Success */ InternalFeedbackResponse[];
export type GetApiInternalFeedbackArg = void;
export type PostApiInternalFeedbackResponse =
  /** status 200 Success */ string[];
export type PostApiInternalFeedbackArg = {
  body: InternalFeedbackRequest[];
};
export type GetApiInternalFeedbackByIdResponse =
  /** status 200 Success */ InternalFeedbackResponse[];
export type GetApiInternalFeedbackByIdArg = {
  id: string;
};
export type GetApiCareerDevelopmentLearnershipByLearnershipGuidResponse =
  /** status 200 Success */ LearnershipResponse;
export type GetApiCareerDevelopmentLearnershipByLearnershipGuidArg = {
  learnershipGuid: string;
};
export type PutApiCareerDevelopmentLearnershipResponse =
  /** status 200 Success */ string;
export type PutApiCareerDevelopmentLearnershipArg = {
  learnershipRequest: LearnershipRequest;
};
export type PostApiCommonLocationsResponse = /** status 200 Success */ string;
export type PostApiCommonLocationsArg = {
  locationsRequest: LocationsRequest;
};
export type GetApiCommonLocationsResponse = /** status 200 Success */ {
  [key: string]: LocationsResponse;
};
export type GetApiCommonLocationsArg = void;
export type GetApiCommonLocationsLockedPeriodsResponse =
  /** status 200 Success */ {
    [key: string]: LocationsResponse;
  };
export type GetApiCommonLocationsLockedPeriodsArg = void;
export type GetApiCommonLocationsByLocationIdResponse =
  /** status 200 Success */ LocationsResponse;
export type GetApiCommonLocationsByLocationIdArg = {
  locationId: string;
};
export type GetApiCommonLockedPeriodsMissingEmissionSourcesResponse =
  /** status 200 Success */ string[];
export type GetApiCommonLockedPeriodsMissingEmissionSourcesArg = {
  id?: string;
  locationId?: string;
  reportingPeriodId?: string;
};
export type PutApiCommonLockedPeriodsResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PutApiCommonLockedPeriodsArg = {
  lockPeriodRequest: LockPeriodRequest;
};
export type DeleteApiCommonLockedPeriodsByLockPeriodIdResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type DeleteApiCommonLockedPeriodsByLockPeriodIdArg = {
  lockPeriodId: string;
};
export type GetApiEntityManagementMappedEntitiesResponse =
  /** status 200 Success */ MappedEntityResponse[];
export type GetApiEntityManagementMappedEntitiesArg = void;
export type GetApiEntityManagementMappedEntitiesByMappedEntityNameResponse =
  /** status 200 Success */ PropertyMappingResponse[];
export type GetApiEntityManagementMappedEntitiesByMappedEntityNameArg = {
  mappedEntityName: string;
};
export type PutApiNetZeroApplicationResponse = /** status 200 Success */ string;
export type PutApiNetZeroApplicationArg = {
  id?: string;
  netZeroProjectApplicationRequest: NetZeroProjectApplicationRequest;
};
export type GetApiNetZeroApplicationResponse =
  /** status 200 Success */ NetZeroProjectApplicationResponse[];
export type GetApiNetZeroApplicationArg = void;
export type GetApiNetZeroApplicationByIdResponse =
  /** status 200 Success */ NetZeroProjectApplicationResponse;
export type GetApiNetZeroApplicationByIdArg = {
  id: string;
};
export type DeleteApiNetZeroApplicationByIdResponse =
  /** status 200 Success */ string;
export type DeleteApiNetZeroApplicationByIdArg = {
  id: string;
};
export type GetApiNetZeroApplicationAllResponse =
  /** status 200 Success */ NormalizedNetZeroIndicatorResponse[];
export type GetApiNetZeroApplicationAllArg = {
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type PostApiNetZeroApplicationByNetZeroProjectApplicationIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiNetZeroApplicationByNetZeroProjectApplicationIdSignOffArg = {
  netZeroProjectApplicationId: string;
  signOffRequest: SignOffRequest;
};
export type GetApiNetZeroApplicationByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiNetZeroApplicationByIdAuditTrailArg = {
  id: string;
};
export type GetApiNetZeroApplicationByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiNetZeroApplicationByIdChangeSummaryArg = {
  id: string;
};
export type GetApiNetZeroProjectTypesResponse = /** status 200 Success */ {
  Unknown?: string;
  EmissionReductions?: string;
  AlternativeEnergy?: string;
  CarbonOffsets?: string;
  CarbonRemoval?: string;
  CarbonSequestration?: string;
};
export type GetApiNetZeroProjectTypesArg = void;
export type PutApiNetZeroProjectResponse = /** status 200 Success */ string;
export type PutApiNetZeroProjectArg = {
  id?: string;
  netZeroProjectRequest: NetZeroProjectRequest;
};
export type GetApiNetZeroProjectResponse =
  /** status 200 Success */ NetZeroProjectResponse[];
export type GetApiNetZeroProjectArg = void;
export type GetApiNetZeroProjectByIdResponse =
  /** status 200 Success */ NetZeroProjectResponse;
export type GetApiNetZeroProjectByIdArg = {
  id: string;
};
export type DeleteApiNetZeroProjectByIdResponse =
  /** status 200 Success */ string;
export type DeleteApiNetZeroProjectByIdArg = {
  id: string;
};
export type PostApiNetZeroProjectByNetZeroProjectIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiNetZeroProjectByNetZeroProjectIdSignOffArg = {
  netZeroProjectId: string;
  signOffRequest: SignOffRequest;
};
export type GetApiNetZeroProjectByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiNetZeroProjectByIdAuditTrailArg = {
  id: string;
};
export type GetApiNetZeroProjectByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiNetZeroProjectByIdChangeSummaryArg = {
  id: string;
};
export type GetApiNetZeroProjectByProjectIdIndicatorResponse =
  /** status 200 Success */ NetZeroProjectIndicatorResponse[];
export type GetApiNetZeroProjectByProjectIdIndicatorArg = {
  projectId: string;
};
export type PutApiNetZeroProjectByProjectIdIndicatorResponse =
  /** status 200 Success */ string;
export type PutApiNetZeroProjectByProjectIdIndicatorArg = {
  projectId: string;
  netZeroProjectIndicatorRequest: NetZeroProjectIndicatorRequest;
};
export type GetApiNetZeroProjectByProjectIdIndicatorAndIndicatorIdResponse =
  /** status 200 Success */ NetZeroProjectIndicatorResponse;
export type GetApiNetZeroProjectByProjectIdIndicatorAndIndicatorIdArg = {
  indicatorId: string;
  projectId: string;
};
export type DeleteApiNetZeroProjectByProjectIdIndicatorAndIndicatorIdResponse =
  /** status 200 Success */ string;
export type DeleteApiNetZeroProjectByProjectIdIndicatorAndIndicatorIdArg = {
  indicatorId: string;
  projectId: string;
};
export type PostApiNetZeroProjectIndicatorByIndicatorIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiNetZeroProjectIndicatorByIndicatorIdSignOffArg = {
  indicatorId: string;
  signOffRequest: SignOffRequest;
};
export type PutApiNetZeroProjectTypeResponse = /** status 200 Success */ string;
export type PutApiNetZeroProjectTypeArg = {
  netZeroProjectTypeRequest: NetZeroProjectTypeRequest;
};
export type GetApiNetZeroProjectTypeByProjectTypeIdResponse =
  /** status 200 Success */ NetZeroProjectTypeResponse;
export type GetApiNetZeroProjectTypeByProjectTypeIdArg = {
  projectTypeId: string;
};
export type GetApiNetZeroProjectTypeAllResponse =
  /** status 200 Success */ NetZeroProjectTypeResponse[];
export type GetApiNetZeroProjectTypeAllArg = void;
export type GetApiNotificationsResponse =
  /** status 200 Success */ UserNotificationResponse[];
export type GetApiNotificationsArg = void;
export type PutApiNotificationsResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PutApiNotificationsArg = {
  userNotificationRequest: UserNotificationRequest;
};
export type PutApiOperationResponse = /** status 200 Success */ string;
export type PutApiOperationArg = {
  operationRequest: OperationRequest;
};
export type GetApiOperationResponse = /** status 200 Success */ {
  [key: string]: OperationResponse;
};
export type GetApiOperationArg = void;
export type GetApiOperationByIdResponse =
  /** status 200 Success */ OperationResponse;
export type GetApiOperationByIdArg = {
  id: string;
};
export type DeleteApiOperationByIdResponse = unknown;
export type DeleteApiOperationByIdArg = {
  id: string;
};
export type GetApiOperationOperationTypesResponse = /** status 200 Success */ {
  Office?: string;
  Manufacturing?: string;
  Production?: string;
  Extractive?: string;
  Retail?: string;
  Other?: string;
};
export type GetApiOperationOperationTypesArg = void;
export type GetApiCommunitiesProjectProjectsResponse =
  /** status 200 Success */ ProjectResponse[];
export type GetApiCommunitiesProjectProjectsArg = void;
export type GetApiCommunitiesProjectByProjectIdResponse =
  /** status 200 Success */ ProjectResponse;
export type GetApiCommunitiesProjectByProjectIdArg = {
  projectId: string;
};
export type DeleteApiCommunitiesProjectByProjectIdResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type DeleteApiCommunitiesProjectByProjectIdArg = {
  projectId: string;
};
export type GetApiCommunitiesProjectByProjectIdPaymentsResponse =
  /** status 200 Success */ ProjectPaymentResponse[];
export type GetApiCommunitiesProjectByProjectIdPaymentsArg = {
  projectId: string;
};
export type PostApiCommunitiesProjectPaymentsResponse =
  /** status 200 Success */ string[];
export type PostApiCommunitiesProjectPaymentsArg = {
  projectPaymentsBulkRequest: ProjectPaymentsBulkRequest;
};
export type PutApiCommunitiesProjectPaymentResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PutApiCommunitiesProjectPaymentArg = {
  projectPaymentRequest: ProjectPaymentRequest;
};
export type GetApiCommunitiesProjectPaymentByProjectPaymentIdResponse =
  /** status 200 Success */ ProjectPaymentResponse;
export type GetApiCommunitiesProjectPaymentByProjectPaymentIdArg = {
  projectPaymentId: string;
};
export type DeleteApiCommunitiesProjectPaymentByProjectPaymentIdResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type DeleteApiCommunitiesProjectPaymentByProjectPaymentIdArg = {
  projectPaymentId: string;
};
export type GetApiCommunitiesProjectByProjectIdReportsResponse =
  /** status 200 Success */ ProjectReportResponse[];
export type GetApiCommunitiesProjectByProjectIdReportsArg = {
  projectId: string;
};
export type PostApiCommunitiesProjectByProjectIdRequestReportResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PostApiCommunitiesProjectByProjectIdRequestReportArg = {
  projectId: string;
  body: string[];
};
export type DeleteApiCommunitiesProjectByProjectReportIdReportResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type DeleteApiCommunitiesProjectByProjectReportIdReportArg = {
  projectReportId: string;
};
export type GetApiCommunitiesProjectByProjectIdIndicatorsResponse =
  /** status 200 Success */ IndicatorResponse[];
export type GetApiCommunitiesProjectByProjectIdIndicatorsArg = {
  projectId: string;
};
export type PostApiCommunitiesProjectByProjectIdIndicatorsResponse =
  /** status 200 Success */ ProjectIndicatorResponse[];
export type PostApiCommunitiesProjectByProjectIdIndicatorsArg = {
  projectId: string;
  body: ProjectIndicatorBulkEntry[];
};
export type PutApiPublicCommunitiesProjectReportByProjectReportIdAnswersResponse =
  /** status 200 Success */ ProjectIndicatorAnswerUpsertReponse[];
export type PutApiPublicCommunitiesProjectReportByProjectReportIdAnswersArg = {
  projectReportId: string;
  body: {
    [key: string]: string;
  };
};
export type GetApiPublicCommunitiesProjectReportByProjectReportIdAnswersResponse =
  /** status 200 Success */ ProjectIndicatorAnswerResponse[];
export type GetApiPublicCommunitiesProjectReportByProjectReportIdAnswersArg = {
  projectReportId: string;
};
export type GetApiCommonReportingPeriodResponse =
  /** status 200 Success */ ReportingPeriodResponse[];
export type GetApiCommonReportingPeriodArg = void;
export type PutApiCommonReportingPeriodResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PutApiCommonReportingPeriodArg = {
  reportingPeriodRequest: ReportingPeriodRequest;
};
export type DeleteApiCommonReportingPeriodByReportingPeriodIdResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type DeleteApiCommonReportingPeriodByReportingPeriodIdArg = {
  reportingPeriodId: string;
};
export type GetApiBusinessPartnerRolesResponse =
  /** status 200 Success */ RoleResponse[];
export type GetApiBusinessPartnerRolesArg = void;
export type PutApiBusinessPartnerRolesResponse =
  /** status 200 Success */ string;
export type PutApiBusinessPartnerRolesArg = {
  id?: string;
  roleRequest: RoleRequest;
};
export type GetApiBusinessPartnerRolesByRoleGuidResponse =
  /** status 200 Success */ RoleResponse;
export type GetApiBusinessPartnerRolesByRoleGuidArg = {
  roleGuid: string;
};
export type PutApiEnergyScope3Response = /** status 200 Success */ string;
export type PutApiEnergyScope3Arg = {
  id?: string;
  scope3EnergyRequest: Scope3EnergyRequest;
};
export type GetApiEnergyScope3Response =
  /** status 200 Success */ Scope3EnergyResponse;
export type GetApiEnergyScope3Arg = {
  scope3EnergyId?: string;
};
export type PutApiEnergyScope3BatchResponse =
  /** status 200 Success */ string[];
export type PutApiEnergyScope3BatchArg = {
  body: Scope3EnergyRequest[];
};
export type GetApiEnergyScope3InputTemplatesResponse =
  /** status 200 Success */ Scope3EnergyInputTemplateResponse[];
export type GetApiEnergyScope3InputTemplatesArg = {
  locationId?: string;
  scope3CategoryId?: string;
};
export type PostApiEnergyScope3ByScope3EnergyIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiEnergyScope3ByScope3EnergyIdSignOffArg = {
  scope3EnergyId: string;
  signOffRequest: SignOffRequest;
};
export type DeleteApiEnergyScope3ByScope3EnergyIdResponse = unknown;
export type DeleteApiEnergyScope3ByScope3EnergyIdArg = {
  scope3EnergyId: string;
};
export type GetApiEnergyScope3EmissionsResponse =
  /** status 200 Success */ Scope3DashboardDto[];
export type GetApiEnergyScope3EmissionsArg = {
  scope?: EmissionScopeEnum;
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type GetApiEnergyScope3PaginatedResponse =
  /** status 200 Success */ Scope3DataGridDtoPaginationResponse;
export type GetApiEnergyScope3PaginatedArg = {
  pageNumber?: number;
  pageSize?: number;
  configuredEmissionSourceId?: string;
  locationId?: string;
  stateId?: string;
  hasAlerts?: boolean;
  hasWaste?: boolean;
  startDate?: DateOnly;
  endDate?: DateOnly;
};
export type GetApiEnergyScope3ByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiEnergyScope3ByIdAuditTrailArg = {
  id: string;
};
export type GetApiEnergyScope3ByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiEnergyScope3ByIdChangeSummaryArg = {
  id: string;
};
export type GetApiEnergyScope3CategoriesResponse = /** status 200 Success */ {
  [key: string]: Scope3CategoryResponse;
};
export type GetApiEnergyScope3CategoriesArg = void;
export type PostApiEnergyScope3OnceOffResponse =
  /** status 200 Success */ SimpleEmissionResponse[];
export type PostApiEnergyScope3OnceOffArg = {
  calculateEffective?: boolean;
  scope3EnergyRequest: Scope3EnergyRequest;
};
export type GetApiEnergyScope3GetBatchResponse =
  /** status 200 Success */ Scope3EnergyResponse[];
export type GetApiEnergyScope3GetBatchArg = {
  ids?: string[];
};
export type GetApiCommonSectorsResponse = /** status 200 Success */ {
  [key: string]: SectorResponse;
};
export type GetApiCommonSectorsArg = void;
export type GetApiCommonSettingsReportingStructureResponse =
  /** status 200 Success */ {
    [key: string]: any;
  };
export type GetApiCommonSettingsReportingStructureArg = void;
export type GetApiCommonSettingsDashboardPaletteResponse =
  /** status 200 Success */ string[];
export type GetApiCommonSettingsDashboardPaletteArg = void;
export type GetApiCommonSettingsFeatureFlagsResponse =
  /** status 200 Success */ FeatureFlagSettings;
export type GetApiCommonSettingsFeatureFlagsArg = void;
export type GetApiSignoffResponse =
  /** status 200 Success */ MappedEntityResponse[];
export type GetApiSignoffArg = void;
export type GetApiSignoffByEntityNameEntityStatesResponse =
  /** status 200 Success */ {
    [key: string]: StateResponse;
  };
export type GetApiSignoffByEntityNameEntityStatesArg = {
  entityName: string;
};
export type PutApiSignoffRequiredDocumentsResponse =
  /** status 200 Success */ string;
export type PutApiSignoffRequiredDocumentsArg = {
  requiredDocumentSignOffRequest: RequiredDocumentSignOffRequest;
};
export type DeleteApiSignoffByIdResponse = unknown;
export type DeleteApiSignoffByIdArg = {
  id: string;
};
export type GetApiSignoffByEntityNameAllowedTransitionsResponse =
  /** status 200 Success */ AllowedTransitionResponse[];
export type GetApiSignoffByEntityNameAllowedTransitionsArg = {
  entityName: string;
};
export type GetApiCommunitiesSocialIncidentCategoriesResponse =
  /** status 200 Success */ SocialIncidentCategoryResponse[];
export type GetApiCommunitiesSocialIncidentCategoriesArg = void;
export type GetApiCommunitiesSocialIncidentResponse =
  /** status 200 Success */ SocialIncidentResponse[];
export type GetApiCommunitiesSocialIncidentArg = void;
export type PutApiCommunitiesSocialIncidentResponse =
  /** status 200 Success */ string;
export type PutApiCommunitiesSocialIncidentArg = {
  id?: string;
  socialIncidentRequest: SocialIncidentRequest;
};
export type GetApiCommunitiesSocialIncidentBySocialIncidentIdResponse =
  /** status 200 Success */ SocialIncidentResponse;
export type GetApiCommunitiesSocialIncidentBySocialIncidentIdArg = {
  socialIncidentId: string;
};
export type DeleteApiCommunitiesSocialIncidentBySocialIncidentIdResponse =
  unknown;
export type DeleteApiCommunitiesSocialIncidentBySocialIncidentIdArg = {
  socialIncidentId: string;
};
export type PostApiCommunitiesSocialIncidentBySocialIncidentIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiCommunitiesSocialIncidentBySocialIncidentIdSignOffArg = {
  socialIncidentId: string;
  signOffRequest: SignOffRequest;
};
export type GetApiCommunitiesSocialIncidentByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiCommunitiesSocialIncidentByIdAuditTrailArg = {
  id: string;
};
export type GetApiStakeholderFeedbackResponse =
  /** status 200 Success */ StakeholderFeedbackResponse[];
export type GetApiStakeholderFeedbackArg = void;
export type PostApiStakeholderFeedbackResponse =
  /** status 200 Success */ string[];
export type PostApiStakeholderFeedbackArg = {
  body: StakeholderFeedbackRequest[];
};
export type GetApiStakeholderFeedbackByIdResponse =
  /** status 200 Success */ StakeholderFeedbackResponse[];
export type GetApiStakeholderFeedbackByIdArg = {
  id: string;
};
export type GetApiStandardsResponse =
  /** status 200 Success */ StandardResponse[];
export type GetApiStandardsArg = void;
export type GetApiStandardsVersionsResponse =
  /** status 200 Success */ StandardVersionResponse[];
export type GetApiStandardsVersionsArg = void;
export type GetApiValueChainSupplierReviewBySupplierReviewGuidResponse =
  /** status 200 Success */ SupplierReviewResponse;
export type GetApiValueChainSupplierReviewBySupplierReviewGuidArg = {
  supplierReviewGuid: string;
};
export type DeleteApiValueChainSupplierReviewBySupplierReviewGuidResponse =
  unknown;
export type DeleteApiValueChainSupplierReviewBySupplierReviewGuidArg = {
  supplierReviewGuid: string;
};
export type GetApiValueChainSupplierReviewResponse =
  /** status 200 Success */ SupplierReviewResponse[];
export type GetApiValueChainSupplierReviewArg = void;
export type PutApiValueChainSupplierReviewResponse =
  /** status 200 Success */ string;
export type PutApiValueChainSupplierReviewArg = {
  id?: string;
  supplierReviewRequest: SupplierReviewRequest;
};
export type GetApiValueChainSupplierReviewBySupplierReviewGuidAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiValueChainSupplierReviewBySupplierReviewGuidAuditTrailArg = {
  id?: string;
  supplierReviewGuid: string;
};
export type PostApiValueChainSupplierReviewBySupplierReviewGuidSignOffResponse =
  /** status 200 Success */ string;
export type PostApiValueChainSupplierReviewBySupplierReviewGuidSignOffArg = {
  supplierReviewId?: string;
  supplierReviewGuid: string;
  signOffRequest: SignOffRequest;
};
export type PutApiTargetResponse = /** status 200 Success */ string;
export type PutApiTargetArg = {
  targetRequest: TargetRequest;
};
export type GetApiTargetResponse = /** status 200 Success */ GetTargetsResponse;
export type GetApiTargetArg = {
  module?: ModuleEnum;
  reportingPeriodId?: string;
};
export type PutApiTargetEnergyResponse = /** status 200 Success */ string;
export type PutApiTargetEnergyArg = {
  energyTargetRequest: EnergyTargetRequest;
};
export type PutApiTargetEnergyIntensityResponse =
  /** status 200 Success */ string;
export type PutApiTargetEnergyIntensityArg = {
  energyIntensityTargetRequest: EnergyIntensityTargetRequest;
};
export type PutApiTargetNetzeroResponse = /** status 200 Success */ string;
export type PutApiTargetNetzeroArg = {
  netZeroProjectTargetRequest: NetZeroProjectTargetRequest;
};
export type GetApiTargetIntensityTargetsResponse =
  /** status 200 Success */ EnergyIntensityTargetResponse[];
export type GetApiTargetIntensityTargetsArg = void;
export type GetApiTargetByTargetIdResponse =
  /** status 200 Success */ TargetResponse;
export type GetApiTargetByTargetIdArg = {
  targetId: string;
};
export type DeleteApiTargetByTargetIdResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type DeleteApiTargetByTargetIdArg = {
  targetId: string;
};
export type GetApiTargetAllResponse =
  /** status 200 Success */ TargetResponse[];
export type GetApiTargetAllArg = {
  module?: ModuleEnum;
};
export type GetApiTargetTypesResponse = /** status 200 Success */ {
  [key: string]: TargetTypeDto;
};
export type GetApiTargetTypesArg = {
  module?: ModuleEnum;
};
export type GetApiCareerDevelopmentTrainingByTrainingGuidResponse =
  /** status 200 Success */ TrainingResponse;
export type GetApiCareerDevelopmentTrainingByTrainingGuidArg = {
  trainingGuid: string;
};
export type PutApiCareerDevelopmentTrainingResponse =
  /** status 200 Success */ string;
export type PutApiCareerDevelopmentTrainingArg = {
  trainingRequest: TrainingRequest;
};
export type GetApiCommonUnitsByUnitTypeResponse = /** status 200 Success */ {
  [key: string]: UnitResponse;
};
export type GetApiCommonUnitsByUnitTypeArg = {
  unitType: UnitTypeEnum;
};
export type PatchApiCommonUnitsByUnitTypeResponse =
  /** status 200 Success */ string[];
export type PatchApiCommonUnitsByUnitTypeArg = {
  unitType: UnitTypeEnum;
  body: UnitPatchDto[];
};
export type GetApiCommonUnitsTypesResponse =
  /** status 200 Success */ UnitTypeEnum[];
export type GetApiCommonUnitsTypesArg = void;
export type PutApiWasteDisposalResponse = /** status 200 Success */ string;
export type PutApiWasteDisposalArg = {
  id?: string;
  wasteDisposalRequest: WasteDisposalRequest;
};
export type GetApiWasteDisposalResponse =
  /** status 200 Success */ WasteDisposalResponse;
export type GetApiWasteDisposalArg = {
  wasteDisposalId?: string;
};
export type PutApiWasteDisposalBatchResponse =
  /** status 200 Success */ string[];
export type PutApiWasteDisposalBatchArg = {
  body: WasteDisposalRequest[];
};
export type GetApiWasteDisposalInputTemplatesResponse =
  /** status 200 Success */ WasteDisposalInputTemplateResponse[];
export type GetApiWasteDisposalInputTemplatesArg = {
  locationId?: string;
};
export type DeleteApiWasteDisposalByWasteDisposalIdResponse = unknown;
export type DeleteApiWasteDisposalByWasteDisposalIdArg = {
  wasteDisposalId: string;
};
export type PostApiWasteDisposalByWasteDisposalIdSignOffResponse =
  /** status 200 Success */ string;
export type PostApiWasteDisposalByWasteDisposalIdSignOffArg = {
  wasteDisposalId: string;
  signOffRequest: SignOffRequest;
};
export type GetApiWasteDisposalDashboardResponse =
  /** status 200 Success */ WasteDisposalDashboardDto[];
export type GetApiWasteDisposalDashboardArg = {
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type GetApiWasteDisposalTableResponse =
  /** status 200 Success */ WasteDisposalSearchDto[];
export type GetApiWasteDisposalTableArg = void;
export type GetApiWasteDisposalByIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiWasteDisposalByIdAuditTrailArg = {
  id: string;
};
export type GetApiWasteDisposalByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiWasteDisposalByIdChangeSummaryArg = {
  id: string;
};
export type GetApiWastemovementByWasteMovementGuidResponse =
  /** status 200 Success */ WasteMovementResponse;
export type GetApiWastemovementByWasteMovementGuidArg = {
  wasteMovementDetailsGuid?: string;
  wasteMovementGuid: string;
};
export type GetFacilitymovementsByFacilityMovementGuidResponse =
  /** status 200 Success */ WasteMovementResponse;
export type GetFacilitymovementsByFacilityMovementGuidArg = {
  facilityMovementGuid: string;
};
export type PutApiWastemovementResponse = /** status 200 Success */ string;
export type PutApiWastemovementArg = {
  wasteMovementRequest: WasteMovementRequest;
};
export type GetApiWasteSourceResponse = /** status 200 Success */ {
  [key: string]: WasteSourceResponse;
};
export type GetApiWasteSourceArg = void;
export type GetApiWasteSourceDisplayNameResponse = /** status 200 Success */ {
  [key: string]: ConfiguredEmissionSourceResponse;
};
export type GetApiWasteSourceDisplayNameArg = void;
export type GetApiWaterMeterResponse =
  /** status 200 Success */ WaterMeterResponse[];
export type GetApiWaterMeterArg = void;
export type PutApiWaterMeterResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PutApiWaterMeterArg = {
  waterMeterRequest: WaterMeterRequest;
};
export type GetApiWaterMeterByWaterMeterIdReadingsResponse =
  /** status 200 Success */ WaterMeterReadingResponse[];
export type GetApiWaterMeterByWaterMeterIdReadingsArg = {
  waterMeterId: string;
  pageNumber?: number;
  pageSize?: number;
  skipCount?: number;
};
export type GetApiWaterMeterByWaterMeterIdCalibrationsResponse =
  /** status 200 Success */ WaterMeterCalibrationResponse[];
export type GetApiWaterMeterByWaterMeterIdCalibrationsArg = {
  waterMeterId: string;
};
export type PutApiWaterMeterCalibrationResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PutApiWaterMeterCalibrationArg = {
  waterMeterCalibrationRequest: WaterMeterCalibrationRequest;
};
export type GetApiWaterMeterReadingResponse =
  /** status 200 Success */ WaterUsageResponse[];
export type GetApiWaterMeterReadingArg = {
  reportingPeriodId?: string;
  businessUnitIds?: string[];
};
export type PutApiWaterMeterReadingResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PutApiWaterMeterReadingArg = {
  id?: string;
  waterMeterReadingRequest: WaterMeterReadingRequest;
};
export type DeleteApiWaterMeterReadingResponse = unknown;
export type DeleteApiWaterMeterReadingArg = {
  waterMeterReadingId?: string;
};
export type PostApiWaterMeterReadingFromTemplateByFileNameResponse =
  /** status 200 Success */ WaterMeterReadingOcrResponse;
export type PostApiWaterMeterReadingFromTemplateByFileNameArg = {
  fileName: string;
  /** Upload File */
  body: Blob;
};
export type PostApiWaterMeterReadingByWaterMeterReadingIdSignOffResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PostApiWaterMeterReadingByWaterMeterReadingIdSignOffArg = {
  waterMeterReadingId: string;
  signOffRequest: SignOffRequest;
};
export type GetApiWaterMeterReadingByWaterMeterReadingIdAuditTrailResponse =
  /** status 200 Success */ AuditHistoryResponse[];
export type GetApiWaterMeterReadingByWaterMeterReadingIdAuditTrailArg = {
  waterMeterReadingId: string;
};
export type GetApiWaterMeterReadingByIdChangeSummaryResponse =
  /** status 200 Success */ string[];
export type GetApiWaterMeterReadingByIdChangeSummaryArg = {
  id: string;
};
export type GetApiWaterMeterReadingByWaterMeterReadingIdResponse =
  /** status 200 Success */ WaterMeterReadingResponse;
export type GetApiWaterMeterReadingByWaterMeterReadingIdArg = {
  waterMeterReadingId: string;
};
export type PutApiWaterMeterReadingUsageResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PutApiWaterMeterReadingUsageArg = {
  waterUsageRequest: WaterUsageRequest;
};
export type GetApiWaterSourceQualitiesResponse =
  /** status 200 Success */ WaterQualityResponse[];
export type GetApiWaterSourceQualitiesArg = void;
export type GetApiWaterSourceTypesResponse =
  /** status 200 Success */ WaterSourceTypeResponse[];
export type GetApiWaterSourceTypesArg = void;
export type GetApiWaterSourceResponse =
  /** status 200 Success */ WaterSourceResponse[];
export type GetApiWaterSourceArg = void;
export type PutApiWaterSourceResponse =
  /** status 200 Success */ GuidSingleValueResponse;
export type PutApiWaterSourceArg = {
  waterSourceRequest: WaterSourceRequest;
};
export type SeverityEnum =
  | 'Unknown'
  | 'Insignificant'
  | 'Minor'
  | 'Moderate'
  | 'Major'
  | 'Extreme';
export type AlertResponse = {
  id?: string;
  isRead?: boolean;
  severity?: SeverityEnum;
  title: string;
  message: string;
  checkEntityName: string;
  clarification?: string | null;
  applicationUserId: string;
};
export type AlertRequest = {
  id?: string | null;
  isRead?: boolean;
  clarification: string;
  severity?: SeverityEnum;
  title: string;
  message: string;
  checkEntityName: string;
  applicationUserId: string;
};
export type ImpactEnum = 'Ecosystem' | 'Species' | 'Unknown';
export type ImpactDriverEnum =
  | 'Energy'
  | 'LandUse'
  | 'Materials'
  | 'WaterUse'
  | 'Disturbances'
  | 'Emissions'
  | 'Waste'
  | 'Soil'
  | 'Water'
  | 'Unknown';
export type ImpactRatingEnum =
  | 'Unknown'
  | 'PositiveMinimal'
  | 'PositiveMedium'
  | 'PositiveLarge'
  | 'NegativeLarge'
  | 'NegativeMedium'
  | 'NegativeMinimal';
export type BiodiversityImpactRequest = {
  id?: string | null;
  operationId?: string;
  impact?: ImpactEnum;
  impactDriver?: ImpactDriverEnum;
  description?: string | null;
  impactRating?: ImpactRatingEnum;
  impactRatingComment?: string | null;
  documentIds?: string[] | null;
};
export type TransitionResponse = {
  id?: string;
  fromStateId?: string;
  toStateId?: string;
  comment?: string | null;
  captureById: string;
  captureTime: string;
};
export type BiodiversityImpactResponse = {
  id: string;
  operationId: string;
  impact?: ImpactEnum;
  impactDriver?: ImpactDriverEnum;
  description?: string | null;
  impactRating?: ImpactRatingEnum;
  impactRatingComment?: string | null;
  lastTransition: TransitionResponse;
};
export type BiodiversityImpactResponsePaginationResponse = {
  count?: number;
  pageNumber?: number;
  pageSize?: number;
  results?: BiodiversityImpactResponse[] | null;
};
export type OperationTypeEnum =
  | 'Office'
  | 'Manufacturing'
  | 'Production'
  | 'Extractive'
  | 'Retail'
  | 'Other';
export type SignOffRequest = {
  currentState: string;
  targetState: string;
  comment?: string | null;
};
export type AuditHistoryResponse = {
  description?: string | null;
  entityId?: string;
  isInitial?: boolean;
  captureById: string;
  captureTime: string;
};
export type ImpactRatingResponse = {
  name?: string | null;
  value?: number;
};
export type BiodiversityDashboardResponse = {
  operationId?: string;
  impact?: ImpactEnum;
  impactDriver?: ImpactDriverEnum;
  description?: string | null;
  impactRating?: ImpactRatingEnum;
  impactRatingReadable?: string | null;
  impactRatingScore?: number;
  impactRatingComment?: string | null;
  stateId?: string;
  captureById: string;
  captureTime: string;
};
export type BusinessPartnerTypeEnum = 'Person' | 'Organization';
export type GenderEnum = 'Unknown' | 'Male' | 'Female' | 'Other';
export type EthnicityEnum =
  | 'Unknown'
  | 'Black'
  | 'Coloured'
  | 'Indian'
  | 'White'
  | 'Other';
export type DateDto = {
  year?: number;
  month?: number;
  day?: number;
};
export type BusinessPartnerResponse = {
  id?: string;
  bpType?: BusinessPartnerTypeEnum;
  name?: string | null;
  surname?: string | null;
  identificationType?: string | null;
  identificationValue?: string | null;
  employeeID?: string | null;
  gender?: GenderEnum;
  ethnicity?: EthnicityEnum;
  dob?: DateDto;
  ageGroup?: string | null;
  website?: string | null;
  email?: string | null;
  telephone?: string | null;
  cellphone?: string | null;
  locationId?: string;
  textAddress?: string | null;
};
export type DateRange = {
  startDate?: DateDto;
  endDate?: DateDto;
};
export type BursaryResponse = {
  cost?: number;
  summary?: string | null;
  participant?: BusinessPartnerResponse;
  id?: string | null;
  name?: string | null;
  dateRange?: DateRange;
  discriminator?: string | null;
  participants?: string[] | null;
  lastTransition?: TransitionResponse;
};
export type BusinessPartnerRequest = {
  id?: string;
  bpType?: BusinessPartnerTypeEnum;
  name?: string | null;
  surname?: string | null;
  identificationType?: string | null;
  identificationValue?: string | null;
  employeeID?: string | null;
  gender?: GenderEnum;
  ethnicity?: EthnicityEnum;
  dob?: DateDto;
  website?: string | null;
  email?: string | null;
  telephone?: string | null;
  cellphone?: string | null;
  locationId?: string;
};
export type BursaryRequest = {
  cost?: number;
  summary?: string | null;
  participant?: BusinessPartnerRequest;
  id?: string | null;
  name?: string | null;
  dateRange?: DateRange;
  documentIds?: string[] | null;
};
export type CarbonTaxScheduleResponse = {
  id?: string;
  ipccCode?: string | null;
  activity?: string | null;
  basicTaxFreeAllowance?: number;
  processEmissionsAllowance?: number;
  fugitiveEmissionsAllowance?: number;
  tradeExposureAllowance?: number;
  performanceAllowance?: number;
  carbonBudgetAllowance?: number;
  offsetsAllowance?: number;
  maximumTotalAllowance?: number;
};
export type CarbonTaxResultResponse = {
  id?: string | null;
  taxAmount?: number;
  netEmissionsEquivalent?: number;
  scope1Emissions?: number;
  sequesteredEmissions?: number;
  cTerm?: number;
  dieselAndPetrolEmissions?: number;
  mTerm?: number;
  processEmissions?: number;
  jTerm?: number;
  fugitiveEmissions?: number;
  kTerm?: number;
  maximumTotalAllowance?: number;
  allowanceInputId?: string;
  allowanceInput?: AllowanceInputResponse;
  carbonTaxScheduleId?: string;
  carbonTaxSchedule?: CarbonTaxScheduleResponse;
};
export type AllowanceInputResponse = {
  carbonTaxInputId?: string;
  ipccCode?: string | null;
  basicAllowance?: number;
  processEmissionsAllowance?: number;
  fugitiveEmissionsAllowance?: number;
  tradeExposureAllowance?: number;
  performanceAllowance?: number;
  carbonBudgetAllowance?: number;
  offsetAllowance?: number;
  carbonTaxResult?: CarbonTaxResultResponse;
};
export type CarbonTaxInputResponse = {
  id?: string;
  taxRate?: number;
  reportingPeriodId?: string;
  allowanceInputs?: AllowanceInputResponse[] | null;
};
export type CarbonTaxSaveInputResponse = {
  lastTransition?: TransitionResponse;
  id?: string;
  taxRate?: number;
  reportingPeriodId?: string;
  allowanceInputs?: AllowanceInputResponse[] | null;
};
export type AllowanceInputRequest = {
  ipccCode?: string | null;
  basicAllowance?: number;
  processEmissionsAllowance?: number;
  fugitiveEmissionsAllowance?: number;
  tradeExposureAllowance?: number;
  performanceAllowance?: number;
  carbonBudgetAllowance?: number;
  offsetAllowance?: number;
};
export type CarbonTaxInputRequest = {
  reportingPeriodId: string;
  allowanceInput?: AllowanceInputRequest[] | null;
  businessUnitIds: string[];
  documentIds?: string[] | null;
};
export type TrainingResponse = {
  cost?: number;
  summary?: string | null;
  participants?: BusinessPartnerResponse[] | null;
  id?: string | null;
  name?: string | null;
  dateRange?: DateRange;
  discriminator?: string | null;
  participant?: string;
  lastTransition?: TransitionResponse;
};
export type CareerReviewResponse = {
  participants?: BusinessPartnerResponse[] | null;
  id?: string | null;
  name?: string | null;
  dateRange?: DateRange;
  discriminator?: string | null;
  summary?: string | null;
  cost?: number;
  participant?: string;
  lastTransition?: TransitionResponse;
};
export type CareerReviewRequest = {
  participants?: BusinessPartnerRequest[] | null;
  id?: string | null;
  name?: string | null;
  dateRange?: DateRange;
  documentIds?: string[] | null;
};
export type MonthEnum =
  | 'Unknown'
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December';
export type CompanyTypeResponse = {
  id?: string;
  typeName?: string | null;
};
export type CompanyLocationResponse = {
  id?: string;
  companyTypeId: string;
  companyType?: CompanyTypeResponse;
  locationId: string;
  name?: string | null;
};
export type DateOnly = string;
export type CompanyRelationshipResponse = {
  id?: string;
  relationshipTypeId?: string;
  relationshipParentId?: string;
  relationshipParent?: CompanyResponse;
  relationshipChildId?: string;
  relationshipChild?: CompanyResponse;
  validFrom?: DateOnly;
  validTo?: DateOnly;
  value?: number;
};
export type CompanyResponse = {
  id?: string;
  companyName?: string | null;
  keyOperations?: string | null;
  sectorId: string;
  operatingCountries?: string | null;
  registeredCountry?: string | null;
  registrationNumber?: string | null;
  isin?: string | null;
  financialYearStart?: MonthEnum;
  stockExchange?: string | null;
  ticker?: string | null;
  legalEntityIdentifier?: string | null;
  registeredOfficeAddressId?: string;
  postalAddressId?: string;
  groupCompanySecretaryId?: string;
  companyLocations?: CompanyLocationResponse[] | null;
  relatedCompanyParents?: CompanyRelationshipResponse[] | null;
  relatedCompanyChildren?: CompanyRelationshipResponse[] | null;
};
export type CompanyLocationRequest = {
  id?: string;
  companyTypeId?: string;
  companyId?: string;
  locationId?: string;
};
export type CompanyRelationshipParentRequest = {
  id?: string;
  relationshipTypeId?: string;
  relationshipParentId?: string;
  value?: number;
};
export type CompanyRelationshipChildRequest = {
  id?: string;
  relationshipTypeId?: string;
  relationshipChildId?: string;
  value?: number;
};
export type CompanyRequest = {
  id?: string | null;
  companyName?: string | null;
  keyOperations?: string | null;
  sectorId: string;
  operatingCountries?: string | null;
  registeredCountry?: string | null;
  registrationNumber?: string | null;
  isin?: string | null;
  financialYearStart?: MonthEnum;
  stockExchange?: string | null;
  ticker?: string | null;
  legalEntityIdentifier?: string | null;
  groupCompanySecretaryId?: string;
  companyLocations?: CompanyLocationRequest[] | null;
  relatedCompanyParents?: CompanyRelationshipParentRequest[] | null;
  relatedCompanyChildren?: CompanyRelationshipChildRequest[] | null;
};
export type CompanyRelationshipTypeResponse = {
  id?: string;
  relationshipName?: string | null;
  requiresValue?: boolean;
};
export type EnergyUnit =
  | 'EnergyUnit.Undefined'
  | 'EnergyUnit.BritishThermalUnit'
  | 'EnergyUnit.Calorie'
  | 'EnergyUnit.DecathermEc'
  | 'EnergyUnit.DecathermImperial'
  | 'EnergyUnit.DecathermUs'
  | 'EnergyUnit.ElectronVolt'
  | 'EnergyUnit.Erg'
  | 'EnergyUnit.FootPound'
  | 'EnergyUnit.GigabritishThermalUnit'
  | 'EnergyUnit.GigaelectronVolt'
  | 'EnergyUnit.Gigajoule'
  | 'EnergyUnit.GigawattDay'
  | 'EnergyUnit.GigawattHour'
  | 'EnergyUnit.HorsepowerHour'
  | 'EnergyUnit.Joule'
  | 'EnergyUnit.KilobritishThermalUnit'
  | 'EnergyUnit.Kilocalorie'
  | 'EnergyUnit.KiloelectronVolt'
  | 'EnergyUnit.Kilojoule'
  | 'EnergyUnit.KilowattDay'
  | 'EnergyUnit.KilowattHour'
  | 'EnergyUnit.MegabritishThermalUnit'
  | 'EnergyUnit.Megacalorie'
  | 'EnergyUnit.MegaelectronVolt'
  | 'EnergyUnit.Megajoule'
  | 'EnergyUnit.MegawattDay'
  | 'EnergyUnit.MegawattHour'
  | 'EnergyUnit.Millijoule'
  | 'EnergyUnit.TeraelectronVolt'
  | 'EnergyUnit.TerawattDay'
  | 'EnergyUnit.TerawattHour'
  | 'EnergyUnit.ThermEc'
  | 'EnergyUnit.ThermImperial'
  | 'EnergyUnit.ThermUs'
  | 'EnergyUnit.WattDay'
  | 'EnergyUnit.WattHour';
export type MassUnit =
  | 'MassUnit.Undefined'
  | 'MassUnit.Centigram'
  | 'MassUnit.Decagram'
  | 'MassUnit.Decigram'
  | 'MassUnit.EarthMass'
  | 'MassUnit.Grain'
  | 'MassUnit.Gram'
  | 'MassUnit.Hectogram'
  | 'MassUnit.Kilogram'
  | 'MassUnit.Kilopound'
  | 'MassUnit.Kilotonne'
  | 'MassUnit.LongHundredweight'
  | 'MassUnit.LongTon'
  | 'MassUnit.Megapound'
  | 'MassUnit.Megatonne'
  | 'MassUnit.Microgram'
  | 'MassUnit.Milligram'
  | 'MassUnit.Nanogram'
  | 'MassUnit.Ounce'
  | 'MassUnit.Pound'
  | 'MassUnit.ShortHundredweight'
  | 'MassUnit.ShortTon'
  | 'MassUnit.Slug'
  | 'MassUnit.SolarMass'
  | 'MassUnit.Stone'
  | 'MassUnit.Tonne';
export type VolumeUnit =
  | 'VolumeUnit.Undefined'
  | 'VolumeUnit.AcreFoot'
  | 'VolumeUnit.AuTablespoon'
  | 'VolumeUnit.BoardFoot'
  | 'VolumeUnit.Centiliter'
  | 'VolumeUnit.CubicCentimeter'
  | 'VolumeUnit.CubicDecimeter'
  | 'VolumeUnit.CubicFoot'
  | 'VolumeUnit.CubicHectometer'
  | 'VolumeUnit.CubicInch'
  | 'VolumeUnit.CubicKilometer'
  | 'VolumeUnit.CubicMeter'
  | 'VolumeUnit.CubicMicrometer'
  | 'VolumeUnit.CubicMile'
  | 'VolumeUnit.CubicMillimeter'
  | 'VolumeUnit.CubicYard'
  | 'VolumeUnit.Decaliter'
  | 'VolumeUnit.DecausGallon'
  | 'VolumeUnit.Deciliter'
  | 'VolumeUnit.DeciusGallon'
  | 'VolumeUnit.HectocubicFoot'
  | 'VolumeUnit.HectocubicMeter'
  | 'VolumeUnit.Hectoliter'
  | 'VolumeUnit.HectousGallon'
  | 'VolumeUnit.ImperialBeerBarrel'
  | 'VolumeUnit.ImperialGallon'
  | 'VolumeUnit.ImperialOunce'
  | 'VolumeUnit.ImperialPint'
  | 'VolumeUnit.KilocubicFoot'
  | 'VolumeUnit.KilocubicMeter'
  | 'VolumeUnit.KiloimperialGallon'
  | 'VolumeUnit.Kiloliter'
  | 'VolumeUnit.KilousGallon'
  | 'VolumeUnit.Liter'
  | 'VolumeUnit.MegacubicFoot'
  | 'VolumeUnit.MegaimperialGallon'
  | 'VolumeUnit.Megaliter'
  | 'VolumeUnit.MegausGallon'
  | 'VolumeUnit.MetricCup'
  | 'VolumeUnit.MetricTeaspoon'
  | 'VolumeUnit.Microliter'
  | 'VolumeUnit.Milliliter'
  | 'VolumeUnit.OilBarrel'
  | 'VolumeUnit.UkTablespoon'
  | 'VolumeUnit.UsBeerBarrel'
  | 'VolumeUnit.UsCustomaryCup'
  | 'VolumeUnit.UsGallon'
  | 'VolumeUnit.UsLegalCup'
  | 'VolumeUnit.UsOunce'
  | 'VolumeUnit.UsPint'
  | 'VolumeUnit.UsQuart'
  | 'VolumeUnit.UsTablespoon'
  | 'VolumeUnit.UsTeaspoon';
export type LengthUnit =
  | 'LengthUnit.Undefined'
  | 'LengthUnit.Angstrom'
  | 'LengthUnit.AstronomicalUnit'
  | 'LengthUnit.Centimeter'
  | 'LengthUnit.Chain'
  | 'LengthUnit.DataMile'
  | 'LengthUnit.Decameter'
  | 'LengthUnit.Decimeter'
  | 'LengthUnit.DtpPica'
  | 'LengthUnit.DtpPoint'
  | 'LengthUnit.Fathom'
  | 'LengthUnit.Foot'
  | 'LengthUnit.Hand'
  | 'LengthUnit.Hectometer'
  | 'LengthUnit.Inch'
  | 'LengthUnit.KilolightYear'
  | 'LengthUnit.Kilometer'
  | 'LengthUnit.Kiloparsec'
  | 'LengthUnit.LightYear'
  | 'LengthUnit.MegalightYear'
  | 'LengthUnit.Megaparsec'
  | 'LengthUnit.Meter'
  | 'LengthUnit.Microinch'
  | 'LengthUnit.Micrometer'
  | 'LengthUnit.Mil'
  | 'LengthUnit.Mile'
  | 'LengthUnit.Millimeter'
  | 'LengthUnit.Nanometer'
  | 'LengthUnit.NauticalMile'
  | 'LengthUnit.Parsec'
  | 'LengthUnit.PrinterPica'
  | 'LengthUnit.PrinterPoint'
  | 'LengthUnit.Shackle'
  | 'LengthUnit.SolarRadius'
  | 'LengthUnit.Twip'
  | 'LengthUnit.UsSurveyFoot'
  | 'LengthUnit.Yard';
export type AreaUnit =
  | 'AreaUnit.Undefined'
  | 'AreaUnit.Acre'
  | 'AreaUnit.Hectare'
  | 'AreaUnit.SquareCentimeter'
  | 'AreaUnit.SquareDecimeter'
  | 'AreaUnit.SquareFoot'
  | 'AreaUnit.SquareInch'
  | 'AreaUnit.SquareKilometer'
  | 'AreaUnit.SquareMeter'
  | 'AreaUnit.SquareMicrometer'
  | 'AreaUnit.SquareMile'
  | 'AreaUnit.SquareMillimeter'
  | 'AreaUnit.SquareNauticalMile'
  | 'AreaUnit.SquareYard'
  | 'AreaUnit.UsSurveySquareFoot';
export type ScalarUnit = 'ScalarUnit.Undefined' | 'ScalarUnit.Amount';
export type HybridUnitsEnum =
  | 'HybridUnitsEnum.Unknown'
  | 'HybridUnitsEnum.TonneKilometer'
  | 'HybridUnitsEnum.PassengerKilometer';
export type CustomScalarUnitsEnum =
  | 'CustomScalarUnitsEnum.Unknown'
  | 'CustomScalarUnitsEnum.Person'
  | 'CustomScalarUnitsEnum.Ream'
  | 'CustomScalarUnitsEnum.Each'
  | 'CustomScalarUnitsEnum.Hour'
  | 'CustomScalarUnitsEnum.Rands'
  | 'CustomScalarUnitsEnum.Percent';
export type AnyUnitEnum =
  | EnergyUnit
  | MassUnit
  | VolumeUnit
  | LengthUnit
  | AreaUnit
  | ScalarUnit
  | HybridUnitsEnum
  | CustomScalarUnitsEnum;
export type IQuantity = {
  unit?: AnyUnitEnum;
  value?: number;
};
export type CustomMetricResponse = {
  id: string;
  metricTypeId: string;
  dateRange?: DateRange;
  quantity?: IQuantity;
  lastTransition?: TransitionResponse;
  documentIds?: string[] | null;
  locationId: string;
  captureById: string;
  captureTime: string;
};
export type ModuleEnum =
  | 'Unknown'
  | 'Energy'
  | 'NetZero'
  | 'Water'
  | 'Waste'
  | 'HealthAndSafety'
  | 'Communities'
  | 'Biodiversity'
  | 'Economic'
  | 'History'
  | 'ValueChain'
  | 'Employment'
  | 'OrganisationalProfile'
  | 'CarbonTax';
export type QuantityDto = {
  unit: AnyUnitEnum;
  value?: number;
};
export type CustomMetricRequest = {
  id?: string | null;
  metricTypeId?: string;
  dateRange?: DateRange;
  quantity?: QuantityDto;
  documentIds?: string[] | null;
  locationId?: string;
};
export type NormalizedCustomMetricResponse = {
  metricTypeId: string;
  quantity: IQuantity;
  reportingPeriodId: string;
  locationId: string;
};
export type CustomMetricTypeResponse = {
  id?: string;
  name?: string | null;
  isIntensity?: boolean;
  quantityUnit?: AnyUnitEnum;
  module?: ModuleEnum;
};
export type CustomMetricInputTemplateResponse = {
  metricTypeId?: string;
  quantityUnit?: AnyUnitEnum;
};
export type GuidSingleValueResponse = {
  value?: string | null;
  businessErrors?: string[] | null;
  isSuccess?: boolean;
};
export type GraphTypeEnum =
  | 'BarGraph'
  | 'PieGraph'
  | 'Organogram'
  | 'TableGraph'
  | 'AreaGraph';
export type WidgetDto = {
  id: string;
  type: GraphTypeEnum;
  metric: string;
  splitBy: string;
  legend?: string | null;
  intensity?: string | null;
  filter?: string | null;
  showLegends: boolean;
};
export type DashboardResponse = {
  id?: string;
  dashboardType?: string | null;
  widgets?: WidgetDto[] | null;
  layouts?: string | null;
  showLegends?: string | null;
};
export type DashboardRequest = {
  id?: string | null;
  dashboardType?: string | null;
  widgets?: WidgetDto[] | null;
  layouts?: string | null;
  showLegends?: string | null;
};
export type DateFrequency =
  | 'Unknown'
  | 'Hourly'
  | 'Daily'
  | 'Weekly'
  | 'Monthly'
  | 'Quarterly'
  | 'BiAnnual'
  | 'Annual';
export type StandardEnum = 'Unknown' | 'GRI' | 'CDP' | 'SASB' | 'SDG' | 'TCFD';
export type DisclosuresRequest = {
  id?: string;
  code?: string | null;
  kpi?: string | null;
  standard?: StandardEnum;
  parentID?: string | null;
  description?: string | null;
};
export type DataTypeEnum =
  | 'Unknown'
  | 'Group'
  | 'BooleanGroup'
  | 'InvertedBooleanGroup'
  | 'SystemGenerated'
  | 'String'
  | 'Number'
  | 'NumberWithUnit'
  | 'Boolean'
  | 'Date'
  | 'Percentage'
  | 'Enum'
  | 'List_String'
  | 'List_Number'
  | 'List_NumberWithUnit'
  | 'List_Date'
  | 'List_Percentage';
export type DisclosureRelationship = 'Full' | 'Partial' | 'Reference';
export type DisclosureGoalResponse = {
  standardId?: StandardEnum;
  childId?: string;
  relationship?: DisclosureRelationship;
};
export type DisclosureResponse = {
  id: string;
  code: string;
  kpi?: string | null;
  standardVersionName?: string | null;
  answerType?: DataTypeEnum;
  description: string;
  alternateText?: string | null;
  guidance?: string | null;
  requirements?: string | null;
  indexingValue?: string | null;
  children?: DisclosureResponse[] | null;
  goals?: DisclosureGoalResponse[] | null;
};
export type UnitTypeEnum =
  | 'Unknown'
  | 'Energy'
  | 'Mass'
  | 'Volume'
  | 'Length'
  | 'Hybrid'
  | 'Area'
  | 'Scalar'
  | 'CustomScalar';
export type DisclosureAnswerEntry = {
  id?: string | null;
  unitType?: UnitTypeEnum;
  unit?: string | null;
  resolver?: string | null;
  value?: string | null;
  indexingValue?: string | null;
  resolverInputs?: {
    [key: string]: string | null;
  } | null;
};
export type PatchDisclosureAnswersDto = {
  businessUnitId?: string | null;
  reportingPeriodId?: string;
  answers?: {
    [key: string]: DisclosureAnswerEntry[] | null;
  } | null;
};
export type DisclosureCompletenessResponse = {
  answered?: number;
  full?: number;
  partial?: number;
  reference?: number;
};
export type DisclosureMapResponse = {
  parentId?: string;
  childId?: string;
};
export type DisclosureMapRequest = {
  parentId?: string;
  childId?: string;
  relationship?: DisclosureRelationship;
};
export type DisclosureIndexRequest = {
  parentId?: string;
  childId?: string;
};
export type ResolverInput = {
  name?: string | null;
  type?: DataTypeEnum;
  enumEntries?: string[] | null;
};
export type ResolverEntry = {
  fullyQualifiedName?: string | null;
  name?: string | null;
  inputs?: ResolverInput[] | null;
};
export type Energy = {
  unit?: EnergyUnit;
  value?: number;
};
export type Mass = {
  unit?: MassUnit;
  value?: number;
};
export type GasEmissionDto = {
  gasId: string;
  emissions: Mass;
};
export type EnergyDashboardDto = {
  id: string;
  reportingPeriodId: string;
  energy: Energy;
  configuredEmissionSourceId: string;
  locationId: string;
  emission: GasEmissionDto;
  cost: number;
  stateId: string;
  captureById: string;
  captureTime: string;
};
export type EmissionScopeEnum = 'Unknown' | 'Scope1' | 'Scope2' | 'Scope3';
export type EnergyDataGridDto = {
  id: string;
  dateRange: DateRange;
  description?: string | null;
  quantity: QuantityDto;
  configuredEmissionSourceId: string;
  lastTransition: TransitionResponse;
  locationId: string;
  emissions?: GasEmissionDto[] | null;
  cost?: number;
  hasAlerts?: boolean;
  captureById: string;
  captureTime: string;
};
export type EnergyDataGridDtoPaginationResponse = {
  count?: number;
  pageNumber?: number;
  pageSize?: number;
  results?: EnergyDataGridDto[] | null;
};
export type EmissionGasResponse = {
  id?: string | null;
  name?: string | null;
  chemicalFormula?: string | null;
  casNumber?: string | null;
  globalWarmingPotential?: number | null;
};
export type EmissionFactorProviderEnum =
  | 'Unknown'
  | 'IPCC'
  | 'EPA'
  | 'Defra'
  | 'Econest';
export type UnitTypeEnumUnitTypeEnumTuple = {
  item1?: UnitTypeEnum;
  item2?: UnitTypeEnum;
};
export type EmissionSourceGasResponse = {
  id?: string;
  emissionGasId?: string;
  effectiveFrom?: DateDto;
  fromUnitId?: AnyUnitEnum;
  toUnitId: MassUnit;
  emissionFactor?: number;
  description?: string | null;
};
export type EmissionFactorCategoryDto = {
  id?: string;
  parentId?: string | null;
  name?: string | null;
  code?: string | null;
  path?: string | null;
  pathEmissionFactorCategories?: EmissionFactorCategoryDto[] | null;
};
export type CalorificValueDto = {
  id?: string;
  fromUnitId: AnyUnitEnum;
  toUnitId: EnergyUnit;
  value: number;
  countryName: string;
  dataSource?: string | null;
  assumption?: string | null;
};
export type EmissionSourceResponse = {
  id: string;
  name: string;
  configuredEmissionSourceDisplayName?: string | null;
  configuredEmissionSourceId?: string | null;
  scope?: EmissionScopeEnum;
  emissionFactorCategoryId?: string | null;
  emissionFactorProviderId?: EmissionFactorProviderEnum;
  unitTypes?: UnitTypeEnum[] | null;
  hybridOptions?: UnitTypeEnumUnitTypeEnumTuple[] | null;
  emissionSourceGases?: EmissionSourceGasResponse[] | null;
  emissionFactorCategoryDto?: EmissionFactorCategoryDto;
  calorificValues?: CalorificValueDto[] | null;
  scope3CategoryIds?: string[] | null;
};
export type EmissionSourceCategoryEnum =
  | 'Unknown'
  | 'FossilFuels'
  | 'PetrolAndDiesel'
  | 'Fugitive'
  | 'Process';
export type ConfiguredEmissionSourceRequest = {
  id?: string | null;
  displayName?: string | null;
  sourceCategory?: EmissionSourceCategoryEnum;
};
export type ConfiguredEmissionSourceDashboardResponse = {
  id: string;
  displayName?: string | null;
  scope?: EmissionScopeEnum;
  sourcecategory?: EmissionSourceCategoryEnum;
  unitTypes?: UnitTypeEnum[] | null;
};
export type CalorificValueResponse = {
  id?: string;
  fromId?: AnyUnitEnum;
  toId?: EnergyUnit;
  value?: number;
  countryName?: string | null;
  quantity?: IQuantity;
};
export type ConfiguredEmissionSourceResponse = {
  id?: string;
  displayName?: string | null;
  scope?: EmissionScopeEnum;
  sourcecategory?: EmissionSourceCategoryEnum;
  emissionSourceNames: string[];
  scope3CategoryIds: string[];
  unitTypes?: UnitTypeEnum[] | null;
  calorificValues?: CalorificValueResponse[] | null;
};
export type EmissionSourceGasRequest = {
  id?: string | null;
  description?: string | null;
  toUnitId?: MassUnit;
  fromUnitId?: AnyUnitEnum;
  emissionGasId?: string;
  emissionFactor?: number;
  effectiveFrom?: DateDto;
};
export type EmissionSourceRequest = {
  id?: string | null;
  name?: string | null;
  emissionFactorProviderId?: EmissionFactorProviderEnum;
  emissionFactorCategoryId?: string;
  scope?: EmissionScopeEnum;
  emissionSourceGases?: EmissionSourceGasRequest[] | null;
  calorificValues?: CalorificValueDto[] | null;
};
export type EmployeeAuditResponse = {
  id?: string;
  locationId: string;
  reportingPeriodId: string;
  demographics: {
    Unknown?: {
      Unknown?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Male?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Female?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Other?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
    };
    Permanent?: {
      Unknown?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Male?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Female?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Other?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
    };
    Contractor?: {
      Unknown?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Male?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Female?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Other?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
    };
    Casual?: {
      Unknown?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Male?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Female?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Other?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
    };
  };
  lastTransition: TransitionResponse;
  documentIds?: string[] | null;
  captureById: string;
  captureTime: string;
};
export type EmployeeAuditRequest = {
  id?: string | null;
  locationId: string;
  reportingPeriodId: string;
  demographics: {
    Unknown?: {
      Unknown?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Male?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Female?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Other?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
    };
    Permanent?: {
      Unknown?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Male?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Female?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Other?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
    };
    Contractor?: {
      Unknown?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Male?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Female?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Other?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
    };
    Casual?: {
      Unknown?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Male?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Female?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
      Other?: {
        Unknown?: number;
        LessThanThirty?: number;
        BetweenThirtyAndFifty?: number;
        GreaterThanFifty?: number;
      };
    };
  };
  documentIds?: string[] | null;
};
export type EmploymentTypeEnum =
  | 'Unknown'
  | 'Permanent'
  | 'Contractor'
  | 'Casual';
export type AgeGroupEnum =
  | 'Unknown'
  | 'LessThanThirty'
  | 'BetweenThirtyAndFifty'
  | 'GreaterThanFifty';
export type EmployeeResponse = {
  gender?: GenderEnum;
  employmentType?: EmploymentTypeEnum;
  age?: AgeGroupEnum;
};
export type EmployeeAuditDashboardResponse = {
  id?: string;
  locationId: string;
  reportingPeriodId: string;
  employees: EmployeeResponse[];
};
export type EmployeeAuditResponsePaginationResponse = {
  count?: number;
  pageNumber?: number;
  pageSize?: number;
  results?: EmployeeAuditResponse[] | null;
};
export type CalorificOverrideDto = {
  value?: number;
  fromUnit?: AnyUnitEnum;
  toUnit?: EnergyUnit;
};
export type EnergyInputTemplateResponse = {
  description?: string | null;
  configuredEmissionSourceId: string;
  unitType?: UnitTypeEnum;
  calorificOverride?: CalorificOverrideDto;
  quantity: IQuantity;
  secondaryQuantity?: IQuantity;
};
export type EnergyResponse = {
  id: string;
  dateRange: DateRange;
  description?: string | null;
  quantity: QuantityDto;
  configuredEmissionSourceId: string;
  documentIds?: string[] | null;
  cost?: number;
  locationId: string;
  lastTransition: TransitionResponse;
  alerts?: AlertResponse[] | null;
  calorificOverride?: CalorificOverrideDto;
};
export type EnergyRequest = {
  id?: string | null;
  dateRange?: DateRange;
  description?: string | null;
  quantity?: QuantityDto;
  secondaryQuantity?: QuantityDto;
  configuredEmissionSourceId?: string;
  documentIds?: string[] | null;
  locationId?: string;
  cost?: number;
  calorificOverride?: CalorificOverrideDto;
};
export type EnergyMetricEnum = 'Electricity' | 'Heating' | 'Cooling' | 'Steam';
export type EnergyUnitSearchDto = {
  id?: EnergyUnit;
  name?: string | null;
  abbreviation?: string | null;
  isEnabled?: boolean;
  isSelectable?: boolean | null;
};
export type EnergyMetricSearchDto = {
  id: EnergyMetricEnum;
  name: string;
  units?: EnergyUnitSearchDto[] | null;
};
export type EnergyUnitDto = {
  id?: EnergyUnit;
  isSelectable?: boolean;
};
export type ExcelTemplateMappingResponse = {
  id?: string | null;
  fieldName?: string | null;
  columnName?: string | null;
  defaultValue?: string | null;
};
export type ExcelTemplateResponse = {
  id?: string;
  templateName?: string | null;
  entityType?: string | null;
  mappings?: ExcelTemplateMappingResponse[] | null;
  captureById: string;
  captureTime: string;
};
export type ExcelTemplateMappingRequest = {
  id?: string | null;
  fieldName?: string | null;
  columnName?: string | null;
  defaultValue?: string | null;
};
export type ExcelTemplateRequest = {
  id?: string | null;
  templateName?: string | null;
  entityType?: string | null;
  mappings?: ExcelTemplateMappingRequest[] | null;
};
export type ExcelTemplateExecutionResponse = {
  id?: string;
  excelTemplate?: ExcelTemplateResponse;
  documentId?: string;
  captureById: string;
  captureTime: string;
};
export type StakeholderFeedbackSolicitationResponse = {
  id?: string;
  companyName?: string | null;
  personName?: string | null;
};
export type InternalFeedbackSolicitationResponse = {
  applicationUserId?: string | null;
};
export type FundingApplicationRequest = {
  name?: string | null;
  sectorId?: string;
  value?: number;
  email?: string | null;
  description?: string | null;
  documentIds?: string[] | null;
};
export type FundingApplicationSearchResponse = {
  id: string;
  name: string;
  sectorId: string;
  value?: number;
  description?: string | null;
  lastTransition?: TransitionResponse;
  documentIds?: string[] | null;
  captureById: string;
  captureTime: string;
};
export type DocumentResponse = {
  id?: string;
  documentTypeId: string;
  fileName?: string | null;
  typeName?: string | null;
  isLocked?: boolean;
  captureById: string;
  captureTime: string;
};
export type FundingApplicationOcrResponse = {
  fundingApplication?: FundingApplicationRequest;
  documentResponse?: DocumentResponse;
};
export type FundingApplicationGroupingEnum = 'Sector' | 'RootSector' | 'State';
export type FundingApplicationResponse = {
  id: string;
  name: string;
  sectorId: string;
  value?: number;
  email: string;
  description?: string | null;
  lastTransition?: TransitionResponse;
  documentIds?: string[] | null;
};
export type ImpactAssessmentTypeEnum = 'Unknown' | 'Social' | 'Environmental';
export type ImpactAssessmentRequest = {
  id?: string | null;
  date?: DateDto;
  locationId?: string;
  impactAssessmentType?: ImpactAssessmentTypeEnum;
  isPubliclyDisclosed?: boolean;
  lastTransition?: TransitionResponse;
  documentIds?: string[] | null;
};
export type ImpactAssessmentResponse = {
  id?: string;
  date?: DateDto;
  locationId?: string;
  impactAssessmentType?: ImpactAssessmentTypeEnum;
  isPubliclyDisclosed?: boolean;
  lastTransition?: TransitionResponse;
  documentIds?: string[] | null;
  captureById: string;
  captureTime: string;
};
export type ImpactAssessmentResponsePaginationResponse = {
  count?: number;
  pageNumber?: number;
  pageSize?: number;
  results?: ImpactAssessmentResponse[] | null;
};
export type GriIncidentCategorizationEnum =
  | 'Undetermined'
  | 'None'
  | 'FirstAidIncident'
  | 'CloseCallOrDangerousOccurrence'
  | 'MedicalTreatmentCase'
  | 'RestrictedWorkCase'
  | 'LostTimeInjury'
  | 'Fatality';
export type HealthAndSafetyIncidentRequest = {
  date?: DateDto;
  isHealth?: boolean;
  isEmployee?: boolean;
  griCategory?: GriIncidentCategorizationEnum;
  id?: string | null;
  locationId?: string;
  description?: string | null;
  externalTrackingId?: string | null;
  documentIds?: string[] | null;
};
export type HealthAndSafetyIncidentResponse = {
  date: DateDto;
  isHealth: boolean;
  isEmployee: boolean;
  griCategory: GriIncidentCategorizationEnum;
  id?: string;
  locationId?: string;
  description?: string | null;
  externalTrackingId?: string | null;
  documentIds?: string[] | null;
  lastTransition: TransitionResponse;
  captureById: string;
  captureTime: string;
};
export type HealthAndSafetyIncidentResponsePaginationResponse = {
  count?: number;
  pageNumber?: number;
  pageSize?: number;
  results?: HealthAndSafetyIncidentResponse[] | null;
};
export type HealthAndSafetyIncidentDashboardDto = {
  id: string;
  locationId: string;
  date: DateDto;
  reportingPeriodId: string;
  isHealth: boolean;
  isEmployee: boolean;
  griCategory: GriIncidentCategorizationEnum;
};
export type IndicatorResponse = {
  id?: string;
  indicatorText?: string | null;
  description?: string | null;
  answerType?: DataTypeEnum;
};
export type EnergyOcrResponse = {
  energyRequest?: EnergyRequest;
  documentResponse?: DocumentResponse;
};
export type Scope3CategoryResponse = {
  id?: string;
  name?: string | null;
  description?: string | null;
  outSourcingStartDate?: DateOnly;
  order?: number;
  isHighRevenue: boolean;
  averageSize?: number;
  averageInfluence?: number;
  averageRisk?: number;
  averageImportance?: number;
  reportingOverrideReason?: string | null;
};
export type InternalFeedbackResponse = {
  scope3CategoryId?: string;
  internalFeedbackSolicitationId?: string;
  size: number;
  influence: number;
  risk: number;
  scope3Category?: Scope3CategoryResponse;
  internalFeedbackSolicitation?: InternalFeedbackSolicitationResponse;
};
export type Scope3CategoryRequest = {
  id?: string;
  name?: string | null;
  description?: string | null;
  oustSourcingStartDate?: DateOnly;
  order?: number;
  isHighRevenue: boolean;
  reportingOverrideReason?: string | null;
};
export type InternalFeedbackSolicitationRequest = {
  applicationUserId?: string | null;
};
export type InternalFeedbackRequest = {
  scope3CategoryId?: string;
  internalFeedbackSolicitationId?: string;
  size: number;
  influence: number;
  risk: number;
  scope3Category?: Scope3CategoryRequest;
  internalFeedbackSolicitation?: InternalFeedbackSolicitationRequest;
};
export type LearnershipResponse = {
  cost?: number;
  summary?: string | null;
  participants?: BusinessPartnerResponse[] | null;
  id?: string | null;
  name?: string | null;
  dateRange?: DateRange;
  discriminator?: string | null;
  participant?: string;
  lastTransition?: TransitionResponse;
};
export type LearnershipRequest = {
  cost?: number;
  summary?: string | null;
  participants?: BusinessPartnerRequest[] | null;
  id?: string | null;
  name?: string | null;
  dateRange?: DateRange;
  documentIds?: string[] | null;
};
export type PointDto = {
  longitude: number;
  latitude: number;
  spatialReferenceId: number;
};
export type LocationsRequest = {
  id?: string | null;
  type?: string | null;
  name?: string | null;
  streetNumber?: number | null;
  shortName?: string | null;
  longName?: string | null;
  suburb?: string | null;
  city?: string | null;
  state?: string | null;
  postCode?: string | null;
  country?: string | null;
  description?: string | null;
  point?: PointDto;
  formattedAddress: string;
  businessUnitId?: string | null;
};
export type LockedPeriodDto = {
  id?: string | null;
  locationId?: string;
  reportingPeriodId?: string;
};
export type LocationsResponse = {
  id: string;
  type?: string | null;
  name?: string | null;
  streetNumber?: number | null;
  shortName?: string | null;
  longName?: string | null;
  suburb?: string | null;
  city?: string | null;
  state?: string | null;
  postCode?: string | null;
  country?: string | null;
  description?: string | null;
  point?: PointDto;
  formattedAddress?: string | null;
  businessUnitId?: string | null;
  lockedPeriods: LockedPeriodDto[];
};
export type LockPeriodRequest = {
  lockedPeriod?: LockedPeriodDto;
  excludedEmissionSourceIds?: string[] | null;
};
export type MappedEntityResponse = {
  id?: string | null;
  entityType?: string | null;
  entityName?: string | null;
};
export type PropertyMappingResponse = {
  field: string;
  requiredField: boolean;
};
export type NetZeroProjectApplicationRequest = {
  name?: string | null;
  netZeroProjectTypeId?: string;
  description?: string | null;
  locationId?: string;
  frequency?: DateFrequency;
  reductionDescription?: string | null;
  strengths?: string | null;
  weaknesses?: string | null;
  opportunities?: string | null;
  threats?: string | null;
  dateRange?: DateRange;
  documentIds?: string[] | null;
  projectAdminId?: string;
};
export type NetZeroProjectTypeEnum =
  | 'Unknown'
  | 'EmissionReductions'
  | 'AlternativeEnergy'
  | 'CarbonOffsets'
  | 'CarbonRemoval'
  | 'CarbonSequestration';
export type NetZeroProjectTypeResponse = {
  id?: string;
  name?: string | null;
  description?: string | null;
  unitId?: string | null;
  configuredEmissionSourceId?: string | null;
  isEmission?: boolean;
  unitType?: UnitTypeEnum;
  projectType?: NetZeroProjectTypeEnum;
};
export type NetZeroProjectIndicatorResponse = {
  id?: string;
  netZeroProjectId?: string;
  unitType?: UnitTypeEnum;
  quantity?: IQuantity;
  dateRange?: DateRange;
  emissionsSaved?: IQuantity;
  lastTransition?: TransitionResponse;
  documentIds?: string[] | null;
};
export type NetZeroProjectResponse = {
  id?: string;
  netZeroProjectApplicationId?: string;
  application?: NetZeroProjectApplicationResponse;
  metrics?: NetZeroProjectIndicatorResponse[] | null;
  lastTransition?: TransitionResponse;
  documentIds?: string[] | null;
};
export type NetZeroProjectApplicationResponse = {
  id?: string;
  name?: string | null;
  netZeroProjectTypeId?: string;
  netZeroProjectType?: NetZeroProjectTypeResponse;
  description?: string | null;
  locationId?: string;
  strengths?: string | null;
  weaknesses?: string | null;
  opportunities?: string | null;
  threats?: string | null;
  frequency?: DateFrequency;
  reductionDescription?: string | null;
  dateRange?: DateRange;
  lastTransition?: TransitionResponse;
  documentIds?: string[] | null;
  unitType?: UnitTypeEnum;
  project?: NetZeroProjectResponse;
  projectAdminId?: string;
  captureById: string;
  captureTime: string;
};
export type NormalizedNetZeroIndicatorResponse = {
  netZeroProjectIndicatorId?: string;
  reportingPeriodId?: string;
  locationId?: string;
  netZeroProjectApplicationId?: string;
  netZeroProjectId?: string;
  netZeroProjectTypeId?: string;
  configuredEmissionSourceId?: string;
  projectApplicationStateId?: string;
  projectStateId?: string;
  projectIndicatorStateId?: string;
  projectName?: string | null;
  projectTypeName?: string | null;
  unitType?: UnitTypeEnum;
  quantity?: IQuantity;
  emissionsSaved?: IQuantity;
};
export type NetZeroProjectIndicatorRequest = {
  id?: string | null;
  netZeroProjectId?: string;
  quantity?: IQuantity;
  dateRange?: DateRange;
  documentIds?: string[] | null;
};
export type NetZeroProjectRequest = {
  id?: string | null;
  netZeroProjectApplicationId?: string;
  metrics?: NetZeroProjectIndicatorRequest[] | null;
  documentIds?: string[] | null;
};
export type NetZeroProjectTypeRequest = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  unitId?: string | null;
  configuredEmissionSourceId?: string | null;
  isEmission?: boolean;
  projectType?: NetZeroProjectTypeEnum;
};
export type NotificationTypeEnum = 'General' | 'Alert';
export type UserNotificationResponse = {
  id?: string;
  isRead?: boolean;
  notificationId?: string;
  notificationText: string;
  notificationEntityId?: string;
  notificationEntityType?: string | null;
  notificationCaptureTime?: string;
  notificationType?: NotificationTypeEnum;
  applicationUserId?: string | null;
};
export type UserNotificationRequest = {
  id?: string | null;
  isRead?: boolean;
  notificationId?: string;
  applicationUserId?: string | null;
};
export type Area = {
  unit?: AreaUnit;
  value?: number;
};
export type OperationRequest = {
  id?: string | null;
  locationId?: string;
  name?: string | null;
  operationType?: OperationTypeEnum;
  area?: Area;
};
export type OperationResponse = {
  id?: string;
  name?: string | null;
  operationType?: OperationTypeEnum;
  area?: Area;
  locationId?: string;
  captureTime?: string;
  captureById?: string;
};
export type FundingApplicationProjectResponse = {
  id?: string;
  name?: string | null;
  sectorId?: string;
  value?: number;
  email?: string | null;
  description?: string | null;
};
export type ProjectResponse = {
  id?: string;
  fundingApplicationId?: string;
  fundingApplication?: FundingApplicationProjectResponse;
  name?: string | null;
  valueGranted?: number;
  indicators?: IndicatorResponse[] | null;
};
export type ProjectPaymentResponse = {
  id?: string;
  projectId?: string;
  paymentDate?: DateDto;
  paymentAmount?: number;
};
export type ProjectPaymentBulkEntry = {
  paymentDate?: DateDto;
  paymentAmount?: number;
};
export type ProjectPaymentsBulkRequest = {
  projectId?: string;
  paymentFrequency?: DateFrequency;
  payments?: ProjectPaymentBulkEntry[] | null;
};
export type ProjectPaymentRequest = {
  id?: string | null;
  projectId?: string;
  paymentDate?: DateDto;
  paymentAmount?: number;
};
export type ProjectReportResponse = {
  id?: string;
  projectId?: string;
  dateRequested?: DateDto;
  dateCompleted?: DateDto;
};
export type ProjectIndicatorResponse = {
  projectId?: string;
  indicatorId?: string;
  frequency?: DateFrequency;
};
export type ProjectIndicatorBulkEntry = {
  indicatorId?: string;
  frequency?: DateFrequency;
};
export type ProjectIndicatorAnswerUpsertReponse = {
  projectReportId?: string;
  indicatorId?: string;
  projectId?: string;
};
export type ProjectIndicatorAnswerResponse = {
  projectReportId?: string;
  indicatorId?: string | null;
  projectId?: string;
  indicator?: IndicatorResponse;
  value?: string | null;
};
export type ReportingPeriodResponse = {
  id: string;
  parentId?: string;
  name: string;
  periodType: DateFrequency;
  startDate?: DateOnly;
  endDate?: DateOnly;
  lockedLocations?: LockedPeriodDto[] | null;
};
export type ReportingPeriodRequest = {
  id?: string | null;
  name?: string | null;
  periodType?: DateFrequency;
  startDate?: DateOnly;
  endDate?: DateOnly;
  parentId?: string | null;
};
export type RoleCategoryTypeEnum =
  | 'Top'
  | 'Senior'
  | 'Middle'
  | 'Junior'
  | 'SemiSkilled'
  | 'Unskilled';
export type TurnoverTypeEnum =
  | 'Resignation'
  | 'Dismissal'
  | 'ContractEnd'
  | 'Promotion'
  | 'Retrenchment';
export type RoleResponse = {
  id?: string;
  businessUnitId?: string | null;
  personId?: string;
  person?: BusinessPartnerResponse;
  employmentType?: EmploymentTypeEnum;
  category?: RoleCategoryTypeEnum;
  dateRange?: DateRange;
  turnoverType?: TurnoverTypeEnum;
  turnoverReason?: string | null;
  parentalLeave?: boolean;
  unionBargaining?: boolean;
  captureByName?: string | null;
  captureTime?: string;
};
export type RoleRequest = {
  id?: string | null;
  businessUnitId?: string | null;
  personId?: string;
  employmentType?: EmploymentTypeEnum;
  category?: RoleCategoryTypeEnum;
  dateRange?: DateRange;
  turnoverType?: TurnoverTypeEnum;
  turnoverReason?: string | null;
  parentalLeave?: boolean;
  unionBargaining?: boolean;
};
export type Scope3EnergyRequest = {
  id?: string | null;
  dateRange?: DateRange;
  description?: string | null;
  quantity?: QuantityDto;
  secondaryQuantity?: QuantityDto;
  configuredEmissionSourceId: string;
  documentIds?: string[] | null;
  locationId?: string;
  scope3CategoryId?: string;
};
export type Scope3EnergyResponse = {
  id: string;
  dateRange: DateRange;
  description?: string | null;
  quantity: QuantityDto;
  secondaryQuantity: QuantityDto;
  configuredEmissionSourceId: string;
  documentIds?: string[] | null;
  locationId: string;
  lastTransition: TransitionResponse;
  alerts?: AlertResponse[] | null;
  scope3CategoryId: string;
};
export type Scope3EnergyInputTemplateResponse = {
  scope3CategoryId: string;
  description?: string | null;
  configuredEmissionSourceId: string;
  unitType?: UnitTypeEnum;
  calorificOverride?: CalorificOverrideDto;
  quantity: IQuantity;
  secondaryQuantity?: IQuantity;
};
export type Scope3DashboardDto = {
  id: string;
  reportingPeriodId: string;
  energy: Energy;
  configuredEmissionSourceId: string;
  locationId: string;
  scope3CategoryId: string;
  emission: GasEmissionDto;
  stateId: string;
};
export type Scope3DataGridDto = {
  id: string;
  dateRange: DateRange;
  description?: string | null;
  quantity: QuantityDto;
  secondaryQuantity?: QuantityDto;
  scope3CategoryId: string;
  configuredEmissionSourceId: string;
  lastTransition: TransitionResponse;
  locationId: string;
  emissions?: GasEmissionDto[] | null;
  hasAlerts?: boolean;
  isWaste?: boolean;
  captureById: string;
  captureTime: string;
};
export type Scope3DataGridDtoPaginationResponse = {
  count?: number;
  pageNumber?: number;
  pageSize?: number;
  results?: Scope3DataGridDto[] | null;
};
export type SimpleEmissionResponse = {
  gasChemicalFormula?: string | null;
  gasName?: string | null;
  emission?: Mass;
};
export type SectorResponse = {
  id?: string;
  name?: string | null;
  path?: string | null;
  parentId?: string | null;
};
export type TemporalModuleFeatureFlags = {
  dataManagement?: boolean | null;
};
export type FeatureFlagSettings = {
  energyFlags?: TemporalModuleFeatureFlags;
  wasteFlags?: TemporalModuleFeatureFlags;
};
export type RequiredDocumentSignOffResponse = {
  id?: string | null;
  allowedTransitionFromStateId?: string;
  allowedTransitionToStateId?: string;
  documentTypeId: string;
  isRequired?: boolean;
};
export type AllowedTransitionResponse = {
  fromStateId: string;
  fromState?: StateResponse;
  toStateId: string;
  toState?: StateResponse;
  isInitial?: boolean;
  requiredDocumentSignOffs?: RequiredDocumentSignOffResponse[] | null;
};
export type StateResponse = {
  id: string;
  name: string;
  description?: string | null;
  isEditable: boolean;
  iconColor?: string | null;
  nextTransitions: AllowedTransitionResponse[];
  isInitial?: boolean;
  entityType?: string | null;
};
export type RequiredDocumentSignOffRequest = {
  id?: string | null;
  allowedTransitionFromStateId?: string;
  allowedTransitionToStateId?: string;
  documentTypeId?: string;
};
export type SocialIncidentCategoryResponse = {
  id: string;
  name: string;
  description?: string | null;
};
export type ReportingPeriod = {
  id?: string;
  name: string;
  periodType?: DateFrequency;
  periodTypeName?: string | null;
  startDate?: DateOnly;
  endDate?: DateOnly;
  parentId?: string | null;
  parent?: ReportingPeriod;
  children?: ReportingPeriod[] | null;
  lockedLocations?: LockedPeriod[] | null;
};
export type LockedPeriod = {
  locationId?: string;
  location?: Location;
  reportingPeriodId?: string;
  reportingPeriods?: ReportingPeriod;
  id: string;
  captureById: string;
  captureTime: string;
};
export type Ordinates =
  | 'None'
  | 'Spatial1'
  | 'Spatial2'
  | 'XY'
  | 'Z'
  | 'XYZ'
  | 'Spatial4'
  | 'Spatial5'
  | 'Spatial6'
  | 'Spatial7'
  | 'Spatial8'
  | 'Spatial9'
  | 'Spatial10'
  | 'Spatial11'
  | 'Spatial12'
  | 'Spatial13'
  | 'Spatial14'
  | 'Spatial15'
  | 'Spatial16'
  | 'AllSpatialOrdinates'
  | 'M'
  | 'XYM'
  | 'XYZM'
  | 'Measure2'
  | 'Measure3'
  | 'Measure4'
  | 'Measure5'
  | 'Measure6'
  | 'Measure7'
  | 'Measure8'
  | 'Measure9'
  | 'Measure10'
  | 'Measure11'
  | 'Measure12'
  | 'Measure13'
  | 'Measure14'
  | 'Measure15'
  | 'Measure16'
  | 'AllMeasureOrdinates'
  | 'AllOrdinates';
export type Coordinate = {
  x?: number;
  y?: number;
  z?: number;
  m?: number;
  coordinateValue?: Coordinate;
  isValid?: boolean;
};
export type CoordinateSequence = {
  dimension?: number;
  measures?: number;
  spatial?: number;
  ordinates?: Ordinates;
  hasZ?: boolean;
  hasM?: boolean;
  zOrdinateIndex?: number;
  mOrdinateIndex?: number;
  first?: Coordinate;
  last?: Coordinate;
  count?: number;
};
export type Dimension =
  | 'P'
  | 'Curve'
  | 'Surface'
  | 'Collapse'
  | 'Dontcare'
  | 'True'
  | 'Unknown';
export type OgcGeometryType =
  | 'Point'
  | 'LineString'
  | 'Polygon'
  | 'MultiPoint'
  | 'MultiLineString'
  | 'MultiPolygon'
  | 'GeometryCollection'
  | 'CircularString'
  | 'CompoundCurve'
  | 'CurvePolygon'
  | 'MultiCurve'
  | 'MultiSurface'
  | 'Curve'
  | 'Surface'
  | 'PolyhedralSurface'
  | 'TIN';
export type PrecisionModels = 'Floating' | 'FloatingSingle' | 'Fixed';
export type PrecisionModel = {
  isFloating?: boolean;
  maximumSignificantDigits?: number;
  scale?: number;
  precisionModelType?: PrecisionModels;
};
export type CoordinateSequenceFactory = {
  ordinates?: Ordinates;
};
export type GeometryOverlay = object;
export type CoordinateEqualityComparer = object;
export type NtsGeometryServices = {
  geometryOverlay?: GeometryOverlay;
  coordinateEqualityComparer?: CoordinateEqualityComparer;
  defaultSRID?: number;
  defaultCoordinateSequenceFactory?: CoordinateSequenceFactory;
  defaultPrecisionModel?: PrecisionModel;
};
export type GeometryFactory = {
  precisionModel?: PrecisionModel;
  coordinateSequenceFactory?: CoordinateSequenceFactory;
  srid?: number;
  geometryServices?: NtsGeometryServices;
};
export type Envelope = {
  isNull?: boolean;
  width?: number;
  height?: number;
  diameter?: number;
  minX?: number;
  maxX?: number;
  minY?: number;
  maxY?: number;
  area?: number;
  minExtent?: number;
  maxExtent?: number;
  centre?: Coordinate;
};
export type Geometry = {
  factory?: GeometryFactory;
  userData?: any | null;
  srid?: number;
  geometryType?: string | null;
  ogcGeometryType?: OgcGeometryType;
  precisionModel?: PrecisionModel;
  coordinate?: Coordinate;
  coordinates?: Coordinate[] | null;
  numPoints?: number;
  numGeometries?: number;
  isSimple?: boolean;
  isValid?: boolean;
  isEmpty?: boolean;
  area?: number;
  length?: number;
  centroid?: Point;
  interiorPoint?: Point;
  pointOnSurface?: Point;
  dimension?: Dimension;
  boundary?: Geometry;
  boundaryDimension?: Dimension;
  envelope?: Geometry;
  envelopeInternal?: Envelope;
  isRectangle?: boolean;
};
export type Point = {
  coordinateSequence?: CoordinateSequence;
  coordinates?: Coordinate[] | null;
  numPoints?: number;
  isEmpty?: boolean;
  dimension?: Dimension;
  boundaryDimension?: Dimension;
  x?: number;
  y?: number;
  coordinate?: Coordinate;
  geometryType?: string | null;
  ogcGeometryType?: OgcGeometryType;
  boundary?: Geometry;
  z?: number;
  m?: number;
  factory?: GeometryFactory;
  userData?: any | null;
  srid?: number;
  precisionModel?: PrecisionModel;
  numGeometries?: number;
  isSimple?: boolean;
  isValid?: boolean;
  area?: number;
  length?: number;
  centroid?: Point;
  interiorPoint?: Point;
  pointOnSurface?: Point;
  envelope?: Geometry;
  envelopeInternal?: Envelope;
  isRectangle?: boolean;
};
export type Location = {
  businessUnitId?: string | null;
  lockedPeriods?: LockedPeriod[] | null;
  id?: string;
  type?: string | null;
  name?: string | null;
  streetNumber?: number | null;
  shortName?: string | null;
  longName?: string | null;
  suburb?: string | null;
  city?: string | null;
  state?: string | null;
  postCode?: string | null;
  country?: string | null;
  description?: string | null;
  point?: Point;
  formattedAddress: string;
};
export type SocialIncidentCategory = {
  id?: string;
  name?: string | null;
  description?: string | null;
};
export type SocialIncidentResponse = {
  id?: string;
  dateReceived?: DateDto;
  locationId?: string;
  location?: Location;
  loggedById?: string;
  loggedBy?: BusinessPartnerResponse;
  recurring?: boolean;
  description?: string | null;
  socialIncidentCategoryId?: string;
  socialIncidentCategory?: SocialIncidentCategory;
  severity?: SeverityEnum;
  assignedToId?: string;
  assignedTo?: BusinessPartnerResponse;
  lastTransition?: TransitionResponse;
  investigationOutcomes?: string | null;
  outcomeResponse?: string | null;
  responseDateProvided?: DateDto;
  futherAction?: string | null;
  reoccuranceRisk?: SeverityEnum;
  documentIds?: string[] | null;
  captureById: string;
  captureTime: string;
};
export type SocialIncidentRequest = {
  dateReceived?: DateDto;
  loggedById?: string;
  recurring?: boolean;
  socialIncidentCategoryId?: string;
  severity?: SeverityEnum;
  assignedToId?: string;
  investigationOutcomes?: string | null;
  outcomeResponse?: string | null;
  responseDateProvided?: DateDto;
  futherAction?: string | null;
  reoccuranceRisk?: SeverityEnum;
  id?: string | null;
  locationId?: string;
  description?: string | null;
  externalTrackingId?: string | null;
  documentIds?: string[] | null;
};
export type StakeholderFeedbackResponse = {
  stakeHolderFeedbackSolicitationId?: string;
  scope3CategoryId?: string;
  importance?: number;
  stakeholderFeedbackSolicitationResponse?: StakeholderFeedbackSolicitationResponse;
  scope3CategoryResponse?: Scope3CategoryResponse;
};
export type StakeholderFeedbackSolicitationRequest = {
  id?: string;
  companyName?: string | null;
  personName?: string | null;
};
export type StakeholderFeedbackRequest = {
  stakeHolderFeedbackSolicitationId?: string;
  scope3CategoryId?: string;
  importance: number;
  stakeholderFeedbackSolicitationRequest?: StakeholderFeedbackSolicitationRequest;
  scope3CategoryRequest?: Scope3CategoryRequest;
};
export type StandardResponse = {
  id: StandardEnum;
  name?: string | null;
  description?: string | null;
};
export type StandardVersionResponse = {
  id: string;
  standardShort?: string | null;
  validityStart?: string | null;
  validityEnd?: string | null;
  standardName?: string | null;
  note?: string | null;
};
export type SupplierReviewResponse = {
  id?: string;
  companyReviewed: CompanyResponse;
  isEnvironmental?: boolean;
  reviewDate?: DateDto;
  impactDetails?: string | null;
  agreedImprovements?: boolean;
  terminationReason?: string | null;
  lastTransition?: TransitionResponse;
  documentIds?: string[] | null;
  captureById: string;
  captureTime: string;
};
export type SupplierReviewRequest = {
  companyReviewedId?: string;
  isEnvironmental?: boolean;
  reviewDate?: DateDto;
  impactDetails?: string | null;
  agreedImprovements?: boolean;
  terminationReason?: string | null;
  documentIds?: string[] | null;
};
export type TargetTypeDto = {
  name?: string | null;
  unitTypeData?: UnitTypeEnum[] | null;
  module?: ModuleEnum;
};
export type TargetRequest = {
  id?: string | null;
  reportingPeriodId: string;
  targetType?: TargetTypeDto;
  businessUnitIds: string[];
  quantity?: IQuantity;
  module: ModuleEnum;
  isCumulative?: boolean;
};
export type EnergySavingTypeEnum = 'Unknown' | 'Metric' | 'Emissions';
export type MonthlyNetZeroTargetResponse = {
  module?: ModuleEnum;
  netZeroProjectName?: string | null;
  energySavingType?: EnergySavingTypeEnum;
  name: string;
  businessUnitIds: string[];
  firstDayOfTarget: DateOnly;
  lastDayOfTarget: DateOnly;
  quantityPerMonth: IQuantity;
  isCumulative?: boolean;
};
export type MonthlyEnergyTargetResponse = {
  module?: ModuleEnum;
  scope?: EmissionScopeEnum;
  name: string;
  businessUnitIds: string[];
  firstDayOfTarget: DateOnly;
  lastDayOfTarget: DateOnly;
  quantityPerMonth: IQuantity;
  isCumulative?: boolean;
};
export type MonthlyTargetResponse = {
  module: ModuleEnum;
  name: string;
  businessUnitIds: string[];
  firstDayOfTarget: DateOnly;
  lastDayOfTarget: DateOnly;
  quantityPerMonth: IQuantity;
  isCumulative?: boolean;
};
export type GenericMonthlyTargetResponse =
  | MonthlyNetZeroTargetResponse
  | MonthlyEnergyTargetResponse
  | MonthlyTargetResponse;
export type GetTargetsResponse = GenericMonthlyTargetResponse[];
export type EnergyTargetRequest = {
  scope?: EmissionScopeEnum;
  configuredEmissionSourceId?: string | null;
  id?: string | null;
  reportingPeriodId: string;
  targetType?: TargetTypeDto;
  businessUnitIds: string[];
  quantity?: IQuantity;
  module: ModuleEnum;
  isCumulative?: boolean;
};
export type EnergyIntensityTargetRequest = {
  intensityMetricType: string;
  intensityType: string;
  id?: string | null;
  reportingPeriodId: string;
  targetType?: TargetTypeDto;
  businessUnitIds: string[];
  quantity?: IQuantity;
  module: ModuleEnum;
  isCumulative?: boolean;
};
export type NetZeroProjectTargetRequest = {
  netZeroProjectId?: string | null;
  energySavingType?: EnergySavingTypeEnum;
  id?: string | null;
  reportingPeriodId: string;
  targetType?: TargetTypeDto;
  businessUnitIds: string[];
  quantity?: IQuantity;
  module: ModuleEnum;
  isCumulative?: boolean;
};
export type EnergyIntensityTargetResponse = {
  intensityMetricType: string;
  intensityType: string;
  id?: string;
  reportingPeriodId?: string;
  reportingPeriod?: ReportingPeriodResponse;
  businessUnitIds?: string[] | null;
  targetType?: TargetTypeDto;
  quantity?: IQuantity;
  isCumulative?: boolean;
  captureById: string;
  captureTime: string;
};
export type TargetResponse = {
  id?: string;
  reportingPeriodId?: string;
  reportingPeriod?: ReportingPeriodResponse;
  businessUnitIds?: string[] | null;
  targetType?: TargetTypeDto;
  quantity?: IQuantity;
  isCumulative?: boolean;
  captureById: string;
  captureTime: string;
};
export type TrainingRequest = {
  cost?: number;
  summary?: string | null;
  participants?: BusinessPartnerRequest[] | null;
  id?: string | null;
  name?: string | null;
  dateRange?: DateRange;
  documentIds?: string[] | null;
};
export type UnitResponse = {
  id?: string | null;
  abbreviation?: string | null;
  name?: string | null;
  isEnabled?: boolean;
  unitType?: UnitTypeEnum;
  primaryUnitType?: UnitTypeEnum;
  secondaryUnitType?: UnitTypeEnum;
};
export type UnitPatchDto = {
  id?: string | null;
  isEnabled?: boolean;
};
export type WasteDisposalRequest = {
  isHazardous?: boolean;
  isOnSite?: boolean;
  id?: string | null;
  dateRange?: DateRange;
  description?: string | null;
  quantity?: QuantityDto;
  secondaryQuantity?: QuantityDto;
  configuredEmissionSourceId: string;
  documentIds?: string[] | null;
  locationId?: string;
  scope3CategoryId?: string;
};
export type WasteDisposalResponse = {
  isHazardous?: boolean;
  isOnSite?: boolean;
  isDisposal?: boolean;
  id: string;
  dateRange: DateRange;
  description?: string | null;
  quantity: QuantityDto;
  secondaryQuantity: QuantityDto;
  configuredEmissionSourceId: string;
  documentIds?: string[] | null;
  locationId: string;
  lastTransition: TransitionResponse;
  alerts?: AlertResponse[] | null;
  scope3CategoryId: string;
};
export type WasteDisposalInputTemplateResponse = {
  description?: string | null;
  configuredEmissionSourceId: string;
  scope3CategoryId: string;
  unitType?: UnitTypeEnum;
  quantity: IQuantity;
  secondaryQuantity?: IQuantity;
  isHazardous?: boolean;
  isOnSite?: boolean;
};
export type WasteDisposalDashboardDto = {
  wasteDisposalId: string;
  reportingPeriodId: string;
  locationId: string;
  configuredEmissionSourceId: string;
  quantity: Mass;
  stateId: string;
  isHazardous?: boolean;
  isOnSite?: boolean;
  isDisposal?: boolean;
  captureById: string;
  captureTime: string;
};
export type WasteDisposalSearchDto = {
  isHazardous?: boolean;
  isOnSite?: boolean;
  isDisposal?: boolean;
  id: string;
  dateRange: DateRange;
  description?: string | null;
  quantity: QuantityDto;
  secondaryQuantity?: QuantityDto;
  scope3CategoryId: string;
  configuredEmissionSourceId: string;
  lastTransition: TransitionResponse;
  locationId: string;
  emissions?: GasEmissionDto[] | null;
  hasAlerts?: boolean;
  isWaste?: boolean;
  captureById: string;
  captureTime: string;
};
export type WasteTypesEnum =
  | 'Construction'
  | 'Refuse'
  | 'ElectricalItems'
  | 'Metal'
  | 'Plastic'
  | 'Paper'
  | 'Mixed';
export type WasteMovementResponse = {
  wasteMovementId?: string;
  facilityMovementId?: string;
  wasteType?: WasteTypesEnum;
  measurementType?: string | null;
  measurementDescription?: string | null;
  locationId?: string;
  quantity?: IQuantity;
};
export type WasteMovementRequest = {
  wasteMovementId?: string;
  facilityMovementId?: string;
  wasteType?: WasteTypesEnum;
  measurementType?: string | null;
  measurementDescription?: string | null;
  destinationId?: string;
  quantity?: IQuantity;
};
export type DisposalMethodEnum =
  | 'Reuse'
  | 'RecycledOpenloop'
  | 'RecycledClosedloop'
  | 'Combustion'
  | 'Composting'
  | 'Landfill'
  | 'AnaerobicDigestion';
export type WasteSourceResponse = {
  disposalMethod?: DisposalMethodEnum;
  id: string;
  name: string;
  configuredEmissionSourceDisplayName?: string | null;
  configuredEmissionSourceId?: string | null;
  scope?: EmissionScopeEnum;
  emissionFactorCategoryId?: string | null;
  emissionFactorProviderId?: EmissionFactorProviderEnum;
  unitTypes?: UnitTypeEnum[] | null;
  hybridOptions?: UnitTypeEnumUnitTypeEnumTuple[] | null;
  emissionSourceGases?: EmissionSourceGasResponse[] | null;
  emissionFactorCategoryDto?: EmissionFactorCategoryDto;
  calorificValues?: CalorificValueDto[] | null;
  scope3CategoryIds?: string[] | null;
};
export type WaterQualityEnum = 'Unknown' | 'FreshWater' | 'OtherWater';
export type WaterQualityResponse = {
  id?: WaterQualityEnum;
  name?: string | null;
  description?: string | null;
};
export type WaterSourceTypeResponse = {
  id?: string;
  name?: string | null;
  isSelectable?: boolean;
};
export type WaterSourceResponse = {
  id?: string;
  name?: string | null;
  isWaterStress?: boolean;
  isNatural?: boolean;
  waterQuality?: WaterQualityResponse;
  waterSourceType?: WaterSourceTypeResponse;
};
export type WaterMeterReadingResponse = {
  id: string;
  reading: number;
  waterCost: number;
  previousReading: number;
  dateOfReading: DateDto;
  observation?: string | null;
  waterMeter?: WaterMeterResponse;
  waterMeterVolumeUnitId: VolumeUnit;
  lastTransition?: TransitionResponse;
  captureById: string;
  captureTime: string;
};
export type WaterMeterResponse = {
  id: string;
  locationId: string;
  name: string;
  description?: string | null;
  serialNumber?: string | null;
  dateOfInstallation?: DateDto;
  fromSourceText?: string | null;
  toSourceText?: string | null;
  fromSource?: WaterSourceResponse;
  toSource?: WaterSourceResponse;
  relatedBusinessActivity?: string | null;
  calibrationFrequency?: DateFrequency;
  regulatoryRequirements?: string | null;
  measurementFrequency?: DateFrequency;
  lastWaterMeterReading?: WaterMeterReadingResponse;
  openingReading: number;
  volumeUnitId?: VolumeUnit;
};
export type WaterMeterCalibrationRequest = {
  id?: string | null;
  dateOfCalibration?: DateDto;
  observation?: string | null;
  waterMeterId?: string;
};
export type WaterMeterReadingRequest = {
  id?: string | null;
  reading?: number;
  waterCost?: number;
  dateOfReading?: DateDto;
  observation?: string | null;
  waterMeterId?: string;
  documentIds?: string[] | null;
};
export type WaterMeterRequest = {
  id?: string | null;
  locationId: string;
  name: string;
  description?: string | null;
  serialNumber?: string | null;
  dateOfInstallation?: DateDto;
  fromSourceText?: string | null;
  fromSourceId?: string | null;
  toSourceText?: string | null;
  toSourceId?: string | null;
  relatedBusinessActivity?: string | null;
  calibrationFrequency?: DateFrequency;
  regulatoryRequirements?: string | null;
  measurementFrequency?: DateFrequency;
  initialCalibration?: WaterMeterCalibrationRequest;
  initialReading?: WaterMeterReadingRequest;
  volumeUnitId?: VolumeUnit;
};
export type WaterMeterCalibrationResponse = {
  id?: string;
  dateOfCalibration?: DateDto;
  observation?: string | null;
  waterMeter?: WaterMeterResponse;
};
export type WaterUsageResponse = {
  waterMeterReadingId?: string;
  reportingPeriodId?: string;
  locationId?: string;
  waterMeterName?: string | null;
  sourceName?: string | null;
  sourceText?: string | null;
  isDischarge?: boolean;
  usage?: number;
  volumeUnitId?: VolumeUnit;
  cost?: number;
};
export type WaterMeterReadingOcrResponse = {
  waterMeterReading?: WaterMeterReadingRequest;
  documentResponse?: DocumentResponse;
};
export type WaterUsageRequest = {
  id?: string | null;
  usage?: number;
  waterCost?: number;
  dateOfReading?: DateDto;
  observation?: string | null;
  waterMeterId?: string;
  documentIds?: string[] | null;
};
export type WaterSourceRequest = {
  id?: string | null;
  name?: string | null;
  isWaterStress?: boolean;
  isNatural?: boolean;
  waterSourceTypeId?: string;
  waterQualityId?: WaterQualityEnum;
};
export const {
  useGetApiAlertsByAlertIdQuery,
  useGetApiAlertsQuery,
  usePutApiAlertsMutation,
  usePutApiBiodiversityMutation,
  useGetApiBiodiversityPaginatedQuery,
  useGetApiBiodiversityByIdQuery,
  useDeleteApiBiodiversityByIdMutation,
  usePostApiBiodiversityByIdSignOffMutation,
  useGetApiBiodiversityByIdAuditTrailQuery,
  useGetApiBiodiversityByIdChangeSummaryQuery,
  useGetApiBiodiversityImpactRatingQuery,
  useGetApiBiodiversityImpactDriverQuery,
  useGetApiBiodiversityDashboardQuery,
  useGetApiCareerDevelopmentBursaryByBursaryGuidQuery,
  usePutApiCareerDevelopmentBursaryMutation,
  useGetApiBusinessPartnerGenderQuery,
  useGetApiBusinessPartnerEthnicityQuery,
  useGetApiBusinessPartnerByBpGuidQuery,
  useGetApiBusinessPartnerByIdByIdentificationValueQuery,
  useGetApiBusinessPartnerSearchBySearchTermQuery,
  usePutApiBusinessPartnerMutation,
  useGetApiCommonBusinessUnitsQuery,
  useGetApiEnergyCarbonTaxQuery,
  usePutApiEnergyCarbonTaxMutation,
  usePostApiEnergyCarbonTaxCalculatedValuesMutation,
  useGetApiEnergyCarbonTaxRateQuery,
  useGetApiEnergyCarbonTaxSchedulesQuery,
  usePostApiEnergyCarbonTaxByIdSignOffMutation,
  useDeleteApiEnergyCarbonTaxByCarbonTaxInputIdMutation,
  useGetApiCareerDevelopmentQuery,
  usePostApiCareerDevelopmentByCareerDevelopmentIdSignOffMutation,
  useDeleteApiCareerDevelopmentByCareerDevelopmentIdMutation,
  useGetApiCareerDevelopmentByIdAuditTrailQuery,
  useGetApiCareerDevelopmentCareerReviewByCareerReviewGuidQuery,
  usePutApiCareerDevelopmentCareerReviewMutation,
  useGetApiCompanyByCompanyGuidQuery,
  useDeleteApiCompanyByCompanyGuidMutation,
  useGetApiCompanySearchBySearchTermQuery,
  useGetApiCompanyQuery,
  usePutApiCompanyMutation,
  useGetApiCompanyTypesQuery,
  useGetApiCompanyRelationshipsDefinitionsQuery,
  useGetApiCommonCustomMetricByCustomMetricIdQuery,
  useGetApiCommonCustomMetricQuery,
  usePutApiCommonCustomMetricMutation,
  useDeleteApiCommonCustomMetricMutation,
  useGetApiCommonCustomMetricNormalizedQuery,
  useGetApiCommonCustomMetricTypesQuery,
  useGetApiCommonCustomMetricTypeRootsQuery,
  usePutApiCommonCustomMetricBatchMutation,
  useGetApiCommonCustomMetricInputTemplatesQuery,
  usePostApiCommonCustomMetricByCustomMetricIdSignOffMutation,
  useGetApiCommonCustomMetricByCustomMetricIdAuditTrailQuery,
  useGetApiCommonCustomMetricByIdChangeSummaryQuery,
  useGetApiDashboardsQuery,
  usePatchApiDashboardsMutation,
  useGetApiDashboardsByIdQuery,
  useGetApiCommonDateEnumFrequencyQuery,
  useGetApiCommonDateEnumFrequencyWithHourlyQuery,
  usePostApiDisclosuresMutation,
  useGetApiDisclosuresByStandardIdAndModuleIdQuery,
  useGetApiDisclosuresChildrenQuery,
  useGetApiDisclosuresByDisclosureIdQuery,
  usePatchApiDisclosuresAnswersMutation,
  useGetApiDisclosuresAnswersQuery,
  useGetApiDisclosuresCompletenessQuery,
  usePutApiDisclosuresMappingMutation,
  usePutApiDisclosuresIndexingMutation,
  useGetApiDisclosuresResolversQuery,
  useGetApiEnergyEmissionsQuery,
  useGetApiEnergyEmissionsPaginatedQuery,
  useGetApiCommonGasesQuery,
  useGetApiEnergySourcesByIdQuery,
  useGetApiEnergySourcesSearchQuery,
  usePutApiEnergySourcesDisplayNameMutation,
  useGetApiEnergySourcesDashboardQuery,
  useGetApiEnergySourcesQuery,
  usePostApiEnergySourcesMutation,
  useGetApiEnergySourcesByConfiguredEmissionSourceIdEmissionSourcesQuery,
  useGetApiEnergySourcesEmissionFactorProviderQuery,
  usePostApiEnergySourcesGasMutation,
  useGetApiEmployeeAuditByEmployeeAuditIdQuery,
  useDeleteApiEmployeeAuditByEmployeeAuditIdMutation,
  useGetApiEmployeeAuditQuery,
  usePutApiEmployeeAuditMutation,
  usePostApiEmployeeAuditByIdSignOffMutation,
  useGetApiEmployeeAuditByIdAuditTrailQuery,
  useGetApiEmployeeAuditByIdChangeSummaryQuery,
  useGetApiEmployeeAuditDashboardQuery,
  useGetApiEmployeeAuditPaginatedQuery,
  useGetApiEmployeeAuditAgeGroupsQuery,
  useGetApiEnergyInputTemplatesQuery,
  useDeleteApiEnergyByIdMutation,
  useGetApiEnergyByIdQuery,
  usePutApiEnergyMutation,
  useGetApiEnergyGetBatchQuery,
  usePutApiEnergyBatchMutation,
  useGetApiEnergyByIdCalculationLogsQuery,
  usePostApiEnergyByIdSignOffMutation,
  useGetApiEnergyByIdAuditTrailQuery,
  useGetApiEnergyByIdChangeSummaryQuery,
  useGetApiEnergyMetricsQuery,
  useGetApiEnergyMetricsByMetricIdUnitsQuery,
  usePatchApiEnergyMetricsByMetricIdUnitsMutation,
  useGetApiEntityManagementEntityTemplateQuery,
  usePutApiEntityManagementEntityTemplateMutation,
  useGetApiEntityManagementEntityTemplateByTemplateIdQuery,
  useGetApiEntityManagementEntityTemplateHistoryQuery,
  usePostApiEntityManagementEntityTemplateByFileNameMutation,
  useGetApiFeedbackCategoryStakeholderSolicitationQuery,
  useGetApiFeedbackCategoryInternalSolicitationQuery,
  usePutApiCommunitiesCsiMutation,
  useGetApiCommunitiesCsiQuery,
  usePostApiCommunitiesCsiFromDocumentByFileNameMutation,
  useGetApiCommunitiesCsiByByGroupingQuery,
  useGetApiCommunitiesCsiByIdQuery,
  usePostApiCommunitiesCsiByFundingApplicationIdSignOffMutation,
  useDeleteApiCommunitiesCsiByFundingApplicationIdMutation,
  useGetApiCommunitiesCsiByIdAuditTrailQuery,
  useGetApiCommunitiesCsiByIdChangeSummaryQuery,
  usePutApiCommunitiesImpactAssessmentMutation,
  useGetApiCommunitiesImpactAssessmentQuery,
  useGetApiCommunitiesImpactAssessmentDashboardQuery,
  useGetApiCommunitiesImpactAssessmentByIdQuery,
  useDeleteApiCommunitiesImpactAssessmentByIdMutation,
  useGetApiCommunitiesImpactAssessmentByIdAuditTrailQuery,
  useGetApiCommunitiesImpactAssessmentByIdChangeSummaryQuery,
  usePostApiCommunitiesImpactAssessmentByIdSignOffMutation,
  usePutApiHealthAndSafetyIncidentMutation,
  useGetApiHealthAndSafetyIncidentQuery,
  useGetApiHealthAndSafetyIncidentByIdQuery,
  useDeleteApiHealthAndSafetyIncidentByIdMutation,
  useGetApiHealthAndSafetyIncidentCategoriesQuery,
  useGetApiHealthAndSafetyIncidentDashboardQuery,
  usePostApiHealthAndSafetyIncidentByIdSignOffMutation,
  useGetApiHealthAndSafetyIncidentByIdAuditTrailQuery,
  useGetApiHealthAndSafetyIncidentByIdChangeSummaryQuery,
  useGetApiCommunitiesIndicatorIndicatorsQuery,
  usePostApiEnergyIndirectFromTemplateByFileNameMutation,
  useGetApiInternalFeedbackQuery,
  usePostApiInternalFeedbackMutation,
  useGetApiInternalFeedbackByIdQuery,
  useGetApiCareerDevelopmentLearnershipByLearnershipGuidQuery,
  usePutApiCareerDevelopmentLearnershipMutation,
  usePostApiCommonLocationsMutation,
  useGetApiCommonLocationsQuery,
  useGetApiCommonLocationsLockedPeriodsQuery,
  useGetApiCommonLocationsByLocationIdQuery,
  useGetApiCommonLockedPeriodsMissingEmissionSourcesQuery,
  usePutApiCommonLockedPeriodsMutation,
  useDeleteApiCommonLockedPeriodsByLockPeriodIdMutation,
  useGetApiEntityManagementMappedEntitiesQuery,
  useGetApiEntityManagementMappedEntitiesByMappedEntityNameQuery,
  usePutApiNetZeroApplicationMutation,
  useGetApiNetZeroApplicationQuery,
  useGetApiNetZeroApplicationByIdQuery,
  useDeleteApiNetZeroApplicationByIdMutation,
  useGetApiNetZeroApplicationAllQuery,
  usePostApiNetZeroApplicationByNetZeroProjectApplicationIdSignOffMutation,
  useGetApiNetZeroApplicationByIdAuditTrailQuery,
  useGetApiNetZeroApplicationByIdChangeSummaryQuery,
  useGetApiNetZeroProjectTypesQuery,
  usePutApiNetZeroProjectMutation,
  useGetApiNetZeroProjectQuery,
  useGetApiNetZeroProjectByIdQuery,
  useDeleteApiNetZeroProjectByIdMutation,
  usePostApiNetZeroProjectByNetZeroProjectIdSignOffMutation,
  useGetApiNetZeroProjectByIdAuditTrailQuery,
  useGetApiNetZeroProjectByIdChangeSummaryQuery,
  useGetApiNetZeroProjectByProjectIdIndicatorQuery,
  usePutApiNetZeroProjectByProjectIdIndicatorMutation,
  useGetApiNetZeroProjectByProjectIdIndicatorAndIndicatorIdQuery,
  useDeleteApiNetZeroProjectByProjectIdIndicatorAndIndicatorIdMutation,
  usePostApiNetZeroProjectIndicatorByIndicatorIdSignOffMutation,
  usePutApiNetZeroProjectTypeMutation,
  useGetApiNetZeroProjectTypeByProjectTypeIdQuery,
  useGetApiNetZeroProjectTypeAllQuery,
  useGetApiNotificationsQuery,
  usePutApiNotificationsMutation,
  usePutApiOperationMutation,
  useGetApiOperationQuery,
  useGetApiOperationByIdQuery,
  useDeleteApiOperationByIdMutation,
  useGetApiOperationOperationTypesQuery,
  useGetApiCommunitiesProjectProjectsQuery,
  useGetApiCommunitiesProjectByProjectIdQuery,
  useDeleteApiCommunitiesProjectByProjectIdMutation,
  useGetApiCommunitiesProjectByProjectIdPaymentsQuery,
  usePostApiCommunitiesProjectPaymentsMutation,
  usePutApiCommunitiesProjectPaymentMutation,
  useGetApiCommunitiesProjectPaymentByProjectPaymentIdQuery,
  useDeleteApiCommunitiesProjectPaymentByProjectPaymentIdMutation,
  useGetApiCommunitiesProjectByProjectIdReportsQuery,
  usePostApiCommunitiesProjectByProjectIdRequestReportMutation,
  useDeleteApiCommunitiesProjectByProjectReportIdReportMutation,
  useGetApiCommunitiesProjectByProjectIdIndicatorsQuery,
  usePostApiCommunitiesProjectByProjectIdIndicatorsMutation,
  usePutApiPublicCommunitiesProjectReportByProjectReportIdAnswersMutation,
  useGetApiPublicCommunitiesProjectReportByProjectReportIdAnswersQuery,
  useGetApiCommonReportingPeriodQuery,
  usePutApiCommonReportingPeriodMutation,
  useDeleteApiCommonReportingPeriodByReportingPeriodIdMutation,
  useGetApiBusinessPartnerRolesQuery,
  usePutApiBusinessPartnerRolesMutation,
  useGetApiBusinessPartnerRolesByRoleGuidQuery,
  usePutApiEnergyScope3Mutation,
  useGetApiEnergyScope3Query,
  usePutApiEnergyScope3BatchMutation,
  useGetApiEnergyScope3InputTemplatesQuery,
  usePostApiEnergyScope3ByScope3EnergyIdSignOffMutation,
  useDeleteApiEnergyScope3ByScope3EnergyIdMutation,
  useGetApiEnergyScope3EmissionsQuery,
  useGetApiEnergyScope3PaginatedQuery,
  useGetApiEnergyScope3ByIdAuditTrailQuery,
  useGetApiEnergyScope3ByIdChangeSummaryQuery,
  useGetApiEnergyScope3CategoriesQuery,
  usePostApiEnergyScope3OnceOffMutation,
  useGetApiEnergyScope3GetBatchQuery,
  useGetApiCommonSectorsQuery,
  useGetApiCommonSettingsReportingStructureQuery,
  useGetApiCommonSettingsDashboardPaletteQuery,
  useGetApiCommonSettingsFeatureFlagsQuery,
  useGetApiSignoffQuery,
  useGetApiSignoffByEntityNameEntityStatesQuery,
  usePutApiSignoffRequiredDocumentsMutation,
  useDeleteApiSignoffByIdMutation,
  useGetApiSignoffByEntityNameAllowedTransitionsQuery,
  useGetApiCommunitiesSocialIncidentCategoriesQuery,
  useGetApiCommunitiesSocialIncidentQuery,
  usePutApiCommunitiesSocialIncidentMutation,
  useGetApiCommunitiesSocialIncidentBySocialIncidentIdQuery,
  useDeleteApiCommunitiesSocialIncidentBySocialIncidentIdMutation,
  usePostApiCommunitiesSocialIncidentBySocialIncidentIdSignOffMutation,
  useGetApiCommunitiesSocialIncidentByIdAuditTrailQuery,
  useGetApiStakeholderFeedbackQuery,
  usePostApiStakeholderFeedbackMutation,
  useGetApiStakeholderFeedbackByIdQuery,
  useGetApiStandardsQuery,
  useGetApiStandardsVersionsQuery,
  useGetApiValueChainSupplierReviewBySupplierReviewGuidQuery,
  useDeleteApiValueChainSupplierReviewBySupplierReviewGuidMutation,
  useGetApiValueChainSupplierReviewQuery,
  usePutApiValueChainSupplierReviewMutation,
  useGetApiValueChainSupplierReviewBySupplierReviewGuidAuditTrailQuery,
  usePostApiValueChainSupplierReviewBySupplierReviewGuidSignOffMutation,
  usePutApiTargetMutation,
  useGetApiTargetQuery,
  usePutApiTargetEnergyMutation,
  usePutApiTargetEnergyIntensityMutation,
  usePutApiTargetNetzeroMutation,
  useGetApiTargetIntensityTargetsQuery,
  useGetApiTargetByTargetIdQuery,
  useDeleteApiTargetByTargetIdMutation,
  useGetApiTargetAllQuery,
  useGetApiTargetTypesQuery,
  useGetApiCareerDevelopmentTrainingByTrainingGuidQuery,
  usePutApiCareerDevelopmentTrainingMutation,
  useGetApiCommonUnitsByUnitTypeQuery,
  usePatchApiCommonUnitsByUnitTypeMutation,
  useGetApiCommonUnitsTypesQuery,
  usePutApiWasteDisposalMutation,
  useGetApiWasteDisposalQuery,
  usePutApiWasteDisposalBatchMutation,
  useGetApiWasteDisposalInputTemplatesQuery,
  useDeleteApiWasteDisposalByWasteDisposalIdMutation,
  usePostApiWasteDisposalByWasteDisposalIdSignOffMutation,
  useGetApiWasteDisposalDashboardQuery,
  useGetApiWasteDisposalTableQuery,
  useGetApiWasteDisposalByIdAuditTrailQuery,
  useGetApiWasteDisposalByIdChangeSummaryQuery,
  useGetApiWastemovementByWasteMovementGuidQuery,
  useGetFacilitymovementsByFacilityMovementGuidQuery,
  usePutApiWastemovementMutation,
  useGetApiWasteSourceQuery,
  useGetApiWasteSourceDisplayNameQuery,
  useGetApiWaterMeterQuery,
  usePutApiWaterMeterMutation,
  useGetApiWaterMeterByWaterMeterIdReadingsQuery,
  useGetApiWaterMeterByWaterMeterIdCalibrationsQuery,
  usePutApiWaterMeterCalibrationMutation,
  useGetApiWaterMeterReadingQuery,
  usePutApiWaterMeterReadingMutation,
  useDeleteApiWaterMeterReadingMutation,
  usePostApiWaterMeterReadingFromTemplateByFileNameMutation,
  usePostApiWaterMeterReadingByWaterMeterReadingIdSignOffMutation,
  useGetApiWaterMeterReadingByWaterMeterReadingIdAuditTrailQuery,
  useGetApiWaterMeterReadingByIdChangeSummaryQuery,
  useGetApiWaterMeterReadingByWaterMeterReadingIdQuery,
  usePutApiWaterMeterReadingUsageMutation,
  useGetApiWaterSourceQualitiesQuery,
  useGetApiWaterSourceTypesQuery,
  useGetApiWaterSourceQuery,
  usePutApiWaterSourceMutation,
} = injectedRtkApi;
