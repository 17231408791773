import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, DialogContent, Grid, Typography, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Property } from 'csstype';
import ActionSidebar from './action-sidebar';

interface ModalProps {
  title: string;
  isOpen: boolean;
  maxWidth?: string;
  overflow?: Property.Overflow;
  onClose: () => void;
  sideBarChildren?: React.ReactNode;
  closeOnBackdropClick?: boolean;
  secondary?: boolean;
}

const primaryContainer = document.getElementById('modals-primary');
const secondaryContainer = document.getElementById('modals-secondary');

export default class Modal extends React.PureComponent<ModalProps> {
  render () : JSX.Element {
    const { secondary, isOpen, title, children, onClose, maxWidth = '600px', overflow = 'visible', sideBarChildren, closeOnBackdropClick = true } = this.props;

    return (
      <Dialog disableEnforceFocus={!secondary} hideBackdrop={!!secondary} container={secondary ? secondaryContainer : primaryContainer} PaperProps={{ sx: { maxWidth, overflow: overflow, backgroundColor: 'white', border: '0px', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 11, borderTopRightRadius: 11 } }} open={isOpen} onClose={(evt, reason) => (reason === 'backdropClick' && closeOnBackdropClick) ? onClose() : null} fullWidth>
        <DialogActions sx={{ background: '#999BBE', border: '0px', borderTopLeftRadius: 10, borderTopRightRadius: 10 }} >
          {/* For some reason using secondary.main in the above dialog action is not working... */}
          <Grid container spacing={1} >
            <Grid item xs={8} >
              <Typography variant="h5" gutterBottom component="div" textAlign='left' color='primary.main' sx={{ mt: 1, ml: 1 }}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign='right'>
              <Button onClick={onClose} startIcon={<Close sx={{ color: 'white' }} />} sx={{ mt: 1 }}></Button>
            </Grid>
          </Grid>
        </DialogActions>
        <DialogContent sx={{ overflow: 'scroll-y' }}>
          {children}
          {
            sideBarChildren &&
            <ActionSidebar
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
              >
                {sideBarChildren}
              </Stack>
            </ActionSidebar>
          }
        </DialogContent>
      </Dialog>
    );
  }
}