import { Dialog, Button, DialogContent, Stack, CircularProgress, Typography } from '@mui/material';
import { Portal } from '@mui/base';
import React from 'react';

interface ConfirmationModalProps {
  confirmText?: string;
  cancelText?: string;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

class ConfirmationModal extends React.PureComponent<ConfirmationModalProps> {
  render (): JSX.Element {
    const { confirmText = 'Confirm', cancelText = 'Cancel', isOpen, onClose, onConfirm, isLoading = false, title, message } = this.props;

    return (
      <Portal>
        <Dialog PaperProps={{ sx: { overflow: 'visible', backgroundColor: 'white', border: '0px', borderRadius: '5px', width: '300px' } }} open={isOpen} onClose={onClose} fullWidth>
          <DialogContent sx={{ p: 0, overflow: 'visible' }}>
            <Typography gutterBottom component="div" color={'white'} textAlign='center' sx={{ fontSize: '1.3em', borderRadius: '5px 5px 0 0', width: '100%', p: 1, backgroundColor: 'primary.main' }}>
              { title }
            </Typography>
            <Typography gutterBottom component="div" textAlign='center' sx={{ width: '100%', p: 1 }}>
              { message }
            </Typography>
            <Stack sx={{ p: 1 }} direction="row" justifyContent={'center'} gap={2}>
              {
                isLoading ?
                  <CircularProgress />
                  : <>
                    <Button variant='contained' color='secondary' onClick={onClose}>{cancelText}</Button>
                    <Button variant='contained' onClick={onConfirm}>{confirmText}</Button>
                  </>
              }
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    );
  }
}

export default ConfirmationModal;