/* eslint-disable max-lines */
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import InfoIcon from '@mui/icons-material/Info';
import { useAppDispatch } from 'services/stores/hooks';
import { showAlert } from 'services/alerts/alert-service';
import { Box, Button, CircularProgress, FormGroup, Stack, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik } from 'formik';
import TargetModalFormValues, { ValidationSchema, InitialValues, setInitialValues, resetInitialValues, IntensityType } from './target-form-values';
import GenericDropdown from 'components/input/generic-dropdown';
import Modal from 'components/shared/modal';
import {
  api,
  GetApiTargetByTargetIdResponse,
  ModuleEnum,
  TargetTypeDto,
  EmissionScopeEnum,
  ConfiguredEmissionSourceResponse,
  NetZeroProjectResponse,
  UnitTypeEnum,
  CustomMetricTypeResponse,
  EnergyIntensityTargetRequest,
  EnergyTargetRequest,
  TargetRequest,
  NetZeroProjectTargetRequest
} from 'services/api/main';
import { CommonUnitType, DataLoad } from 'services/api/common-types';
import UnitQuantityCapture from 'components/input/unit-qty-capture';
import { Dictionary, values } from 'lodash';
import ReportingPeriodDropdown from 'components/input/controlled/reporting-period-dropdown';
import MultiSelectDropdownControlled from 'components/input/controlled/multi-select-dropdown';
import { ExtractUnit, ExtractUnitType } from 'common';
import SwitchControlled from 'components/input/controlled/switch-controlled';
import SwitchField from 'components/input/switch-field';

interface TargetModalProps {
  title?: string;
  module?: ModuleEnum;
}

const TargetModal = ({ title, module }: TargetModalProps) => {
  const appDispatch = useAppDispatch();
  const [putTarget, { isSuccess, isError }] = api.usePutApiTargetMutation();
  const [putEnergyTarget, { isSuccess: isEnergySuccess, isError: isEnergyError }] = api.usePutApiTargetEnergyMutation();
  const [putNetZeroTarget, { isSuccess: isNetZeroSuccess, isError: isNetZeroError }] = api.usePutApiTargetNetzeroMutation();
  const [putEnergyIntensityTarget, { isSuccess: isEnergyIntensitySuccess, isError: isEnergyIntensityError }] = api.usePutApiTargetEnergyIntensityMutation();
  const { targetId, projectId } = useParams<'targetId' | 'projectId'>();
  const navigate = useNavigate();
  const closeModal = React.useCallback(() => navigate(-1), [navigate]);
  const targetData: DataLoad<GetApiTargetByTargetIdResponse> = api.useGetApiTargetByTargetIdQuery({ targetId: targetId ?? '' }, { skip: !targetId });
  const targetTypes: DataLoad<Dictionary<TargetTypeDto>> = api.useGetApiTargetTypesQuery({ module: module });
  const { data: intensityMetricTypes, isLoading: intensityMetricTypesLoading }: DataLoad<Dictionary<CustomMetricTypeResponse>> = api.useGetApiCommonCustomMetricTypesQuery({ module: module as ModuleEnum, isIntensity: true });
  const [scopeSelected, setScopeSelected] = React.useState<EmissionScopeEnum>('Unknown');
  const [unitType, setUnitType] = React.useState<UnitTypeEnum>('Unknown');
  const { data: emissionSources, isLoading: emissionSourcesLoading }: DataLoad<Dictionary<ConfiguredEmissionSourceResponse>> = api.useGetApiEnergySourcesQuery({ scope: scopeSelected }, { skip: !scopeSelected });
  const { data: businessUnitsList, isLoading: businessUnitListLoading }: DataLoad<Dictionary<string>> = api.useGetApiCommonBusinessUnitsQuery();
  const { data: netZeroProjects, isLoading: loadingNetZeroProjects }: DataLoad<NetZeroProjectResponse[]> = api.useGetApiNetZeroProjectQuery(undefined, { skip: module !== 'NetZero' && module !== 'Energy' });

  const intensityTypes: IntensityType[] = [{ name: 'Energy', unitType: 'Energy' }, { name: 'Emissions', unitType: 'Mass' }];
  const energySavingTypes = ['Metric', 'Emissions'];

  // Temporary solution - see TD: https://dev.azure.com/econest/econest/_workitems/edit/3055
  const intensityMetricTypesByName = {};

  React.useEffect(() => {
    if (!intensityMetricTypes) return;
    Object.values(intensityMetricTypes!).map(imt => intensityMetricTypesByName[imt.name!] = { ...imt });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intensityMetricTypes]);

  const [showOptional, setShowOptional] = React.useState(false);
  React.useEffect(() => {
    const primaryUnitType = (targetData.data?.quantity?.unit
      ? ExtractUnitType(targetData.data!.quantity!.unit)
      : 'Unknown') as UnitTypeEnum;

    setUnitType(primaryUnitType);
  }, [targetData.data]);

  if (targetData.data)
    setInitialValues(targetData.data);
  else
    resetInitialValues({ netZeroProjectId: projectId });

  React.useEffect(() => {
    if (isSuccess || isEnergySuccess || isEnergyIntensitySuccess || isNetZeroSuccess) {
      appDispatch(showAlert({ severity: 'success', message: 'Target successfully saved' }));
      closeModal();
      return;
    }
    if (isError || isEnergyError || isEnergyIntensityError || isNetZeroError) {
      appDispatch(showAlert({ severity: 'error', message: 'Error saving Target' }));
      return;
    }
  }, [isSuccess, isError, isEnergySuccess, isEnergyError, appDispatch, closeModal, isEnergyIntensitySuccess, isEnergyIntensityError, isNetZeroSuccess, isNetZeroError]);

  const onSubmit = React.useCallback(
    (values: TargetModalFormValues) => {
      if (module === 'Energy') {
        if (values.targetType!.name! === 'Intensity') {
          putEnergyIntensityTarget({
            energyIntensityTargetRequest: {
              ...values
            } as EnergyIntensityTargetRequest
          });
        } else if (values.targetType!.name === 'Energy & GHG Reduction') {
          putNetZeroTarget({
            netZeroProjectTargetRequest:
              {
                ...values,
                module: module ?? 'Unknown'
              } as NetZeroProjectTargetRequest
          });
        } else {
          putEnergyTarget({
            energyTargetRequest:
              {
                ...values,
                module: module ?? 'Unknown',
                scope: values.scope as EmissionScopeEnum
              } as EnergyTargetRequest
          });
        }
      } else if (module === 'NetZero') {
        putNetZeroTarget({
          netZeroProjectTargetRequest:
            {
              ...values,
              module: module ?? 'Unknown'
            } as NetZeroProjectTargetRequest
        });
      } else {
        putTarget({
          targetRequest:
            {
              ...values,
            } as TargetRequest
        });
      }
    }, [module, putEnergyTarget, putEnergyIntensityTarget, putTarget, putNetZeroTarget]
  );

  const getUnit = (fullUnitType: CommonUnitType | undefined): string => fullUnitType ? ExtractUnit(fullUnitType) : '(Unit)';

  return (
    <Modal
      key='TargetModal'
      title={title ? title : 'Capture Target'}
      isOpen
      onClose={closeModal}
      children={
        <Formik initialValues={InitialValues} validationSchema={ValidationSchema} onSubmit={onSubmit} enableReinitialize>
          {
            formik =>
              <Form>
                <Stack direction={'column'} sx={{ gap: 2 }}>
                  <Stack direction='column' spacing={1}>
                    {businessUnitsList ?
                      <Field name="businessUnitIds">
                        {
                          (fieldProps: FieldProps<string[], any>) =>
                            <MultiSelectDropdownControlled
                              fieldInputProps={fieldProps.field}
                              fieldMetaProps={fieldProps.meta}
                              value={fieldProps.field.value}
                              onChange={v => fieldProps.form.setFieldValue('businessUnitIds', v)}
                              id='businessUnitIds'
                              items={businessUnitListLoading ? [] : Object.keys(businessUnitsList!)}
                              label='Select Business Unit'
                              selectSx={{ width: '100%' }}
                              sx={{ width: '100%' }} />
                        }
                      </Field>
                      : <CircularProgress />
                    }
                    <Stack direction='row' spacing={1}>
                      <Field name="reportingPeriodId">
                        {
                          ({ meta, field, form: { setFieldValue } }: FieldProps<string>) =>
                            (<ReportingPeriodDropdown
                              fieldMetaProps={meta}
                              value={formik?.values?.reportingPeriodId!}
                              onChange={v => setFieldValue('reportingPeriodId', v)}
                              sx={{ minWidth: '50%', maxWidth: '50%' }}
                              formControlProps={{ size: 'medium' }}
                            />)
                        }
                      </Field>
                      <GenericDropdown
                        data={targetTypes.data ? Object.values(targetTypes.data) : []}
                        selectElementId='target_dropdown'
                        selectElementLabel='Select Target Type'
                        selectedItemId={formik.values.targetType!.name ?? 'target'}
                        getKey={i => i.name!} getDisplay={i => i.name!}
                        name={'targetType.name'}
                        isLoading={false}
                        stackSx={{ width: '50%' }}
                        onChange={(e) => {
                          formik.setFieldValue('unitTypeData', e?.unitTypeData!);
                          formik.setFieldValue('targetType.module', e?.module!);
                        }} />
                    </Stack>
                    <SwitchField
                      title='Cumulative Target?'
                      name='isCumulative'
                      size='medium'
                      direction='row-reverse'
                    />
                    {
                      formik.values.targetType!.name !== ''
                        ? <>
                          <Box hidden={module !== 'Energy' || formik.values.targetType!.name === 'Energy & GHG Reduction'}>
                            <Stack direction='row' spacing={1} hidden={formik.values.targetType!.name !== 'Intensity'} >
                              <GenericDropdown
                                data={intensityTypes}
                                selectElementId='intensityType'
                                selectElementLabel='Intensity Type'
                                selectedItemId={formik.values.intensityType}
                                getKey={i => i.name} getDisplay={i => i.name}
                                onChange={i => { setUnitType(i!.unitType!); formik.setFieldValue('quantity.unit', undefined); }}
                                name={'intensityType'} />

                              <GenericDropdown
                                data={intensityMetricTypes ? Object.values(intensityMetricTypes) : []}
                                selectElementId='intensityMetricType' selectElementLabel='Intensity Metric Type'
                                getKey={i => i.name!} getDisplay={i => i.name!}
                                name={'intensityMetricType'}
                                isLoading={intensityMetricTypesLoading} />
                            </Stack>
                          </Box>

                          <Box hidden={module !== 'Energy' || formik.values.targetType!.name === 'Intensity'}>
                            <Stack direction='row' spacing={1} hidden={formik.values.targetType!.name !== 'Energy & GHG Reduction'} >
                              <GenericDropdown
                                data={energySavingTypes}
                                selectElementId='energySavingType'
                                selectElementLabel='Energy Saving Type'
                                selectedItemId={formik.values.energySavingType}
                                getKey={i => i} getDisplay={i => i}
                                onChange={i => { setUnitType(i === 'Emissions' ? 'Mass' : 'Unknown'); formik.setFieldValue('quantity.unit', undefined); }}
                                name={'energySavingType'} />
                            </Stack>
                          </Box>

                          <Box hidden={module !== 'NetZero' && formik.values.targetType!.name !== 'Energy & GHG Reduction'}>
                            <Stack direction={'row'}>
                              <GenericDropdown
                                data={netZeroProjects ?? []}
                                selectElementId='netZeroProject_dropdown'
                                selectElementLabel='Select Net Zero Project'
                                selectedItemId={formik.values.netZeroProjectId}
                                getKey={i => i.id!} getDisplay={i => i.application!.name!}
                                name={'netZeroProjectId'}
                                onChange={e => {
                                  if(formik.values.energySavingType !== 'Emissions') 
                                    setUnitType(e?.application?.unitType ?? 'Unknown'); 
                                }}
                                isLoading={loadingNetZeroProjects} />
                            </Stack>
                          </Box>
                          {
                            !formik.values.targetType?.name?.includes('Intensity') ?
                              <GenericDropdown
                                sx={{ minWidth: 125 }}
                                disabled={formik.values.energySavingType === 'Emissions' || formik.values.netZeroProjectId !== undefined}
                                selectElementId='unit-type-select'
                                selectElementLabel='Select Unit Type'
                                selectedItemId={unitType}
                                data={formik.values.unitTypesData ?? []}
                                getKey={d => d.toString()}
                                getDisplay={d => d.toString()}
                                onChange={ut => setUnitType(ut)}
                                getSelectedDisplay={d => d}
                                isLoading={false} />
                              : null
                          }
                          <UnitQuantityCapture unitType={unitType} />
                          <Box hidden={module !== 'Energy' || formik.values.targetType?.name === 'Energy & GHG Reduction'}>
                            <Stack hidden={formik.values.targetType!.name!.includes('Intensity')}>
                              <FormGroup row >
                                <SwitchControlled
                                  title='Set Optional attributes.'
                                  size='medium'
                                  direction='row-reverse'
                                  checked={showOptional}
                                  onChange={() => { setShowOptional(!showOptional); formik.setFieldValue('scope', 'Unknown'); formik.setFieldValue('configuredEmissionSourceId', ''); }}
                                />
                              </FormGroup>
                              <Stack hidden={formik.values.targetType?.name?.includes('Intensity') || !showOptional} direction={'row'} spacing={1}>
                                <GenericDropdown
                                  data={['Unknown', 'Scope1', 'Scope2', 'Scope3']}
                                  selectElementId='scope_dropdown'
                                  selectElementLabel='Scope'
                                  selectedItemId={formik.values.scope}
                                  getKey={i => i} getDisplay={i => i}
                                  isOptional
                                  onChange={e => setScopeSelected(e! as EmissionScopeEnum)}
                                  name={'scope'} />
                                <GenericDropdown
                                  data={emissionSources ? values(emissionSources!) : []}
                                  selectElementId='configuredEmissionSource_dropdown'
                                  selectElementLabel='Select Emission Source'
                                  selectedItemId={formik.values.configuredEmissionSourceId}
                                  getKey={i => i.id!} getDisplay={i => i!.displayName!}
                                  isOptional
                                  name={'configuredEmissionSourceId'}
                                  isLoading={emissionSourcesLoading} />
                              </Stack>
                            </Stack>
                          </Box>
                          {
                            (formik.values.targetType?.name!.includes('Intensity') && formik.values.quantity?.unit) && Object.keys(intensityMetricTypesByName).length > 0
                              ? <Stack direction='row'>
                                <InfoIcon sx={{ mr: 1 }} color='primary' />
                                <Typography sx={{ fontSize: '1em' }}>
                                  {
                                    formik.values.intensityMetricType
                                      ? <>For <b>{formik.values.quantity!.value} {getUnit(formik.values.quantity!.unit!)}s ({formik.values.intensityType})</b> there is <b>1 {getUnit(intensityMetricTypesByName?.[formik.values.intensityMetricType].quantityUnit)} of {intensityMetricTypesByName?.[formik.values.intensityMetricType].name}</b>.</>
                                      : null
                                  }
                                </Typography>
                              </Stack>
                              : null
                          }
                        </>
                        : null
                    }
                  </Stack>
                  <Box display='flex' flexDirection='row-reverse' sx={{ pt: 1 }}>
                    <Button variant="contained" sx={{ ml: 1 }} type="submit">Save</Button>
                  </Box>
                </Stack>
              </Form>
          }
        </Formik>
      }
    ></Modal>
  );
};

export default TargetModal;
