import React from 'react';
import { Field } from 'formik';
import SwitchControlled, { SwitchControlledProps } from './controlled/switch-controlled';

interface SwitchFieldProps extends SwitchControlledProps {
  name: string;
}

const SwitchField = React.memo(({
  name,
  ...rest
}: SwitchFieldProps) => {
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue } }) => (
        <SwitchControlled
          {...rest}
          checked={field.value === true}
          name={name}
          onChange={
            (e, value) =>
              setFieldValue(field.name, value)
                .then(() => field.onChange(e))
          }
          onBlur={field.onBlur}
        />
      )}
    </Field>
  );
});

export default SwitchField;