import { baseApi as api } from "./base-query";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postFile: build.mutation<PostFileResponse, PostFileRequest>({
      query: (queryArg) => ({
        url: `/documents`,
        method: "POST",
        body: queryArg.body,
        params: {
          fileName: queryArg.fileName,
          documentTypeId: queryArg.documentTypeId,
          linkedEntityId: queryArg.linkedEntityId,
        },
      }),
    }),
    getDocument: build.query<GetDocumentResponse, GetDocumentRequest>({
      query: (queryArg) => ({ url: `/documents/${queryArg.fileId}` }),
    }),
    deleteDocument: build.mutation<
      DeleteDocumentResponse,
      DeleteDocumentRequest
    >({
      query: (queryArg) => ({
        url: `/documents/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    getLinkedFileSummaries: build.query<
      GetLinkedFileSummariesResponse,
      GetLinkedFileSummariesRequest
    >({
      query: (queryArg) => ({
        url: `/documents/summaries/${queryArg.linkedEntityId}`,
      }),
    }),
    getDocumentsByType: build.query<
      GetDocumentsByTypeResponse,
      GetDocumentsByTypeRequest
    >({
      query: (queryArg) => ({
        url: `/documents/document-types/${queryArg.documentTypeId}/documents`,
      }),
    }),
    getAllDocuments: build.query<
      GetAllDocumentsResponse,
      GetAllDocumentsRequest
    >({
      query: () => ({ url: `/documents/all` }),
    }),
    getDocumentTypes: build.query<
      GetDocumentTypesResponse,
      GetDocumentTypesRequest
    >({
      query: () => ({ url: `/documents/document-types` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as docsApi };
export type PostFileResponse =
  /** status 200 File Successfully Uploaded */ DocumentResponse;
export type PostFileRequest = {
  fileName: string;
  documentTypeId: string;
  linkedEntityId?: string;
  /** File data */
  body: Blob;
};
export type GetDocumentResponse = /** status 200 File data */ Blob;
export type GetDocumentRequest = {
  fileId: string;
};
export type DeleteDocumentResponse = unknown;
export type DeleteDocumentRequest = {
  fileId: string;
};
export type GetLinkedFileSummariesResponse =
  /** status 200 Payload of Array of DocumentResponse */ DocumentResponse[];
export type GetLinkedFileSummariesRequest = {
  linkedEntityId: string;
};
export type GetDocumentsByTypeResponse =
  /** status 200 Payload of Array of DocumentResponse */ DocumentResponse[];
export type GetDocumentsByTypeRequest = {
  documentTypeId: string;
};
export type GetAllDocumentsResponse =
  /** status 200 Payload of Array of DocumentResponse */ DocumentResponse[];
export type GetAllDocumentsRequest = void;
export type GetDocumentTypesResponse =
  /** status 200 Payload of Dictionary of DocumentType */ {
    [key: string]: DocumentType;
  };
export type GetDocumentTypesRequest = void;
export type DocumentResponse = {
  id?: string;
  documentTypeId: string;
  fileName?: string;
  typeName?: string;
  isLocked?: boolean;
  captureById: string;
  captureTime: string;
};
export type EntityType = {
  entityName?: string;
};
export type DocumentType = {
  id?: string;
  name?: string;
  description?: string;
  applicableEntities?: EntityType[];
};
export const {
  usePostFileMutation,
  useGetDocumentQuery,
  useDeleteDocumentMutation,
  useGetLinkedFileSummariesQuery,
  useGetDocumentsByTypeQuery,
  useGetAllDocumentsQuery,
  useGetDocumentTypesQuery,
} = injectedRtkApi;
