import { createSlice } from '@reduxjs/toolkit';

export interface LoaderSlice {
  isLoading: boolean;
}

const initialState: LoaderSlice = {
  isLoading: false,
};

export const loaderSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    isLoading: (state) => {
      state.isLoading = true;
    },
    notLoading: (state) => {
      state.isLoading = false;
    }
  }
});
export const { isLoading, notLoading } = loaderSlice.actions;
export default loaderSlice;
