import LoaderSuspense from 'components/navigation/loader-suspense';
import React from 'react';
import { Route, Navigate } from 'react-router';

const IntensityMetricForm = React.lazy(() => import('components/shared/custom-metrics/custom-metric-form'));
const PdfViewer = React.lazy(() => import('components/pdf-viewer/pdf-viewer'));
const BatchTemplate = React.lazy(() => import('components/batch/batch-template'));
const DisclosureSetupPage = React.lazy(() => import('components/disclosures/disclosure-setup-page'));
const EmissionSourceCapture = React.lazy(() => import('components/setup/emission-source-capture/emission-source-capture'));
const EmissionSourceGasCapture = React.lazy(() => import('components/setup/emission-source-capture/emission-source-gas-capture'));
const TargetModal = React.lazy(() => import('components/setup/targets/target-modal'));
const EmissionSourceModal = React.lazy(() => import('components/energy/emission-sources/emission-source-modal'));
const SetupTabs = React.lazy(() => import('components/setup/setup-tabs'));
const AuditTrail = React.lazy(() => import('components/audit/audit-trail-grid'));
const LocationFormModal = React.lazy(() => import('components/geo-services/location-form-modal')); 
const WaterMeterModal = React.lazy(() => import('components/setup/water/meter-form/water-meter-modal'));
const WaterSourceModal = React.lazy(() => import('components/setup/water/source-form/water-source-modal'));
const OperationModal = React.lazy(() => import('components/setup/operations/operation-modal'));

const SetupRoutes = () => {
  return (<>
    <Route
      path='setup'
      element={<Navigate to='/setup/general' replace />}
    />
    <Route
      path='setup/target/modal'
      element={<LoaderSuspense modal role='Admin'><TargetModal /></LoaderSuspense>}
    />
    <Route
      path='setup/target/modal/:targetId'
      element={<LoaderSuspense modal role='Admin'><TargetModal /></LoaderSuspense>}
    />
    <Route
      path='setup/intensity_metric/modal'
      element={<LoaderSuspense modal role='Admin'><IntensityMetricForm /></LoaderSuspense>}
    />
    <Route
      path='setup/intensity_metric/modal/:productionFactorId'
      element={<LoaderSuspense modal role='Admin'><IntensityMetricForm /></LoaderSuspense>}
    />
    <Route
      path='setup/intensity_metric/modal/:recordId/audit-trail'
      element={<LoaderSuspense modal role='Admin'><AuditTrail module={'Unknown'} type='ProductionFactor' /></LoaderSuspense>}
    />
    <Route
      path='setup/:tab'
      element={<LoaderSuspense outlet role='Admin'><SetupTabs /></LoaderSuspense>} >
      <Route path='pdf-viewer' element={<LoaderSuspense modal role='Admin'><PdfViewer /></LoaderSuspense>} />
      <Route path='template/:templateId' element={<LoaderSuspense modal role='Admin'><BatchTemplate /></LoaderSuspense>} />
      <Route path='template' element={<LoaderSuspense modal role='Admin'><BatchTemplate /></LoaderSuspense>} />
      <Route
        path='emission-source-modal/:emissionSourceId'
        element={<LoaderSuspense modal role='Admin'><EmissionSourceModal /></LoaderSuspense>} />
      <Route
        path='emission-source-capture/'
        element={<LoaderSuspense modal role='Admin'><EmissionSourceCapture /></LoaderSuspense>} />
      <Route
        path='add-emission-gas/:emissionSourceId'
        element={<LoaderSuspense modal role='Admin'><EmissionSourceGasCapture /></LoaderSuspense>} />
      <Route
        path='location-capture/'
        element={<LoaderSuspense modal role='Admin'><LocationFormModal /></LoaderSuspense>} />
      <Route
        path='location-capture/:locationId'
        element={<LoaderSuspense modal role='Admin'><LocationFormModal /></LoaderSuspense>} />
      <Route
        path='water-meter/'
        element={<LoaderSuspense modal role='Admin'><WaterMeterModal /></LoaderSuspense>} />
      <Route
        path='water-meter/:waterMeterId'
        element={<LoaderSuspense modal role='Admin'><WaterMeterModal /></LoaderSuspense>} />
      <Route
        path='water-source/'
        element={<LoaderSuspense modal role='Admin'><WaterSourceModal /></LoaderSuspense>} />
      <Route
        path='water-source/:waterSourceId'
        element={<LoaderSuspense modal role='Admin'><WaterSourceModal /></LoaderSuspense>} />
      <Route
        path='modal/'
        element={<LoaderSuspense modal role='Admin'><OperationModal /></LoaderSuspense>} />
      <Route
        path='modal/:operationId'
        element={<LoaderSuspense modal role='Admin'><OperationModal /></LoaderSuspense>} />
    </Route>
    <Route
      path='disclosures/:module'
      element={<LoaderSuspense><DisclosureSetupPage /></LoaderSuspense>} />
  </>);
};

export default SetupRoutes;
