import React, { ReactNode } from 'react';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridColDef,
  GridRowParams,
  useGridApiContext,
  GridCsvExportOptions,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  GridCsvGetRowsToExportParams,
} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { 
  FileDownload,
  ControlPoint,
  Print,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { DateTime } from 'luxon';
import useModulePermissions from 'helpers/use-module-permissions';
import { ModuleEnum } from 'services/api/main';

interface DataGridToolBarProps {
  addModalUrl?: string;
  title: string;
  rows: GridRowParams[];
  columns: GridColDef[];
  customButtons?: (isReadOnly: boolean) => ReactNode;
  isGraphTable?: boolean;
  module: ModuleEnum;
};

const getRowsFromCurrentPage = ({ apiRef }: GridCsvGetRowsToExportParams) =>
  gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);

export function CustomToolbar ({ addModalUrl, title, rows: rowsData, columns, customButtons, isGraphTable = false, module }: DataGridToolBarProps) {
  const navigate = useNavigate();
  const apiRef = useGridApiContext();
  const { readOnly } = useModulePermissions(module);
  const openCaptureModal = React.useCallback(() => navigate(addModalUrl!), [navigate, addModalUrl]);
  const specificHiddenColumns = ['unit'];
  const fieldarray = ['files', 'actionId'];

  // Todo: Parse filterd fields down or make more dynamic, current hardcoded values are not ideal. 
  const handleExport = (options: GridCsvExportOptions) =>
    apiRef.current.exportDataAsCsv({
      ...options,
      allColumns: true,
      fileName: `${title}-${DateTime.now().toFormat('yyyy-LLL-dd').toString()}`, 
      fields: columns.filter(c =>
        c?.field !== (!isGraphTable ? 'id' : null) 
               && !fieldarray.includes(c?.field)
               && (!c?.hide
                   || specificHiddenColumns.includes(c?.field)))
        .map(c => { return c.field; }) });

  const handlePrint = (options: GridCsvExportOptions) =>
    apiRef.current.exportDataAsPrint({ ...options, fileName: `${title}-${DateTime.now().toFormat('yyyy-LLL-dd').toString()}` });

  return (
    <GridToolbarContainer sx={{ borderBottom: '1px solid #E0E0E0' }}>
      { addModalUrl !== undefined ?
        <Button
          color="primary"
          disabled={readOnly}
          startIcon={<ControlPoint/>}
          onClick={openCaptureModal} > 
          Add
        </Button>
        : null
      }
      {customButtons ? customButtons(readOnly) : null}
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <Button
        color="primary"
        startIcon={<FileDownload />}
        onClick={_ => handleExport({ getRowsToExport: getRowsFromCurrentPage })} > 
          Download
      </Button>
      <Button
        color="primary"
        startIcon={<Print />}
        onClick={_ => handlePrint({ getRowsToExport: getRowsFromCurrentPage })} > 
          Print
      </Button>
    </GridToolbarContainer>
  );
};
