import LoaderSuspense from 'components/navigation/loader-suspense';
import React from 'react';
import { Route } from 'react-router';

const SupplierReviewTable = React.lazy(() => import('components/value-chain/supplier-review/datagrids/supplier-review-data-grid'));
const SupplierReviewForm = React.lazy(() => import('components/value-chain/supplier-review/forms/supplier-review-form'));
const ValueChainDashboard = React.lazy(() => import('components/value-chain/value-chain-dynamic-dashboard'));

const ValueChainRoutes = () => {
  return (<>
    <Route
      path='value-chain'
      element={<LoaderSuspense module='ValueChain'><ValueChainDashboard /></LoaderSuspense>}
    />
    <Route
      path='value-chain/supplier-review/table'
      element={<LoaderSuspense outlet module='ValueChain'><SupplierReviewTable /></LoaderSuspense>}
    >
      <Route
        path='modal'
        element={<LoaderSuspense modal module='ValueChain'><SupplierReviewForm /></LoaderSuspense>}
      />
      <Route
        path='modal/:supplierReviewId'
        element={<LoaderSuspense modal module='ValueChain'><SupplierReviewForm /></LoaderSuspense>}
      />
    </Route>
  </>);
};

export default ValueChainRoutes;
