import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import authService from 'services/authorization/AuthorizeService';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_DOCS_BASE_URL,
  prepareHeaders: async (headers) => {
    const token = await authService.getAccessToken();
    if (token) headers.set('Authorization', `Bearer ${token}`);

    return headers;
  }
});

export const baseApi = createApi({
  reducerPath: 'docsApi',
  tagTypes: [
    'Documents',
  ],
  baseQuery: baseQuery,
  endpoints: () => ({})
});
