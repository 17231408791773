import React from 'react';
import { Badge, Box, Divider, FormHelperText, IconButton, Popover, Stack, Tooltip } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';
import { ButtonColors } from 'types/shared/ambiguous-mui-types';

interface PopoverButtonProps {
  index: number,
  muiIcon: React.ReactNode,
  IconColor?: ButtonColors | 'inherit',
  children?: React.ReactNode,
  badgeContent?: React.ReactNode,
  displayBadge?: boolean;
  disabled?: boolean,
  isOpen?: boolean,
  close?: boolean;
  tooltip?: string;
  errorText?: string;
  onClick?: () => void;
  onClose?: () => void;
}

export default function PopoverButton ({ index, muiIcon, children, isOpen = false, disabled = false, close = true, tooltip, displayBadge = false, errorText, IconColor = 'default', onClick, onClose }: PopoverButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  React.useEffect(() => {
    if (isOpen) {
      setAnchorEl(buttonRef.current ?? null);
    } else {
      setAnchorEl(null);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose?.();
    if (close) {
      setAnchorEl(null);
    }
  };

  let open = Boolean(anchorEl);
  const id = open ? `${index}-PopOver` : undefined;

  const onClickCallback = React.useCallback(() => {
    if(disabled) return;

    onClick?.();
    setAnchorEl(buttonRef.current);
  }, [onClick, disabled]);
  
  return (
    <>
      <Stack direction={'column'} sx={{ flex: '1 1 auto', alignItems: 'center', justifyContent: 'flex-end' }} >
        <Badge onClick={onClickCallback} overlap="circular" badgeContent={ displayBadge ? <PriorityHigh color={IconColor as any} fontSize='small' /> : null}>
          {Boolean(tooltip) && !disabled ?
            <Tooltip title={tooltip!}>
              <IconButton ref={buttonRef} id={id + '-button'} color={IconColor} aria-describedby={id} disabled={disabled} sx={{ opacity: disabled ? 0.5 : 1 }}>
                { muiIcon }
              </IconButton>
            </Tooltip> :
            <IconButton ref={buttonRef} id={id + '-button'} color={IconColor} aria-describedby={id} disabled={disabled} sx={{ opacity: disabled ? 0.5 : 1 }}>
              {muiIcon}
            </IconButton>}
        </Badge>
        {
          errorText && 
          <Divider sx={{ backgroundColor: 'red', boxShadow: 1, borderWidth: 1 }} flexItem />
        }
        {
          errorText &&
          <FormHelperText sx={{ mt: 0, mb: 1 }} error>{errorText}</FormHelperText>
        }
      </Stack>

      { Boolean(children) ?
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: 1, m: 0 }}>
            {children}
          </Box>
        </Popover>
        : ''
      }
    </>
  );
};